import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { gray3, gray4 } from 'constants/colors'

const SkeletonWrapper = ({
	color = gray4,
	highlightColor = gray3,
	...props
}) => {
	return (
		<SkeletonTheme
			color={color}
			style={{ height: '100%' }}
			highlightColor={highlightColor}
		>
			<Skeleton {...props} />
		</SkeletonTheme>
	)
}

export default SkeletonWrapper
