import React from 'react'
import BaseFormikWrapper from 'components/wrappers/formik/index'
import PropTypes from 'prop-types'
import BaseDatePicker from 'components/ui/controls/BaseDatePicker'

const FormikFieldDatePicker = props => (
	<BaseFormikWrapper component={BaseDatePicker} {...props} />
)

FormikFieldDatePicker.propTypes = {
	name: PropTypes.string.isRequired
}

export default FormikFieldDatePicker
