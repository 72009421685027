import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import classes from './NativeSelect.module.scss'
import clsx from 'clsx'
import SpriteIcon from 'components/ui/SpriteIcon'
const arrow = {
	background: 'url(assets/arrow-down.svg) no-repeat right',
	backgroundPositionX: 'calc(100% - 10px)'
}

const NativeSelect = ({
	options = [],
	className,
	value,
	error,
	touched,
	placeholder,
	name,
	onChange,
	hasArrow,
	arrowClass
}) => {
	const hasError = useMemo(() => error && touched, [error, touched])
	return (
		<>
			{/*eslint-disable-next-line jsx-a11y/no-onchange*/}
			<select
				className={clsx(className, classes.root, { [classes.error]: hasError })}
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				style={arrow}
				name={name}
			>
				{placeholder && (
					<option value="" disabled hidden>
						{placeholder}
					</option>
				)}
				{options?.map(({ value, label }, index) => (
					<option
						key={`option-${label}-${index}`}
						value={value}
						className={classes.option}
					>
						{label}
					</option>
				))}
			</select>
			{hasArrow && (
				<SpriteIcon
					name="arrow-down"
					className={clsx(classes.arrow, arrowClass)}
				/>
			)}
		</>
	)
}

NativeSelect.propTypes = {
	className: PropTypes.string,
	options: PropTypes.array.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onChange: PropTypes.func,
	hasArrow: PropTypes.bool
}

NativeSelect.defaultProps = {
	className: '',
	value: 'placeholder',
	options: [],
	onChange: () => null,
	hasArrow: false
}

export default memo(NativeSelect)
