import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance'
import appConfig from 'constants/appConfig'
import * as Sentry from '@sentry/browser'

export const getSubscriptionPlans = () =>
	AuthorizedAxiosInstance.get(
		appConfig.apiPrefix + '/config/subscription-plans'
	)

export const getPaymentSecret = async () => {
	try {
		const {
			result: { secret }
		} = await AuthorizedAxiosInstance.get(
			appConfig.apiPrefix + '/subscription/payment-secret'
		)

		return secret
	} catch (e) {
		Sentry.captureException(e)
	}
}

export const updatePaymentMethod = paymentMethod =>
	AuthorizedAxiosInstance.post(
		appConfig.apiPrefix + `/subscription/update-payment-method`,
		{ payment_method: paymentMethod }
	)

export const activateSubscription = data =>
	AuthorizedAxiosInstance.post(
		appConfig.apiPrefix + '/subscription/activate',
		data
	)

export const cancelSubscription = () =>
	AuthorizedAxiosInstance.post(appConfig.apiPrefix + '/subscription/cancel')

export const getPaymentInfo = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/user/payment-method')

export const checkPromo = data =>
	AuthorizedAxiosInstance.post(
		appConfig.apiPrefix + '/subscription/check-promo',
		data
	)

export const checkPromoWithPlan = code =>
	AuthorizedAxiosInstance.get(
		appConfig.apiPrefix + '/subscription/check-promo',
		{ params: { promo_code: code } }
	)

export const getExchangeRates = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/config/exchange-rates')

export const getPaymentList = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/user/payment-list')
