import {
	birthday,
	ageFrom,
	ageTo,
	drink,
	familyPlans,
	growth,
	heightFrom,
	heightTo,
	job,
	kid,
	religion,
	smoke,
	study,
	wantGender,
	locationId,
	horoscope
} from 'modules/profile/createProfile/config'

export const deserializePreference = data => ({
	[birthday]: [
		data[ageFrom].length ? data[ageFrom][0] : 18,
		data[ageTo].length ? data[ageTo][0] : 110
	],
	[wantGender]: data.gender_id || [],
	[religion]: data.religion_id || [],
	[study]: data.education_id || [],
	[job]: data.career_id || [],
	[kid]: data.have_kids_id || [],
	[smoke]: data.smoking_id || [],
	[drink]: data.drinks_id || [],
	[growth]: [
		data[heightFrom].length ? data[heightFrom][0] : 1,
		data[heightTo].length ? data[heightTo][0] : 45
	],
	[familyPlans]: data.want_kids_id || [],
	locations: {
		type: data.location_type[0] || 0,
		selectedIds: data.location_id || [],
		text: data.location_text
	},
	[horoscope]: data.horoscope_id || []
})

export const serializePreference = (data = {}) => ({
	age_from: data[ageFrom] ? [data[ageFrom]] : undefined,
	age_to: data[ageTo] ? [data[ageTo]] : undefined,
	gender_id: data[wantGender],
	religion_id: data[religion],
	education_id: data[study],
	career_id: data[job],
	have_kids_id: data[kid],
	smoking_id: data[smoke],
	drinks_id: data[drink],
	height_from_id: data.height_from_id ? [data.height_from_id] : undefined,
	height_to_id: data.height_to_id ? [data.height_to_id] : undefined,
	want_kids_id: data[familyPlans],
	location_type:
		typeof data.location_type !== 'undefined'
			? [data.location_type]
			: undefined,
	location_id: data[locationId]?.map(location =>
		location.hasOwnProperty('id') ? location?.id : location
	),
	horoscope_id: data[horoscope]
})
