import React from 'react'
import BaseFormikWrapper from 'components/wrappers/formik/index'
import PropTypes from 'prop-types'
import AboutMe from 'modules/profile/createProfile/components/AboutMe'

const FormikFieldAboutMe = props => (
	<BaseFormikWrapper component={AboutMe} {...props} />
)

FormikFieldAboutMe.propTypes = {
	name: PropTypes.string.isRequired
}

export default FormikFieldAboutMe
