import React from 'react'
import BaseFormikWrapper from 'components/wrappers/formik/index'
import PropTypes from 'prop-types'
import BaseTextArea from 'components/ui/controls/BaseTextArea'

const FormikFieldTextArea = props => (
	<BaseFormikWrapper component={BaseTextArea} {...props} />
)

FormikFieldTextArea.propTypes = {
	name: PropTypes.string.isRequired
}

export default FormikFieldTextArea
