import React, { useCallback, useMemo, memo } from 'react'
import PropTypes from 'prop-types'
import classes from './InfoDialog.module.scss'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import Typography from 'components/ui/Typography'
import Button from 'components/ui/Button'
import clsx from 'clsx'
import { ReactComponent as SuccessIcon } from 'assets/staticIcons/done.svg'
import { ReactComponent as ErrorIcon } from 'assets/staticIcons/error.svg'

const InfoDialog = ({
	open,
	type,
	onClose,
	onConfirm,
	title,
	body,
	classes: outerClasses = {}
}) => {
	const IconByType = useMemo(() => {
		switch (type) {
			case 'success':
				return <SuccessIcon className={classes.icon} />
			case 'error':
				return <ErrorIcon className={classes.icon} />
			default:
				return null
		}
	}, [type])

	const handleConfirm = useCallback(() => {
		onConfirm()
		onClose()
	}, [onClose, onConfirm])

	return (
		<BaseDialog open={open} maxWidth="sm" onClose={onClose}>
			<div className={clsx(classes.root, outerClasses.root)}>
				<div className={clsx(classes.modal, outerClasses.modal)}>
					{IconByType}
					<Typography className={clsx(classes.title, outerClasses.title)}>
						{title}
					</Typography>
					<Typography className={clsx(classes.body, outerClasses.body)}>
						{body}
					</Typography>
					<Button
						onClick={handleConfirm}
						shape="rounded"
						className={classes.btn}
					>
						Okay
					</Button>
				</div>
			</div>
		</BaseDialog>
	)
}

InfoDialog.propTypes = {
	open: PropTypes.bool,
	type: PropTypes.oneOf(['default', 'success', 'error']),
	onClose: PropTypes.func,
	onConfirm: PropTypes.func
}

InfoDialog.defaultProps = {
	open: false,
	type: 'default',
	onClose: () => null,
	onConfirm: () => null
}

export default memo(InfoDialog)
