import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import classes from './BaseDialog.module.scss'
import clsx from 'clsx'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const BaseDialog = ({
	open,
	onClose,
	rootClass,
	children,
	classesBackdrop,
	classesPaper,
	backdropProps,
	maxWidth,
	scroll,
	fullScreen,
	classes: propsClasses
}) => {
	return (
		<Dialog
			scroll={scroll}
			open={open}
			TransitionComponent={Transition}
			keepMounted
			fullScreen={fullScreen}
			maxWidth={maxWidth}
			onClose={onClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			classes={{
				...propsClasses,
				root: clsx(classes.root, rootClass)
			}}
			PaperProps={{
				classes: {
					root: clsx({ [classes.paperRoot]: !fullScreen }, classesPaper)
				}
			}}
			BackdropProps={{
				classes: { root: clsx(classes.backdrop, classesBackdrop) },
				...backdropProps
			}}
		>
			{children}
		</Dialog>
	)
}

export default BaseDialog
