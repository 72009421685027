import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage as FormikErrorHandler, useField } from 'formik'
import ErrorMessage from 'components/ui/ErrorMessage'
import classes from './BaseFormikWrapper.module.scss'
import { _isObject, _isArray } from 'utils/lodash'
import clsx from 'clsx'
import SuccessMessage from 'components/ui/SuccessMessage'

// const infoDialogClasses = {
// 	body: classes.infoDialogBody
// }

const BaseFormikWrapper = ({
	name,
	hideError,
	component: Component,
	errorMsgClass,
	showErrorDialog,
	showSuccessMessage,
	...props
}) => {
	const [{ value, onChange }, { error, touched }, { setValue }] = useField(name)

	const handleChange = useCallback(
		value => {
			if (_isObject(value) && !_isArray(value)) {
				onChange(value)
			} else {
				setValue(value)
			}
		},
		//eslint-disable-next-line
		[]
	)

	const isVerified = useMemo(() => !error && touched && value, [
		error,
		touched,
		value
	])

	return (
		<div className={clsx(classes.root)}>
			<Component
				error={error}
				touched={touched}
				value={value}
				name={name}
				onChange={handleChange}
				{...props}
			/>

			{!hideError && !showErrorDialog && (
				<FormikErrorHandler
					component={ErrorMessage}
					name={name}
					className={errorMsgClass}
				/>
			)}

			{showSuccessMessage && isVerified && (
				<SuccessMessage>Verified!</SuccessMessage>
			)}
		</div>
	)
}

BaseFormikWrapper.propTypes = {
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
	showErrorDialog: PropTypes.bool,
	showSuccessMessage: PropTypes.bool
}

BaseFormikWrapper.defaultProps = {
	// name: '',
	className: '',
	hideError: false,
	showErrorDialog: false,
	showSuccessMessage: false
}

export default BaseFormikWrapper
