import { routesByName } from 'constants/routes'
import { lazy } from 'react'
import mapRouteConfig from 'utils/mapRouteConfig'

const EmailNotifications = lazy(() => import('modules/notifications/emails'))
const MatchesCreateMobile = lazy(() =>
	import('modules/notifications/matches/createConversation/MatchesCreateMobile')
)
const Upgrade = lazy(() => import('pages/Upgrade/Upgrade'))
const EnterEmail = lazy(() => import('modules/auth/EnterEmail'))
const ConversationMobile = lazy(() =>
	import('modules/conversation/ConversationMobile')
)
const RequestCreateMobile = lazy(() =>
	import(
		'modules/notifications/requests/createConversation/RequestCreateMobile'
	)
)
const ReceivedConversationMobile = lazy(() =>
	import(
		'modules/notifications/requests/receivedConversation/ReceivedConversationMobile'
	)
)
const LastMatchCreateMobile = lazy(() =>
	import(
		'modules/notifications/matches/lastMatch/createConversation/LastMatchCreateMobile'
	)
)
const SelectedPlanPage = lazy(() =>
	import('modules/profile/createProfile/components/SelectedPlanPage')
)
const MainPage = lazy(() => import('modules/main'))

const PrivateRouteConfig = [
	{
		path: routesByName.main.notifications.emails,
		component: EmailNotifications
	},
	{
		path: routesByName.main.notifications.matches.mobileConversationCreate,
		component: MatchesCreateMobile,
		onlyMobile: true
	},
	{
		path: routesByName.upgrade.root,
		component: Upgrade
	},
	{
		path: routesByName.enterEmail,
		component: EnterEmail
	},
	{
		path: routesByName.conversation.root,
		component: ConversationMobile,
		onlyMobile: true
	},
	{
		path: routesByName.main.notifications.requests.sentConversationCreate,
		component: RequestCreateMobile,
		onlyMobile: true
	},
	{
		path: routesByName.main.notifications.requests.mobileReceivedConversation,
		component: ReceivedConversationMobile,
		onlyMobile: true
	},
	{
		path: routesByName.main.lastMatch.mobileConversationCreate,
		component: LastMatchCreateMobile,
		onlyMobile: true
	},
	{
		path: routesByName.selectedPlan,
		component: SelectedPlanPage
	},
	{
		path: routesByName.main.root,
		component: MainPage
	}
]

const result = mapRouteConfig(PrivateRouteConfig)

export default result
