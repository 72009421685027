import LaravelEcho from 'laravel-echo'
import io from 'socket.io-client'

/* constants */
import { getAccessToken, hasToken } from 'utils/authorize.js'
import appConfig from 'constants/appConfig.js'

let laravelEchoInstance = null
let connected = false

const getToken = () => {
	if (hasToken()) {
		return getAccessToken()
	}
	return ''
}

function connectLaravelEcho() {
	if (laravelEchoInstance) {
		return laravelEchoInstance
	}

	laravelEchoInstance = new LaravelEcho({
		broadcaster: 'socket.io',
		client: io,
		host: appConfig.broadcastUrl,
		namespace: '',
		auth: {
			headers: {
				Authorization: getToken()
			}
		}
	})
	connected = true
	return laravelEchoInstance
}

class Echo {
	// eslint-disable-next-line class-methods-use-this
	connect() {
		return new Promise(resolve => {
			if (!connected) {
				connectLaravelEcho()
			}
			laravelEchoInstance.options.auth.headers.Authorization = getToken()
			resolve(laravelEchoInstance)
		})
	}
}

export default new Echo()
