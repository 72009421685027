import React from 'react'
import BaseFormikWrapper from 'components/wrappers/formik/index'
import BaseInput from 'components/ui/controls/BaseInput'
import PropTypes from 'prop-types'

const FormikFieldInput = props => (
	<BaseFormikWrapper component={BaseInput} {...props} />
)

FormikFieldInput.propTypes = {
	name: PropTypes.string.isRequired
}

export default FormikFieldInput
