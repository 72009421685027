const appConfig = {
	isDev: process.env.NODE_ENV === 'development',
	apiPrefix: '/v1',
	apiUrl: process.env.REACT_APP_API_URL,
	broadcastUrl: process.env.REACT_APP_BROADCAST_URL,
	configUrl: process.env.REACT_APP_CONFIG_URL,
	stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
	broadcastChannelName: process.env.REACT_APP_BROADCAST_CHANNEL_NAME,
	sentryDSN: process.env.REACT_APP_SENTRY_DSN,
	facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID,
	fcmConfig: {
		apiKey: 'AIzaSyDf9sug7cLFRcPe1IztsESqMi7AU3iV-_0',
		authDomain: 'mtdf-11f6b.firebaseapp.com',
		projectId: 'mtdf-11f6b',
		storageBucket: 'mtdf-11f6b.appspot.com',
		messagingSenderId: '207587327789',
		appId: '1:207587327789:web:b3cfe5c0eb1e1096955da4'
	}
}

export default appConfig
