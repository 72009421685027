import React from 'react'
import { useSelector } from 'react-redux'
import FallbackPage from 'pages/FallbackPage/FallbackPage'

const ControlledLoader = () => {
	const isLoading = useSelector(({ app: { isLoading } }) => isLoading)
	return isLoading ? <FallbackPage /> : null
}

export default ControlledLoader
