import isEmpty from 'lodash.isempty'
import get from 'lodash.get'
import isArray from 'lodash.isarray'
import toArray from 'lodash.toarray'
import isObject from 'lodash.isobject'
import throttle from 'lodash.throttle'
import debounce from 'lodash.debounce'
import set from 'lodash.set'
import difference from 'lodash.difference'
import filter from 'lodash.filter'

export const _get = (...args) => get(...args)
export const _isEmpty = (...args) => isEmpty(...args)
export const _isObject = (...args) => isObject(...args)
export const _toArray = (...args) => toArray(...args)
export const _isArray = (...args) => isArray(...args)
export const _throttle = (...args) => throttle(...args)
export const _debounce = (fn, delay) => debounce(fn, delay)
export const _set = (...args) => set(...args)
export const _difference = (...args) => difference(...args)
export const _filter = (...args) => filter(...args)
