import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAppMetaVersion, getAppVersion } from '../../store/selectors'
import { _isEmpty } from '../../../../utils/lodash'
import { setAppVersion } from '../../store/actions'
import { cacheBuster } from '../../../../utils/cacheBuster'
import _useDidMount from '../../../../hooks/useDidMount'

const CacheBuster = () => {
	const appVersion = useSelector(getAppVersion)
	const appMetaVersion = useSelector(getAppMetaVersion)
	const dispatch = useDispatch()
	const isReload = localStorage.getItem('isReload')

	_useDidMount(() => {
		if (isReload === 'yes') {
			localStorage.setItem('isReload', 'no')
			window.location.reload(true)
		}
	})

	useEffect(() => {
		if (appVersion) {
			/* eslint-disable-next-line */
			console.log('Current app version:', appVersion)
		}

		if (!appVersion && !_isEmpty(appMetaVersion)) {
			/* eslint-disable-next-line */
			console.log('Setting init app version:', appVersion)
			dispatch(setAppVersion(appMetaVersion))
		}

		if (
			!_isEmpty(appVersion) &&
			!_isEmpty(appMetaVersion) &&
			appVersion !== appMetaVersion
		) {
			/* eslint-disable */
			console.log('New version is available:', appMetaVersion)
			console.log('Updating...')
			/* eslint-enable */

			cacheBuster(appMetaVersion).then(() => {
				/* eslint-disable-next-line */
				console.log('Hard reloading...')

				dispatch(setAppVersion(appMetaVersion))
				localStorage.setItem('isReload', 'yes')
			})
		}
	}, [appVersion, appMetaVersion, dispatch])

	return null
}

export default CacheBuster
