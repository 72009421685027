const currencies = ['CAD', 'USD', 'GBP', 'EUR', 'AUD']

const currencySigns = {
	CAD: '$',
	USD: '$',
	GBP: '£',
	EUR: '€',
	AUD: '$'
}

export const transformExchangeRates = (arr = [], name) =>
	arr.reduce((acc, item) => {
		if (currencies.includes(item.name)) {
			return {
				...acc,
				[item.name]: {
					name: item.name,
					index: item.index,
					sign: currencySigns[item.name]
				}
			}
		} else {
			return acc
		}
	}, {})
