import React, { useMemo } from 'react'
import { ReactComponent as TutorialArrow } from 'assets/staticIcons/tutorial-arrow.svg'
import classes from './Step1.module.scss'
import Button from 'components/ui/Button'
import clsx from 'clsx'
import SpriteIcon from 'components/ui/SpriteIcon'
import { black3, primaryColor } from 'constants/colors'

const Step1 = () => {
	const renderMobileButtons = useMemo(
		() => (
			<>
				<Button
					size="xl"
					shape="rounded"
					color="white"
					className={clsx(classes.btn, classes.likeBtn)}
				>
					<SpriteIcon
						name="like"
						className={classes.icon}
						color={primaryColor}
					/>
				</Button>
				<Button
					size="xl"
					shape="rounded"
					color="white"
					className={clsx(classes.btn, classes.passBtn)}
				>
					<SpriteIcon name="close" className={classes.icon} color={black3} />
				</Button>
			</>
		),
		[]
	)
	return (
		<div className={classes.root}>
			<TutorialArrow />
			{renderMobileButtons}
		</div>
	)
}

export default Step1
