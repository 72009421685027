import * as types from './constants'
import update from 'immutability-helper'

const initialState = {
	conversations: [],
	messagesByConversationId: {},
	conversationsMap: {},
	metaByConversationId: {},
	currentConversationId: null,
	userForCreateConversation: null,
	hasMore: true
}

const messengerReducer = (state = { ...initialState }, { type, payload }) => {
	switch (type) {
		case types.GET_CONVERSATIONS: {
			return update(state, {
				conversations: {
					$set: payload.conversations
				},
				conversationsMap: {
					$merge: payload.conversationsMap
				},
				metaByConversationId: {
					$merge: payload.metaByConversationId
				},
				hasMore: { $set: payload.hasMore }
			})
		}
		case types.PUSH_CONVERSATIONS: {
			return update(state, {
				conversations: {
					$push: payload.conversations
				},
				conversationsMap: {
					$merge: payload.conversationsMap
				},
				metaByConversationId: {
					$merge: payload.metaByConversationId
				},
				hasMore: { $set: payload.hasMore }
			})
		}
		case types.GET_MESSAGES_BY_CONVERSATION_BY_ID: {
			return update(state, {
				messagesByConversationId: {
					[payload.id]: { $set: payload.messages }
				}
			})
		}
		case types.SEND_MESSAGE: {
			return update(state, {
				messagesByConversationId: {
					[payload.id]: { $push: [payload.message] }
				}
			})
		}
		case types.GET_NEXT_PAGE_MESSAGES: {
			return update(state, {
				messagesByConversationId: {
					[payload.id]: { $unshift: payload.messages }
				}
			})
		}
		case types.SET_FLAG_HAS_NEXT_PAGE: {
			return update(state, {
				metaByConversationId: {
					[payload.id]: { $set: { hasNextPage: payload.hasNextPage } }
				}
			})
		}
		case types.PUSH_RECEIVED_MESSAGE: {
			return update(state, {
				messagesByConversationId: {
					[payload.id]: { $push: [payload.message] }
				}
			})
		}
		case types.GET_CONVERSATION_BY_ID: {
			return update(state, {
				conversations: {
					[payload.id]: { $push: [payload.message] }
				}
			})
		}
		case types.SET_CURRENT_CONVERSATION: {
			return update(state, {
				currentConversationId: { $set: payload }
			})
		}
		case types.CREATE_CONVERSATION: {
			return update(state, {
				messagesByConversationId: { [payload.id]: { $set: [] } },
				conversationsMap: { [payload.id]: { $set: payload } },
				conversations: { $push: [payload] },
				currentConversationId: { $set: payload.id }
			})
		}
		case types.SET_USER_FOR_CREATE_CONVERSATION: {
			return update(state, {
				userForCreateConversation: { $set: payload }
			})
		}
		case types.CLEAR_MESSENGER_META: {
			return update(state, {
				userForCreateConversation: { $set: null },
				currentConversationId: { $set: null }
			})
		}
		case types.SET_CONVERSATION: {
			return update(state, {
				// messagesByConversationId: { [payload.id]: { $set: state.messagesByConversationId[payload.id].map((message) => message.id === payload.lastMessage.id ? payload.lastMessage : message ) } },
				conversationsMap: { [payload.id]: { $set: payload } },
				conversations: {
					$set: state.conversations?.map(conv =>
						conv.id === payload.id ? payload : conv
					)
				}
			})
		}
		case types.REMOVE_CONVERSATION_BY_PARTICIPANT_ID: {
			return update(state, {
				conversations: { $set: payload }
			})
		}
		case types.SET_IS_LAST_MESSAGE_READ: {
			return update(state, {
				conversationsMap: { [payload.id]: { $set: payload } }
			})
		}
		default:
			return state
	}
}

export default messengerReducer
