import React, { useEffect } from 'react'
import { Form } from 'formik'
import Button from 'components/ui/Button'
import classes from './View.module.scss'
import clsx from 'clsx'
import SpriteIcon from 'components/ui/SpriteIcon'
import { screenFormat } from 'constants/screenFormat'
import { _isEmpty } from 'utils/lodash'
import {
	answers,
	birthday,
	drink,
	firstName,
	gender,
	growth,
	job,
	kid,
	languages,
	lastName,
	location,
	personalities,
	photos,
	religion,
	smoke,
	study,
	wantGender
} from 'modules/profile/createProfile/config'
import { defaultAge } from 'modules/profile/createProfile/index.js'

const View = ({
	components,
	format,
	containerClass,
	rootClass,
	user,
	setValues,
	config,
	submitForm
}) => {
	useEffect(
		() => {
			if (!_isEmpty(user)) {
				setValues({
					cityName: user.cityName,
					countryName: user.countryName,
					[firstName]: user[firstName] || '',
					[lastName]: user[lastName] || '',
					[birthday]: user[birthday] || defaultAge,
					[gender]: user[gender] || '',
					[drink]: user[drink] || '',
					[growth]: user[growth] || '',
					[job]: user[job] || '',
					[kid]: user[kid] || '',
					[location]: user[location] || '',
					[photos]: user[photos]?.map(photo => photo?.large) || [],
					[religion]: user[religion] || '',
					[smoke]: user[smoke] || '',
					[personalities]: user[personalities] || [],
					[study]: user[study] || '',
					[wantGender]: user[wantGender] || '',
					[languages]: user[languages] || [],
					[answers]: user[answers] || {}
				})
			}
		},
		//eslint-disable-next-line
		[user]
	)

	return (
		<Form className={clsx(classes.root, rootClass)}>
			<div className={clsx(classes.container, containerClass)}>
				<components.Icon
					className={classes.icon}
					// hide={format === screenFormat.desktop}
				/>
				<components.Title />
				<components.Description />
				<components.Inputs format={format} user={user} config={config} />
			</div>

			<Button
				type="submit"
				size="md"
				shape="rounded"
				onClick={submitForm}
				className={classes.btn}
				iconPlacement="end"
				icon={<SpriteIcon name="arrow-next2" size="md" className="ml-2" />}
			>
				{format === screenFormat.desktop ? 'Next' : null}
			</Button>
			{/*<div className={classes.whiteSmooth} />*/}
		</Form>
	)
}

export default View
