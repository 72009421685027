import messengerConnect from '@rznv/core'
import { getAccessToken } from 'utils/authorize.js'
import appConfig from 'constants/appConfig.js'

const errorHandler = error => {
	console.dir(error)
}

const initialConfig = {
	restApiUrl: appConfig.apiUrl,
	onError: errorHandler
}

const messengerApi = messengerConnect(initialConfig)

export const attachTokenToMessengerApiInstance = () => {
	messengerApi.setConfig({
		...initialConfig,
		token: getAccessToken()
	})
}

export default messengerApi
