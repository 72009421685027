import * as types from './constants'
import * as services from './../services'
import messengerApi from 'libs/messenger'
import { transformArrayToObjectById } from 'utils/transformArrayToObjectById.js'
import { deleteRequestById } from 'modules/notifications/requests/services.js'
import { getUsersByIds } from 'modules/users/actions'
import { _get } from 'utils/lodash'

export const getRequests = () => async (dispatch, getState) => {
	try {
		const response = await services.getRequests()

		const requests = _get(response, 'result', [])

		const {
			profile: {
				user: { id: myId }
			}
		} = getState()

		const conversations =
			(await Promise.all(
				(requests || []).map(conversationId =>
					messengerApi.conversations.byId(conversationId).get()
				)
			)) || []

		const users = (conversations || []).reduce(
			(acc, conversation) => [
				...acc,
				conversation.participants.find(
					participant => participant?.userId !== myId
				)?.userId
			],
			[]
		)

		if (users.length) {
			dispatch(getUsersByIds(users))
		}

		const sentRequests = conversations.filter(({ ownerId }) => myId === ownerId)
		const receivedRequests = conversations.filter(
			({ ownerId }) => myId !== ownerId
		)

		const sentRequestsMap = transformArrayToObjectById(sentRequests)
		const receivedRequestsMap = transformArrayToObjectById(receivedRequests)

		dispatch({
			type: types.GET_REQUESTS,
			payload: {
				sentRequests,
				receivedRequests,
				sentRequestsMap,
				receivedRequestsMap
			}
		})
	} catch (e) {
		console.dir(e)
	}
}

export const removeReceivedRequest = conversationId => async (
	dispatch,
	getState
) => {
	try {
		const {
			requests: { receivedRequests, receivedRequestsMap }
		} = getState()

		await deleteRequestById(conversationId)

		const newReceivedRequests = receivedRequests.filter(
			({ id }) => id !== conversationId
		)
		delete receivedRequestsMap[conversationId]

		dispatch({
			type: types.REMOVE_RECEIVED_REQUEST,
			payload: {
				receivedRequests: newReceivedRequests,
				receivedRequestsMap
			}
		})
	} catch (e) {
		console.dir(e)
	}
}
