import React from 'react'
import BaseFormikWrapper from 'components/wrappers/formik/index'
import PropTypes from 'prop-types'
import BaseSelectLocation from 'components/ui/controls/BaseSelectLocation'

const FormikFieldSelectLocation = props => (
	<BaseFormikWrapper component={BaseSelectLocation} {...props} />
)

FormikFieldSelectLocation.propTypes = {
	name: PropTypes.string.isRequired
}

export default FormikFieldSelectLocation
