import { toastr } from 'react-redux-toastr'

const errorToastr = (message, title = 'Error', options = {}) => {
	toastr.error(title, message, {
		...options,
		timeOut: 7000,
		position: 'top-center',
		transitionIn: 'fadeIn',
		transitionOut: 'fadeOut',
		showCloseButton: true
	})
}

export default errorToastr
