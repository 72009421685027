export const defaultAnswers = {
	1: "I invented mutton rolls. I've visited 22 countries. I can speak 3 languages.",
	2: 'Drive 12 hours to New York City to eat some great pizza.',
	3: "I'd buy my amma her dream home",
	4: 'Laugh at all my bad jokes',
	5: 'I’m free spirited, curious about people and places and always up for a new challenge!',
	6: 'Family time, working out and trying out a new recipe in the kitchen. Cheesy kotthu roti anyone?',
	7: '“And when you want something, all the universe conspires in helping you achieve it”',
	8: 'Values family, loves Tamil films and music as much as I do and is looking for something serious.',
	9: 'To visit every country in the world!'
}
