const root = 'app'

export const isShowNavigationBar = ({ [root]: { isShowNavigationBar } }) =>
	isShowNavigationBar

export const getReportReasons = ({ [root]: { reportReasons } }) => reportReasons

export const getUnmatchReasons = ({ [root]: { unmatchReasons } }) =>
	unmatchReasons

export const getAppConfig = ({ [root]: { config } }) => config

export const getAppVersion = ({ [root]: { appVersion } }) => appVersion

export const getAppMetaVersion = ({ [root]: { appMetaVersion } }) =>
	appMetaVersion

const appSelectors = {
	isShowNavigationBar,
	getReportReasons,
	getUnmatchReasons,
	getAppConfig,
	getAppVersion
}

export default appSelectors
