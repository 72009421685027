import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classes from './EditedSection.module.scss'
import clsx from 'clsx'
import SpriteIcon from 'components/ui/SpriteIcon'

const EditedSection = ({
	className,
	iconClass,
	titleClass,
	value,
	onClickEdit,
	onClickSelect,
	isSelect,
	title,
	answer
}) => {
	const handleClickEdit = useCallback(
		event => {
			event.stopPropagation()
			onClickEdit(value)
		},
		[onClickEdit, value]
	)

	// const handleClickSelect = useCallback(() => {
	// 	onClickSelect(value)
	// }, [onClickSelect, value])

	return (
		<div
			role="presentation"
			className={clsx(classes.root, className, {
				[classes.selected]: isSelect
			})}
			onClick={handleClickEdit}
		>
			<SpriteIcon
				name="edit"
				className={clsx(iconClass, classes.icon)}
				onClick={handleClickEdit}
			/>
			<div className={clsx(classes.title, titleClass)}>{title}</div>
			{answer && <div className={classes.answer}>{answer}</div>}
		</div>
	)
}

EditedSection.propTypes = {
	className: PropTypes.string,
	iconClass: PropTypes.string,
	onClickEdit: PropTypes.func,
	onClickSelect: PropTypes.func
}

EditedSection.defaultProps = {
	className: '',
	value: '',
	name: '',
	onClickEdit: () => null,
	onClickSelect: () => null,
	isSelect: false,
	options: []
}

export default memo(EditedSection)
