import { _get } from 'utils/lodash'

export const buildSrcWithHashAndUserId = ({
	type,
	config = {},
	userId,
	hash
}) => {
	return Object.entries(config?.endpoints?.media[type]).reduce(
		(acc, [key, value]) => {
			return {
				...acc,
				[key]: value.replace(/{userId}/, userId).replace(/{hash}/, hash)
			}
		},
		{}
	)
}

export const preparePhotosSrc = ({ photos = [], config }) => {
	return (photos || []).map(photo => ({
		...photo,
		...buildSrcWithHashAndUserId({
			config,
			...photo
		})
	}))
}

export const buildUsersPhotoPath = (users = [], config) => {
	return users.map(user => {
		const photos = _get(user, 'photos', [])

		user.photos = preparePhotosSrc({ photos, config })

		return user
	})
}
