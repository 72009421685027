import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const Heading = ({ variant, children, className, area, ...props }) => (
	<span
		className={clsx(className, variant)}
		style={{
			gridArea: area
		}}
		{...props}
	>
		{children}
	</span>
)

Heading.propTypes = {
	className: PropTypes.string,
	area: PropTypes.string,
	variant: PropTypes.oneOf([
		'heading-1',
		'heading-2',
		'heading-3',
		'heading-4',
		'heading-5',
		'heading-6',
		'heading-7'
	])
}

Heading.defaultProps = {
	variant: 'heading-1',
	className: '',
	area: ''
}

export default Heading
