import React, { memo } from 'react'
import classes from 'components/ui/UpgradeBanners/MobileTextBanner/MobileTextBanner.module.scss'
import Button from 'components/ui/Button'
import { routesByName } from 'constants/routes.js'
import useHistory from 'hooks/useHistory.js'
import Typography from 'components/ui/Typography'

const MobileTextBanner = ({ buttons }) => {
	const history = useHistory()

	return (
		<div className={classes.card}>
			<Typography className={classes.title}>
				Upgrade and unlock this feature
			</Typography>
			<Typography className={classes.subTitle}>
				Premium members can see who liked them, send unlimited messages & more!
			</Typography>
			<div className={classes.actions}>
				<Button
					shape="rounded"
					className={classes.cardBtn}
					onClick={() => history.push(routesByName.upgrade.root)}
				>
					Upgrade now
				</Button>
				{buttons}
			</div>
		</div>
	)
}

export default memo(MobileTextBanner)
