import React from 'react'
import BaseFormikWrapper from 'components/wrappers/formik/index'
import PropTypes from 'prop-types'
import BaseCheckboxButtonsGroup from 'components/ui/controls/BaseCheckboxButtonsGroup'

const FormikFieldCheckboxButtonsGroup = props => (
	<BaseFormikWrapper component={BaseCheckboxButtonsGroup} {...props} />
)

FormikFieldCheckboxButtonsGroup.propTypes = {
	name: PropTypes.string.isRequired
}

export default FormikFieldCheckboxButtonsGroup
