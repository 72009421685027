import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import SkeletonWrapper from 'components/wrappers/ui/Skeleton'
import classes from './BaseImage.module.scss'
import clsx from 'clsx'

const BaseImage = ({
	className,
	effect = 'blur',
	alt = 'broken link',
	classes: outerClasses = {},
	...props
}) =>
	props.src ? (
		<LazyLoadImage
			wrapperClassName={clsx(classes.wrapper, outerClasses.wrapper)}
			className={clsx(classes.root, outerClasses.root)}
			alt={alt}
			effect={effect}
			{...props}
		/>
	) : (
		<SkeletonWrapper className={clsx(classes.root, outerClasses.root)} />
	)

export default memo(BaseImage)
