import { AUTH_INIT, AUTH_LOGOUT } from 'modules/auth/store/constants'
import update from 'immutability-helper'

const initialState = {
	logined: false
}

const authReducer = (state = { ...initialState }, { type, payload }) => {
	switch (type) {
		case AUTH_INIT: {
			return update(state, {
				logined: {
					$set: payload.logined
				}
			})
		}
		case AUTH_LOGOUT: {
			return update(state, {
				$set: initialState
			})
		}
		default:
			return state
	}
}

export default authReducer
