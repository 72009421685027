import { SET_DIALOG_MANAGER_STATE, CLOSE_DIALOG_MANAGER } from './constants'
import { initialDialogManagerState } from 'modules/dialogManager/store/reducer'

export const setDialogManagerState = (options = initialDialogManagerState) => ({
	type: SET_DIALOG_MANAGER_STATE,
	payload: options
})
export const closeDialogManager = () => ({
	type: CLOSE_DIALOG_MANAGER
})
