import React, { useCallback } from 'react'
import classes from './Dot.module.scss'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const Dot = ({ active, onClick, slide, className, activeClass }) => {
	const handleClick = useCallback(() => {
		onClick(slide)
	}, [onClick, slide])

	return (
		<div
			className={clsx(classes.root, className, {
				[clsx(classes.active, activeClass)]: active
			})}
			role="presentation"
			onClick={handleClick}
		/>
	)
}

Dot.propTypes = {
	active: PropTypes.bool.isRequired,
	slide: PropTypes.number,
	onClick: PropTypes.func
}

Dot.defaultProps = {
	active: false,
	slide: 0,
	onClick: () => null
}

export default Dot
