export const GET_DISCOVERY_USERS = 'GET_DISCOVERY_USERS'
export const GET_MORE_DISCOVERY_USERS = 'GET_MORE_DISCOVERY_USERS'
export const PREPARE_NEXT_APPLICANT = 'PREPARE_NEXT_APPLICANT'
export const PREPARE_PREVIOUS_APPLICANT = 'PREPARE_PREVIOUS_APPLICANT'
export const LIKE_APPLICANT = 'LIKE_APPLICANT'
export const REJECT_APPLICANT = 'REJECT_APPLICANT'
export const SET_WAVE = 'SET_WAVE'
export const SET_POPUP_STATUS = 'SET_POPUP_STATUS'
export const RESET_POPUP_STATUS = 'RESET_POPUP_STATUS'
export const SET_USERS_ARE_OVER = 'SET_USERS_ARE_OVER'
export const SKIP_USER_VIEW = 'SKIP_USER_VIEW'
export const SAVE_USERS = 'SAVE_USERS'