export const slides = format => [
	{
		img: `assets/upgradeSlides/${format}/1.jpg`,
		text: ['Send & receive unlimited messages', 'See who liked you']
	},
	{
		img: `assets/upgradeSlides/${format}/2.jpg`,
		text: [
			'Send & receive special requests to members you haven’t matched with and stand out',
			'Undo matches you’ve passed on if you change your mind'
		]
	},
	{
		img: `assets/upgradeSlides/${format}/3.jpg`,
		text: [
			'Access all premium filters to help you zone in on the exact matches you’re looking for'
		]
	}
]

export const textSlides = [
	'Send & receive unlimited messages',
	'See who liked you',
	'Send & receive special requests to members you haven’t matched with and stand out',
	'Access all premium filters to help you zone in on the exact matches you’re looking for',
	'Undo matches you’ve passed on if you change your mind'
]
