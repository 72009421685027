import * as types from './constants'
import * as services from '../services'
import { _get } from 'utils/lodash'
import { transformExchangeRates } from 'utils/transformExchangeRates'

export const getSubscriptionPlans = () => async dispatch => {
	try {
		const response = await services.getSubscriptionPlans()

		const plans = _get(response, 'result', [])

		dispatch({
			type: types.GET_SUBSCRIPTION_PLANS,
			payload: plans
		})
	} catch (e) {
		console.dir(e)
	}
}

export const getPaymentInfo = () => async dispatch => {
	try {
		const response = await services.getPaymentInfo()

		const info = _get(response, 'result', [])

		dispatch({
			type: types.GET_PAYMENT_INFO,
			payload: info ? [info] : []
		})
	} catch (e) {
		console.dir(e)
	}
}

export const getExchangeRates = () => async dispatch => {
	try {
		const response = await services.getExchangeRates()
		const transformedRates = transformExchangeRates(response)

		dispatch({
			type: types.GET_EXCHANGE_RATES,
			payload: transformedRates
		})
	} catch (e) {
		console.dir(e)
	}
}

export const getPaymentList = () => async dispatch => {
	try {
		const response = await services.getPaymentList()
		const info = _get(response, 'result', [])

		dispatch({
			type: types.GET_PAYMENT_LIST,
			payload: info
		})
	} catch (e) {
		console.dir(e)
	}
}

export const setCurrentPlan = (planId, promoCode = '') => (
	dispatch,
	getState
) => {
	const {
		subscriptionBilling: { config }
	} = getState()

	const currentPlan = config?.find(({ id }) => id === planId)
	currentPlan.promoCode = promoCode

	dispatch({
		type: types.SET_CURRENT_PLAN,
		payload: currentPlan
	})
}
