import * as types from './constants'
import * as services from '../services'
import { _get } from '../../../../utils/lodash'

export const getUnsubscribeInfo = () => async dispatch => {
	try {
		const response = await services.getUnsubscribeInfo()
		const unsubscribeInfo = _get(response, 'data', [])
		dispatch({
			type: types.GET_UNSUBSCRIBE_INFO,
			payload: unsubscribeInfo?.slice(3)
		})
	} catch (e) {
		console.dir(e)
	}
}

export const toggleCheckboxState = id => (dispatch, getState) => {
	const {
		emails: { unsubscribeInfo }
	} = getState()

	const toggled = unsubscribeInfo
	toggled[id].state = !toggled[id].state

	dispatch({
		type: types.TOGGLE_STATE,
		payload: {
			id,
			item: toggled[id]
		}
	})
}
