export const PDF = 'application/pdf'
export const SWF = 'application/x-shockwave-flash'
export const OGA = 'application/ogg'

export const JPEG = 'image/jpeg'
export const JPG = 'image/jpg'
export const PNG = 'image/png'
export const BMP = 'image/bmp'
export const GIF = 'image/gif'
export const SVG = 'image/svg+xml'

export const MP3 = 'audio/mpeg'
export const OGG = 'audio/ogg'
export const WAV = 'audio/x-wav'
export const WEBM = 'audio/webm'
export const MPGA = 'audio/mpeg'
export const WMA = 'audio/x-ms-wma'

export const MP4 = 'video/mp4'
export const AVI = 'video/x-msvideo'
export const FLV = 'video/x-flv'
export const MOV = 'video/quicktime'
export const MPEG = 'video/mpeg'
export const WMV = 'video/x-ms-wmv'
