import React, { memo, useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classes from './AboutMe.module.scss'
import EditedSection from 'components/ui/EditedSection'
import EditModal from 'modules/profile/createProfile/components/AboutMe/EditModal'
import { defaultAnswers } from 'modules/profile/createProfile/components/AboutMe/config'
import { sm } from 'utils/simulateEvent'

const AboutMe = ({ questions = [], name, value: values = {}, onChange }) => {
	const [currentAnswer, setCurrentAnswer] = useState(null)
	const [open, setOpen] = useState(false)

	const handleClickEdit = useCallback(id => {
		setCurrentAnswer(id)
		setOpen(true)
	}, [])

	const handleClickDone = useCallback(() => {
		setOpen(false)
	}, [])

	const closeDialog = useCallback(() => {
		const result = { ...values }

		delete result[currentAnswer]

		onChange(sm(name, result))
		setCurrentAnswer(null)
		setOpen(false)
	}, [currentAnswer, name, onChange, values])

	const renderQuestions = useMemo(
		() =>
			questions?.map(({ value, label }, index) => (
				<EditedSection
					key={`edit-section-${value}-${label}`}
					value={value}
					title={label}
					onClickEdit={handleClickEdit}
					className={classes.card}
					isSelect={values.hasOwnProperty(value)}
					answer={values[value] ? values[value] : defaultAnswers[value]}
				/>
			)),
		[questions, handleClickEdit, values]
	)

	const title = useMemo(
		() =>
			currentAnswer
				? questions.find(({ value }) => value === currentAnswer).label
				: '',
		[currentAnswer, questions]
	)

	return (
		<>
			<div className={classes.root}>{renderQuestions}</div>
			<EditModal
				open={open}
				name={`${name}[${currentAnswer}]`}
				title={title}
				onClose={closeDialog}
				onCancel={closeDialog}
				onClickDone={handleClickDone}
				value={values[currentAnswer]}
				placeholder={defaultAnswers[currentAnswer]}
			/>
		</>
	)
}

AboutMe.propTypes = {
	questions: PropTypes.array
}

AboutMe.defaultProps = {
	onChange: () => null,
	questions: []
}

export default memo(AboutMe)
