import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance'
import appConfig from 'constants/appConfig'

export const getMe = () => AuthorizedAxiosInstance.get('/user')

export const logout = () =>
	AuthorizedAxiosInstance.post(appConfig.apiPrefix + '/auth/logout')

export const resendConfirmLink = (data) =>
	AuthorizedAxiosInstance.post(appConfig.apiPrefix + '/auth/resend-email-verification', data)
