import React from 'react'
import classes from './Link.module.scss'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const Link = ({
	children,
	variant,
	size,
	className,
	lineHeight,
	area,
	onClick,
	underline,
	...props
}) => (
	<a
		role="presentation"
		className={clsx(classes.root, className, classes[variant], {
			[classes.underline]: underline
		})}
		style={{ gridArea: area }}
		onClick={onClick}
		{...props}
	>
		{children}
	</a>
)

Link.propTypes = {
	className: PropTypes.string,
	area: PropTypes.string,
	variant: PropTypes.oneOf(['secondary', 'primary']),
	size: PropTypes.number,
	underline: PropTypes.bool,
	onClick: PropTypes.func,
	lineHeight: PropTypes.number
}

Link.defaultProps = {
	className: '',
	area: '',
	variant: 'primary',
	size: 14,
	onClick: () => null,
	underline: false,
	lineHeight: 10
}

export default Link
