import React, { forwardRef, memo } from 'react'
import PropTypes from 'prop-types'
import classes from './BaseTextArea.module.scss'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import clsx from 'clsx'

const BaseTextArea = forwardRef(
	(
		{
			rowsMin,
			placeholder,
			value = '',
			name,
			onChange = () => null,
			className,
			maxLength,
			touched,
			...props
		},
		ref
	) => {
		return (
			<div className={classes.root}>
				<TextareaAutosize
					className={clsx(classes.input, className)}
					aria-label="minimum height"
					rowsMin={rowsMin}
					placeholder={placeholder}
					value={value}
					name={name}
					onChange={onChange}
					ref={ref}
					maxLength={maxLength}
					{...props}
				/>
				{maxLength ? (
					<div className={classes.maxLength}>
						{value.length}/{maxLength}
					</div>
				) : null}
			</div>
		)
	}
)

BaseTextArea.propTypes = {
	className: PropTypes.string
}

BaseTextArea.defaultProps = {
	className: ''
}

export default memo(BaseTextArea)
