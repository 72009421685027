import update from 'immutability-helper'
import * as types from './constants'
import {
	ageFrom,
	ageTo,
	job,
	drink,
	study,
	wantGender,
	kid,
	religion,
	smoke,
	familyPlans,
	horoscope
} from 'modules/profile/createProfile/config'

const initialState = {
	filters: {
		age: [18, 30],
		careerId: [],
		drinksId: [],
		educationId: [],
		genderId: [],
		haveKidsId: [],
		height: [10, 30],
		locations: {
			selectedIds: [],
			type: {},
			text: ''
		},
		religionId: [],
		smokingId: [],
		wantKidsId: [],
		horoscope: []
	},
	filtersInitialState: {
		[ageFrom]: 18,
		[ageTo]: 110,
		[job]: [],
		[drink]: [],
		[study]: [],
		[wantGender]: [],
		[kid]: [],
		height_from_id: 1,
		height_to_id: 45,
		location_type: 0,
		location_id: [],
		location_text: '',
		[religion]: [],
		[smoke]: [],
		[familyPlans]: [],
		[horoscope]: []
	},
	wantGenderIdFlag: false
}

const preferenceReducer = (state = { ...initialState }, { type, payload }) => {
	switch (type) {
		case types.GET_PREFERENCES: {
			return update(state, {
				filters: { $set: payload }
			})
		}
		case types.UPDATE_PREFERENCES: {
			return update(state, {
				filters: { $merge: payload }
			})
		}
		case types.UPDATE_WANT_GENDER_FLAG: {
			return update(state, {
				wantGenderIdFlag: { $set: true }
			})
		}
		case types.RESET_PREFERENCES: {
			return update(state, {
				filters: { $merge: payload }
			})
		}
		default:
			return state
	}
}

export default preferenceReducer
