import React, { memo } from 'react'
import { ReactComponent as GrayLogo } from 'assets/staticIcons/gray-logo.svg'
import { ReactComponent as DarkLogo } from 'assets/staticIcons/dark-logo.svg'
import classes from './BottomNavigationBar.module.scss'
import SpriteIcon from 'components/ui/SpriteIcon'
import UserAvatar from 'components/ui/UserAvatart'
import { Link, useRouteMatch } from 'react-router-dom'
import { routesByName } from 'constants/routes.js'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { getMeAvatar } from 'modules/profile/store/selectors'
import { isShowNavigationBar } from 'modules/app/store/selectors.js'

const NavigationBarItem = ({ Item, path }) => {
	const isCurrentPath = useRouteMatch(path)

	return (
		<Link to={path}>
			<Item
				className={clsx(classes.item, { [classes.selected]: isCurrentPath })}
				isSelected={isCurrentPath}
			/>
		</Link>
	)
}

const navigationBarItems = [
	{
		path: routesByName.main.discovery.root,
		Item: ({ isSelected, ...props }) =>
			isSelected ? <DarkLogo {...props} /> : <GrayLogo {...props} />
	},
	{
		path: routesByName.main.notifications.root,
		Item: ({ isSelected, ...props }) => (
			<SpriteIcon name="message-outlined" {...props} />
		)
	},
	// {
	// 	path: routesByName.main.lastMatch.root,
	// 	Item: ({ isSelected, ...props }) => (
	// 		<SpriteIcon name="match-icon" {...props} />
	// 	)
	// },
	{
		path: routesByName.main.profile.root,
		Item: ({ isSelected, ...props }) => {
			const userAvatar = useSelector(getMeAvatar)

			return (
				<UserAvatar
					src={userAvatar?.thumb}
					isSelected={!!isSelected}
					{...props}
				/>
			)
		}
	}
]
const BottomNavigationBar = ({ classes: outerClasses = {} }) => {
	const navigationBar = useSelector(isShowNavigationBar)

	return navigationBar ? (
		<div className={clsx(classes.root, outerClasses.root)}>
			{navigationBarItems.map((item, index) => (
				<NavigationBarItem key={`navigation-item-${index}`} {...item} />
			))}
		</div>
	) : null
}

export default memo(BottomNavigationBar)
