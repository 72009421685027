
import { useSelector } from 'react-redux'
import * as types from 'modules/app/store/constants'
import { setUpAuthInterceptorsAction } from 'libs/axios/AuthorizedAxiosInstance'
import { initAuthAction } from 'modules/auth/store/actions'
import * as appServices from '../services.js'
import errorToastr from 'libs/toastr/errorToastr'
import { _get } from 'utils/lodash'
import { initFacebookSdk } from 'libs/facebook'
import * as Sentry from '@sentry/browser'

export const initAppAction = () => async dispatch => {
	try {
		await dispatch(setUpAuthInterceptorsAction());

		// await dispatch(toggleLoader())
		await dispatch(setLoader(true));
		await dispatch(getAppConfig())
		await dispatch(initAuthAction())

		initFacebookSdk()

		dispatch({ type: types.APP_INITED })

	} catch (e) {
		await dispatch(setLoader(false));
		Sentry.captureException(e)
		errorToastr(e)
	} finally {
		// await dispatch(toggleLoader())
		await dispatch(setLoader(false));
	}
}

export const setShowNavigationBar = value => {
	return { type: types.SET_SHOW_NAVIGATION_BAR, payload: value }
}

export const toggleLoader = () => ({ type: types.TOGGLE_LOADER })

export const setLoader = value => ({
	type: types.SET_LOADER,
	payload: value
})

export const getAppConfig = () => async dispatch => {
	try {
		const config = await appServices.getAppConfig()

		dispatch({ type: types.GET_APP_CONFIG, payload: { config } })
	} catch (e) {
		Sentry.captureException(e)
	}
}

export const getReportReasons = () => async dispatch => {
	try {
		const response = await appServices.getReportReasons()

		dispatch({
			type: types.GET_REPORT_REASONS,
			payload: _get(response, 'result.reasons', [])
		})
	} catch (e) {
		Sentry.captureException(e)
	}
}

export const getUnmatchReasons = () => async dispatch => {
	try {
		const response = await appServices.getUnmatchReasons()

		dispatch({
			type: types.GET_UNMATCH_REASONS,
			payload: _get(response, 'result', {})
		})
	} catch (e) {
		Sentry.captureException(e)
	}
}

export const updateCounterBanner = () => dispatch => {
	dispatch({
		type: types.UPDATE_BANNER_COUNTER
	})
}

export const setIsShownUpgradeBanner = () => dispatch => {
	dispatch({
		type: types.SET_SHOW_BANNER
	})
}

export const getAppVersionFromMeta = () => async dispatch => {
	appServices
		.getMeta()
		.then(response => response.json())
		.then(meta => {
			dispatch({
				type: types.APP_SET_META_VERSION,
				payload: meta.version
			})
		})
		.catch(e => {
			Sentry.captureException(e)
		})
}

export const setAppVersionFromMeta = () => async dispatch => {
	appServices
		.getMeta()
		.then(response => response.json())
		.then(meta => {
			// eslint-disable-next-line
			console.log('Setting init app version:', meta.version)
			dispatch({
				type: types.APP_SET_VERSION,
				payload: meta.version
			})
		})
		.catch(e => {
			Sentry.captureException(e)
		})
}

export const setAppVersion = version => dispatch => {
	dispatch({
		type: types.APP_SET_VERSION,
		payload: version
	})
}

const appActions = {
	initAppAction,
	setShowNavigationBar,
	toggleLoader,
	getAppConfig,
	getReportReasons,
	getUnmatchReasons,
	updateCounterBanner,
	setIsShownUpgradeBanner,
	getAppVersionFromMeta,
	setAppVersionFromMeta
}

export default appActions
