import axios from 'axios'
import appConfig from 'constants/appConfig'
import RequestError from 'errors/RequestError'

const BaseAxiosInstance = axios.create({
	baseURL: appConfig.apiUrl
})

BaseAxiosInstance.interceptors.response.use(
	({ data }) => data,
	error => {
		throw new RequestError({ ...error })
	}
)

export default BaseAxiosInstance
