import update from 'immutability-helper'
import * as types from './constants'

const initialState = {
	config: [],
	paymentsInfo: [],
	exchangeRates: [],
	paymentList: [],
	currentPlan: {}
}

const subscriptionBillingReducer = (
	state = { ...initialState },
	{ type, payload }
) => {
	switch (type) {
		case types.GET_SUBSCRIPTION_PLANS: {
			return update(state, {
				config: { $set: payload }
			})
		}
		case types.GET_PAYMENT_INFO: {
			return update(state, {
				paymentsInfo: { $set: payload }
			})
		}
		case types.GET_EXCHANGE_RATES: {
			return update(state, {
				exchangeRates: { $set: payload }
			})
		}
		case types.GET_PAYMENT_LIST: {
			return update(state, {
				paymentList: { $set: payload }
			})
		}
		case types.SET_CURRENT_PLAN: {
			return update(state, {
				currentPlan: { $set: payload }
			})
		}
		default:
			return state
	}
}

export default subscriptionBillingReducer
