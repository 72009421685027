import * as types from './constants'
import * as services from '../services'
import { getUserById, saveUsers } from 'modules/users/actions'
import { extractFields } from 'utils/exctractIds'
import { _get, _isEmpty } from 'utils/lodash'
import * as usersServices from 'modules/users/services'
import { SET_PERSON_FOR_MATCH } from './constants'

export const getMatches = (params = {}, mode = 'set') => async dispatch => {
	try {
		const response = await services.getMatches({
			limit: 20,
			...params
		})

		const matches = _get(response, 'result', [])

		dispatch(saveUsers(matches))

		dispatch({
			type: mode === 'set' ? types.GET_MATCHES : types.PUSH_MATCHES,
			// payload: extractFields(matches)
			payload: {
				matches: extractFields(matches),
				hasMore: !!matches.length
			}
		})
	} catch (e) {
		console.dir(e)
	}
}

export const getLastMatch = () => async dispatch => {
	try {
		const response = await services.getMatches({
			limit: 1,
			sort: 'desc'
		})

		const match = _get(response, 'result[0]', {})

		const userId = match?.id || null

		if (userId) {
			dispatch(getUserById(userId))
			dispatch(saveUsers([match]))
		}

		dispatch({
			type: types.GET_LAST_MATCH,
			payload: userId
		})
	} catch (e) {
		console.dir(e)
	}
}

export const removeMatchById = userId => (dispatch, getState) => {
	const {
		matches: { items }
	} = getState()

	const filteredItems = items?.filter(item => item !== userId)

	dispatch({
		type: types.REMOVE_MATCH_BY_ID,
		payload: filteredItems
	})
}

export const setPersonForMatch = data => async (dispatch, getState) => {
	try {
		const {
			profile: {
				user: { id: myId }
			}
		} = getState()

		const personId =
			myId !== 0 && myId !== data?.person1 ? data?.person1 : data?.person2

		if (personId) {
			const response = await usersServices.getUserById(personId)
			const person = _get(response, 'result', {})

			if (!_isEmpty(person)) {
				dispatch(saveUsers([person]))
				dispatch({
					type: SET_PERSON_FOR_MATCH,
					payload: personId
				})
			}
		}
	} catch (e) {
		console.dir(e)
	}
}
