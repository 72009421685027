import useMaterialMediaQuery from '@material-ui/core/useMediaQuery'
import { breakpointsUp } from 'constants/breakpoints'
import { screenFormat } from 'constants/screenFormat'

const useDetermineScreenFormat = (breakpoint = breakpointsUp.lg) => {
	const moreThanBreakpoint = useMaterialMediaQuery(`(min-width:${breakpoint})`)

	return moreThanBreakpoint ? screenFormat.desktop : screenFormat.mobile
}

export default useDetermineScreenFormat
