import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { screenFormat } from 'constants/screenFormat'
import BaseScrollbars from 'components/wrappers/ui/BaseScrollbars'
import React from 'react'

// TODO Refactor
const ScrollbarWrapper = ({ children, height, margin, hide }) => {
	const format = useDetermineScreenFormat()

	return format === screenFormat.desktop && !hide ? (
		<div style={{ height, margin }}>
			<BaseScrollbars>{children}</BaseScrollbars>
		</div>
	) : (
		<>{children}</>
	)
}

export default ScrollbarWrapper
