import React, { useCallback, useMemo, useState } from 'react'
import Select, { components } from 'react-select'
import { getLocation } from 'modules/profile/services'
import { _debounce } from 'utils/lodash'
import _useEffect from 'hooks/_useEffect'
import { lettersOnly } from '../../../../modules/profile/createProfile/config'

const getStyles = provided => ({
	container: provided => ({
		...provided,
		width: '100%'
	}),

	option: provided => ({
		...provided,
		backgroundColor: '#F9F9F9',
		padding: '17px 0 17px 23px',
		fontFamily: 'Montserrat, sans-serif',
		fontStyle: 'normal',
		fontWeight: '500',
		fontSize: '16px',
		color: 'black',

		'&:hover': {
			backgroundColor: '#FFEAE3',
			cursor: 'pointer'
		},

		'&:active': {
			backgroundColor: '#FFC3AE'
		}
	}),

	control: (provided, state) => {
		const style = {
			...provided,
			// backgroundColor: '#FC8C66',
			border: `1px solid #FC8C66`,
			borderRadius: '8px',
			boxSizing: 'border-box',
			padding: '1px 8px 1px 15px',
			height: 'inherit',
			minHeight: '50px',
			fontSize: '20px',
			fontFamily: 'Montserrat',
			fontWeight: '500',

			'&:hover': {
				borderColor: '#FC8C66'
			},

			'& > div': {
				padding: 0
			}
		}

		if (state.isFocused) {
			style['borderColor'] = '#FC8C66'
			style['boxShadow'] = undefined
			style['backgroundColor'] = '#F7EDFF'
		}

		return style
	},
	input: () => ({
		fontFamily: 'Montserrat'
	}),
	indicatorsContainer: () => ({
		display: 'flex',
		alignItems: 'center',
		'& > div': {
			padding: 0
		}
	})
})

const DropdownIndicator = props => {
	return (
		<components.DropdownIndicator {...props}>
			<svg
				width="9"
				height="5"
				viewBox="0 0 9 5"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M8 1L4.5 4L1 1"
					stroke="#FC8C66"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</components.DropdownIndicator>
	)
}

const getLocationOptions = async (input = '', type = 0) => {
	try {
		if (input.length > 2) {
			const { result } = await getLocation({ input, type })
			return result.map(({ id: value, text: label }) => ({ value, label }))
		} else {
			return []
		}
	} catch (e) {
		console.dir(e)
	}
}

const BaseAsyncSelect = ({
	outerStyles = {},
	portalPlacement = 'auto',
	isFixed = true,
	value,
	placeholder,
	onChange = () => null,
	isClearable = false,
	type = 0
}) => {
	const [options, setOptions] = useState([])
	const [inputValue, setInputValue] = useState(value)

	const compValue = useMemo(
		() => options?.find(option => option.label === value),
		[options, value]
	)
	const customStyles = useMemo(() => getStyles(), [])

	const loadNewOptions = useCallback(
		value => {
			getLocationOptions(value, type).then(options => {
				setOptions(options)
			})
		},
		[type]
	)

	_useEffect(() => {
		if (typeof value === 'string' && value) {
			let cleanupFunction = false

			getLocationOptions(value, type)
				.then(options => {
					if (!cleanupFunction) setOptions(options)
					onChange({
						value: options.find(({ label }) => label === value)?.value
					})
				})
				.catch(console.dir)

			return () => (cleanupFunction = true)
		}
	}, [value])

	const onInputChangeHandler = useCallback(
		value => {
			const debouncedLoading = _debounce(value => {
				loadNewOptions(value)
			}, 300)

			if (value && lettersOnly(value)) {
				setInputValue(value)
				debouncedLoading(value)
			} else {
				setInputValue(value.slice(0, value.length - 1))
			}
		},
		[loadNewOptions]
	)

	return (
		<>
			<Select
				components={{
					IndicatorSeparator: undefined,
					DropdownIndicator
				}}
				styles={{ ...customStyles, ...outerStyles }}
				options={options}
				value={compValue}
				onChange={onChange}
				placeholder={placeholder}
				onInputChange={onInputChangeHandler}
				backspaceRemovesValue
				isClearable={isClearable}
				menuPosition={isFixed ? 'fixed' : 'absolute'}
				menuPlacement={portalPlacement}
				inputValue={inputValue}
			/>
		</>
	)
}

export default BaseAsyncSelect
