import { toastr } from 'react-redux-toastr'
import React from 'react'
import Button from 'components/ui/Button'
import classes from './Toastr.module.scss'

export const upgradeMessageConfig = {
	undoUpgrade: 'Undo matches you’ve passed on if you change your mind.',
	messageUpgrade:
		'Send a personalized message and stand out, even if you haven’t matched.'
}

const upgradeToastr = (
	onClickUpgrade,
	messageType = upgradeMessageConfig.messageUpgrade
) => {
	toastr.info('', messageType, {
		showCloseButton: true,
		closeOnToastrClick: true,
		timeOut: 3000,
		position: 'top-center',
		transitionIn: 'fadeIn',
		transitionOut: 'fadeOut',
		component: () => (
			<Button
				shape="rounded"
				color="white"
				className={classes.btn}
				size="sm"
				onClick={onClickUpgrade}
			>
				Upgrade now
			</Button>
		)
	})
}

export default upgradeToastr
