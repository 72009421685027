import FormikFieldInput from 'components/wrappers/formik/FormikFieldInput'
import React, { useCallback, useState } from 'react'
import Typography from 'components/ui/Typography'
import Heading from 'components/ui/Typography/Heading'
import clsx from 'clsx'
import classes from 'modules/profile/createProfile/View/View.module.scss'

import FormikFieldDatePicker from 'components/wrappers/formik/FormikFieldBaseDatePicker'
import FormikFieldRadioButtonsGroup from 'components/wrappers/formik/FormikFieldRadioButtonsGroup'
import FormikFieldSelectLocation from 'components/wrappers/formik/FormikFieldSelectLocation'
import FormikFieldCheckboxButtonsGroup from 'components/wrappers/formik/FormikFieldCheckboxButtonsGroup'
import FormikFieldNativeSelect from 'components/wrappers/formik/FormikFieldNativeSelect'
import FormikFieldAboutMe from 'components/wrappers/formik/FormikFieldAboutMe'
import FormikFieldSelectImages from 'components/wrappers/formik/FormikFieldSelectImages'

import yup from 'libs/yup'
import SpriteIcon from 'components/ui/SpriteIcon'
import {
	postLocation,
	postMedias,
	putAnswers,
	putBirthday,
	putDrink,
	putGender,
	putGrowth,
	putJob,
	putKid,
	putLanguages,
	putName,
	putPersonalities,
	putReligion,
	putSmoke,
	putStudy,
	putWantGender
} from 'modules/profile/services'

import Hint from 'components/ui/Hint'
import { configFields } from 'modules/profile/store/constants'
import ErrorsGroup from 'components/wrappers/formik/ErrorsGroup'
import Link from 'components/ui/Typography/Link'
import PhotoGuideline from 'modules/profile/createProfile/components/PhotoGuideline'

import { useField } from 'formik'
import * as moment from 'moment'
import { capitalize } from './utils'

export const firstName = 'firstName'
export const lastName = 'lastName'
export const birthday = 'birthday'
export const ageFrom = 'age_from'
export const ageTo = 'age_to'
export const gender = 'genderId'
export const drink = 'drinkId'
export const growth = 'growthId'
export const heightFrom = 'height_from_id'
export const heightTo = 'height_to_id'
export const job = 'jobId'
export const kid = 'haveKidId'
export const location = 'location_txt'
export const locations = 'locations'
export const locationId = 'location_id'
export const locationType = 'location_type'
export const locationTypeField = 'type'
export const locationSelectedIdField = 'selectedIds'
export const photos = 'photos'
export const religion = 'religionId'
export const smoke = 'smokeId'
export const personalities = 'personalities'
export const study = 'studyId'
export const wantGender = 'wantGenderId'
export const languages = 'languages'
export const answers = 'answers'
export const familyPlans = 'wantKidId'
export const horoscope = 'horoscope'

// export const lettersOnly = value => /^[a-zA-Z]*$/.test(value)
export const lettersOnly = value => /^[a-zA-Zá-ź\s,]+$/.test(value)

const nameTooltip = {
	title: 'Why last name?',
	text:
		'It helps us build a trusted and authentic community for you and others. Only your first name is shown publicly.'
}

const radioButtonsClasses = { root: 'mt-1' }

const defaultBodyShape = {
	Icon: ({ className }) => <div className={className} />,
	Title: () => null,
	Description: () => null,
	handleValues: () => null,
	Inputs: () => null,
	validationSchema: yup.object().shape({})
}

export const enterFirstName = {
	...defaultBodyShape,
	Title: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'mb-2')}
			{...props}
		>
			What is Your Name?
		</Heading>
	),
	Description: props => (
		<Typography variant="body-1" className={clsx(classes.description, 'mb-4')}>
			Name can only contain letters. no spaces or symbols. Nicknames allowed.
		</Typography>
	),
	handleValues: data =>
		putName({
			[firstName]: capitalize(data[firstName]),
			[lastName]: data[lastName]
		}),
	Inputs: ({ className }) => (
		<ErrorsGroup className={clsx(classes.firstNameContainer, className)}>
			<FormikFieldInput
				inputClass={classes.input}
				name={firstName}
				placeholder="First Name"
				showSuccessMessage={false}
			/>
			<FormikFieldInput
				inputClass={classes.input}
				name={lastName}
				placeholder="Last Name"
				tooltip={nameTooltip}
				showSuccessMessage={false}
			/>
		</ErrorsGroup>
	),
	validationSchema: yup.object().shape({
		[firstName]: yup
			.string()
			.min(2, 'Must contain a minimum of 2 letters.')
			.test('Letters only', 'Only letters allowed.', lettersOnly)
			.required('Please fill out the required fields.'),
		[lastName]: yup.string()
	})
}

export const enterBirthday = {
	...defaultBodyShape,
	Title: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'text-black')}
			{...props}
		>
			When is Your
		</Heading>
	),
	Description: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'mb-4')}
			{...props}
		>
			Birthday?
		</Heading>
	),
	handleValues: data => putBirthday({ [birthday]: data[birthday] }),
	Inputs: () => {
		const [{ value }] = useField(birthday)
		return (
			<>
				<FormikFieldDatePicker name={birthday} className="mb-1" />
				<Hint
					text={`Your age will be displayed as ${moment().diff(
						moment(value),
						'years'
					)}.`}
					className={classes.hint}
				/>
			</>
		)
	},
	validationSchema: yup.object().shape({
		[birthday]: yup.mixed().age(18)
	})
}

export const chooseGender = {
	...defaultBodyShape,
	Icon: props => <SpriteIcon name="gender" {...props} />,
	Title: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'text-black')}
			{...props}
		>
			What Is Your
		</Heading>
	),
	Description: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'mb-4')}
			{...props}
		>
			Gender?
		</Heading>
	),
	Inputs: ({ config }) => (
		<FormikFieldRadioButtonsGroup
			name={gender}
			options={config[configFields.genders].options}
			showErrorDialog
		/>
	),
	validationSchema: yup.object().shape({
		[gender]: yup.string().required('Please make a selection.')
	}),
	handleValues: data => putGender({ [gender]: data[gender] })
}
export const chooseGenderForDate = {
	...defaultBodyShape,
	Icon: props => <SpriteIcon name="gender" {...props} />,
	Title: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'text-black')}
			{...props}
		>
			Who Do You Want
		</Heading>
	),
	Description: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'mb-4')}
			{...props}
		>
			To Date?
		</Heading>
	),
	Inputs: ({ config }) => (
		<FormikFieldRadioButtonsGroup
			name={wantGender}
			options={config[configFields.wantGenders].options}
			showErrorDialog
		/>
	),
	validationSchema: yup.object().shape({
		[wantGender]: yup
			.string()
			.required('Please make a selection.')
	}),
	handleValues: data => putWantGender({ [wantGender]: data[wantGender] })
}
const choosePhotos = {
	...defaultBodyShape,
	Icon: () => null,
	Title: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'mb-2')}
			{...props}
		>
			Add your Photos
		</Heading>
	),
	Description: props => {
		const [show, setShow] = useState(false)

		const openModal = useCallback(() => setShow(true), [])
		const closeModal = useCallback(() => setShow(false), [])

		return (
			<div className={classes.photosDesc}>
				<Typography
					variant="body-1"
					className={clsx(classes.description, 'mb-4')}
				>
					Your first photo must clearly show your full face, without sunglasses
					and other people.{' '}
					<Link underline variant="secondary" onClick={openModal}>
						Here
					</Link>{' '}
					are things we don’t allow.
				</Typography>
				<PhotoGuideline open={show} onClose={closeModal} />
			</div>
		)
	},
	Inputs: () => {
		const [{ value }, { error }] = useField(photos)

		return (
			<>
				<FormikFieldSelectImages name={photos} showErrorDialog />
				{value && value.length > 2 && !error && (
					<Hint text="Wow. You look amazing!" className={classes.hint} />
				)}
			</>
		)
	},
	validationSchema: yup.object().shape({
		[photos]: yup.mixed().photos(3)
	}),
	handleValues: async data => {
		const request = (data[photos] || [])?.reduce((acc, photo, index) => {
			const type = index === 0 ? 'avatar' : 'photo'
			return photo instanceof Blob
				? [...acc, postMedias([data[photos][index]], type)]
				: acc
		}, [])

		return await Promise.allSettled(request)
	}
}

export const chooseSelfLocation = {
	...defaultBodyShape,
	Icon: props => <SpriteIcon name="map-mark" {...props} />,
	Title: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'text-black')}
			{...props}
		>
			Where Do You
		</Heading>
	),
	Description: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'mb-4')}
			{...props}
		>
			Live?
		</Heading>
	),
	Inputs: ({ user, ...props }) => (
		<FormikFieldSelectLocation name={location} {...props} showErrorDialog />
	),
	validationSchema: yup.object().shape({
		[location]: yup.number().required('Please fill out the required fields.')
	}),
	handleValues: async (data, dispatch) => postLocation({ id: data[location] })
}
export const chooseLanguages = {
	...defaultBodyShape,
	Icon: props => <SpriteIcon name="translate" {...props} />,
	Title: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'text-black')}
			{...props}
		>
			What
			<Heading
				variant="heading-4"
				className={clsx(classes.title, 'mb-4')}
				{...props}
			>
				{' '}
				Language(s){' '}
			</Heading>
		</Heading>
	),
	Description: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'text-black mb-4')}
			{...props}
		>
			Are You Fluent In?
		</Heading>
	),
	Inputs: ({ config }) => (
		<>
			<Hint text="Select all that apply" className={classes.hint} />
			<FormikFieldCheckboxButtonsGroup
				name={languages}
				options={config[configFields.languages].options}
				classes={radioButtonsClasses}
				showErrorDialog
			/>
		</>
	),
	validationSchema: yup.object().shape({
		[languages]: yup
			.array()
			.required('Please make a selection.')
	}),
	handleValues: data => putLanguages({ [languages]: data[languages] })
}
export const chooseEducationLevel = {
	...defaultBodyShape,
	Icon: props => <SpriteIcon name="education" {...props} />,
	Title: props => (
		<div className={classes.educationTitleWrapper}>
			<Heading
				variant="heading-4"
				className={clsx(classes.title, 'text-black', classes.education)}
				{...props}
			>
				What's the{' '}
			</Heading>
			<Heading
				variant="heading-4"
				className={clsx(classes.title, classes.education)}
				{...props}
			>
				Highest Level{' '}
			</Heading>
		</div>
	),
	Description: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'text-black mb-4')}
			{...props}
		>
			You’ve Attained?
		</Heading>
	),
	Inputs: ({ config }) => (
		<FormikFieldRadioButtonsGroup
			name={study}
			variant="secondary"
			options={config[configFields.educations].options}
			showErrorDialog
		/>
	),
	validationSchema: yup.object().shape({
		[study]: yup.string().required('Please make a selection.')
	}),
	handleValues: data => putStudy({ [study]: data[study] })
}
export const chooseJob = {
	...defaultBodyShape,
	Icon: props => <SpriteIcon name="job" {...props} />,
	Title: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'text-black')}
			{...props}
		>
			What's Your
		</Heading>
	),
	Description: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'mb-4')}
			{...props}
		>
			Job Title?
		</Heading>
	),
	Inputs: ({ config, format }) => (
		<FormikFieldRadioButtonsGroup
			name={job}
			withSearch
			className={classes.jobsInput}
			hasSearch
			searchPlaceholder="Search your Job Title"
			options={config[configFields.jobs].options}
			showErrorDialog
		/>
	),
	validationSchema: yup.object().shape({
		[job]: yup.string().required('Please make a selection.')
	}),
	handleValues: data => putJob({ [job]: data[job] })
}
export const chooseGrowth = {
	...defaultBodyShape,
	Icon: props => <SpriteIcon name="tall" {...props} />,
	Title: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'text-black mb-4')}
			{...props}
		>
			How
			<Heading variant="heading-4" className={clsx(classes.title)} {...props}>
				{' '}
				Tall{' '}
			</Heading>
			Are You?
		</Heading>
	),
	Inputs: ({ config }) => (
		<FormikFieldNativeSelect
			name={growth}
			placeholder="Please select height"
			options={config[configFields.growth].options}
			showErrorDialog
		/>
	),
	validationSchema: yup.object().shape({
		[growth]: yup.string().required('Please make a selection.')
	}),
	handleValues: data => putGrowth({ [growth]: data[growth] })
}
export const chooseReligion = {
	...defaultBodyShape,
	Icon: props => <SpriteIcon name="religion" {...props} />,
	Title: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'text-black')}
			{...props}
		>
			What Is Your
		</Heading>
	),
	Description: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'mb-4')}
			{...props}
		>
			Religion?
		</Heading>
	),
	Inputs: ({ config }) => (
		<FormikFieldRadioButtonsGroup
			name={religion}
			options={config[configFields.religions].options}
			showErrorDialog
		/>
	),
	validationSchema: yup.object().shape({
		[religion]: yup
			.string()
			.required('Please make a selection.')
	}),
	handleValues: data => putReligion({ [religion]: data[religion] })
}
export const describePersonality = {
	...defaultBodyShape,
	Icon: props => <SpriteIcon name="emoji" {...props} />,
	Title: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'text-black')}
			{...props}
		>
			How would you describe
		</Heading>
	),
	Description: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'mb-4')}
			{...props}
		>
			your personality?
		</Heading>
	),
	Inputs: ({ config }) => (
		<>
			<Hint text="Select 3-8 options" className={classes.hint} />
			<FormikFieldCheckboxButtonsGroup
				name={personalities}
				withSearch
				displaySelected
				options={config[configFields.personalities].options}
				classes={radioButtonsClasses}
				showErrorDialog
			/>
		</>
	),
	validationSchema: yup.object().shape({
		[personalities]: yup
			.array()
			.min(3, 'Select 3 to 8 options only.')
			.max(8, 'Select 3 to 8 options only.')
			.required('Please fill out all the required fields.')
	}),
	handleValues: data =>
		putPersonalities({ [personalities]: data[personalities] })
}
export const aboutMe = {
	...defaultBodyShape,
	Title: props => (
		<>
			<Heading
				variant="heading-4"
				className={clsx(classes.title, 'mb-1')}
				{...props}
			>
				About Me
			</Heading>
			<Hint text="Answer 2 prompts." className={clsx('mb-6', classes.hint)} />
		</>
	),
	Inputs: ({ config }) => (
		<FormikFieldAboutMe
			name={answers}
			questions={config[configFields.questions].options}
			showErrorDialog
		/>
	),
	handleValues: data => putAnswers({ [answers]: data[answers] }),
	validationSchema: yup.object().shape({
		[answers]: yup.mixed().answers()
	})
}
export const whatAboutKids = {
	...defaultBodyShape,
	Icon: props => <SpriteIcon name="kids" {...props} />,
	Title: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'text-black')}
			{...props}
		>
			What About
		</Heading>
	),
	Description: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'mb-4')}
			{...props}
		>
			Kids?
		</Heading>
	),
	Inputs: ({ config }) => (
		<>
			<FormikFieldRadioButtonsGroup
				name={kid}
				options={config[configFields.haveKids].options}
				showErrorDialog
			/>
			<hr className="my-2" />
			<FormikFieldRadioButtonsGroup
				name={familyPlans}
				options={config[configFields.familyPlans].options}
				showErrorDialog
			/>
		</>
	),
	validationSchema: yup.object().shape({
		[kid]: yup.string().required('Please make a selection.'),
		[familyPlans]: yup
			.string()
			.required('Please make a selection.')
	}),
	handleValues: data =>
		putKid({ [kid]: data[kid], [familyPlans]: data[familyPlans] })
}
export const doYouSmoke = {
	...defaultBodyShape,
	Icon: props => <SpriteIcon name="smoke" {...props} />,
	Title: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'text-black')}
			{...props}
		>
			Do You
		</Heading>
	),
	Description: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'mb-4')}
			{...props}
		>
			Smoke?
		</Heading>
	),
	Inputs: ({ config = {} }) => (
		<FormikFieldRadioButtonsGroup
			name={smoke}
			options={config[configFields.smoke]?.options}
			showErrorDialog
		/>
	),
	validationSchema: yup.object().shape({
		[smoke]: yup.string().required('Please make a selection.')
	}),
	handleValues: data => putSmoke({ [smoke]: data[smoke] })
}
export const doYouDrink = {
	...defaultBodyShape,
	Icon: props => <SpriteIcon name="drink" {...props} />,
	Title: props => (
		<Heading
			variant="heading-4"
			className={clsx(classes.title, 'text-black mb-4')}
			{...props}
		>
			Do You{' '}
			<Heading variant="heading-4" className={classes.title} {...props}>
				Drink?
			</Heading>
		</Heading>
	),
	Inputs: ({ config }) => (
		<FormikFieldRadioButtonsGroup
			name={drink}
			options={config[configFields.drink].options}
			showErrorDialog
		/>
	),
	validationSchema: yup.object().shape({
		[drink]: yup.string().required('Please make a selection.')
	}),
	handleValues: data => putDrink({ [drink]: data[drink] })
}

export const stepByField = {
	[firstName]: 0,
	[birthday]: 1,
	[gender]: 2,
	[wantGender]: 3,
	[photos]: 4,
	[location]: 5,
	[languages]: 6,
	[study]: 7,
	[job]: 8,
	[growth]: 9,
	[religion]: 10,
	[personalities]: 11,
	[answers]: 12,
	[kid]: 13,
	[familyPlans]: 13,
	[smoke]: 14,
	[drink]: 15
}

export const steps = [
	enterFirstName, //0
	enterBirthday, //1
	chooseGender, //2
	chooseGenderForDate, //3
	choosePhotos, //4
	chooseSelfLocation, //5
	chooseLanguages, //5
	chooseEducationLevel, //6
	chooseJob, //7
	chooseGrowth, //8
	chooseReligion, // 9
	describePersonality, //10
	aboutMe, //11
	whatAboutKids, //12
	doYouSmoke, //13
	doYouDrink //14
]
