import * as types from 'modules/app/store/constants'
import update from 'immutability-helper'

const initialState = {
	inited: false,
	isShowNavigationBar: true,
	isLoading: false,
	isShownUpgradeBanner: false,
	countToShowUpgradeBanner: 0,
	appVersion: '',
	isReload: false,
	appMetaVersion: '',
	config: {},
	reportReasons: [],
	unmatchReasons: {}
}

const appReducer = (state = { ...initialState }, { type, payload }) => {
	switch (type) {
		case types.APP_INITED: {
			return update(state, {
				inited: { $set: true }
			})
		}
		case types.APP_SET_VERSION: {
			return update(state, {
				appVersion: { $set: payload }
			})
		}
		case types.APP_SET_META_VERSION: {
			return update(state, {
				appMetaVersion: { $set: payload }
			})
		}
		case types.SET_SHOW_NAVIGATION_BAR: {
			return update(state, {
				$merge: {
					isShowNavigationBar: payload
				}
			})
		}
		case types.GET_APP_CONFIG: {
			return update(state, {
				config: { $set: payload.config }
			})
		}
		case types.SET_LOADER: {
			return update(state, {
				isLoading: { $set: payload }
			})
		}
		case types.TOGGLE_LOADER: {
			return update(state, {
				isLoading: { $set: !state.isLoading }
			})
		}
		case types.GET_REPORT_REASONS: {
			return update(state, {
				reportReasons: { $set: payload }
			})
		}
		case types.GET_UNMATCH_REASONS: {
			return update(state, {
				unmatchReasons: { $set: payload }
			})
		}
		case types.UPDATE_BANNER_COUNTER: {
			return update(state, {
				countToShowUpgradeBanner: { $set: state.countToShowUpgradeBanner + 1 }
			})
		}
		case types.SET_SHOW_BANNER: {
			return update(state, {
				isShownUpgradeBanner: { $set: true }
			})
		}
		default:
			return state
	}
}

export default appReducer
