import React, { memo, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classes from 'components/ui/controls/BaseRadioButtonsGroup/BaseRadioButtonsGroup.module.scss'
import RadioItem from 'components/ui/controls/BaseRadioButton'
import clsx from 'clsx'
import useButtonsGroupHelper from 'hooks/useButtonsGroupHelper'
import ScrollbarWrapper from 'components/ui/layout/ScrollbarWrapper'
import BaseChip from 'components/ui/BaseChip'
import { _get } from 'utils/lodash'
import { sm } from 'utils/simulateEvent'
import { SELECT_ALL_LABEL, SELECT_ALL_VALUE, EXCLUDE_LABEL } from './constants'
import { wantGender } from '../../../../modules/profile/createProfile/config'

const BaseCheckboxButtonsGroup = ({
	options,
	onChange,
	value: values,
	name,
	typePreference,
	withSearch,
	searchPlaceholder,
	displaySelected,
	classes: outerClasses = {},
	variant,
	className
}) => {
	const { searchComponent, filteredOptions } = useButtonsGroupHelper({
		options,
		withSearch,
		displaySelected,
		searchPlaceholder
	})

	if (
		typePreference &&
		!filteredOptions.find(option => option.value === SELECT_ALL_VALUE) &&
		name !== wantGender
	) {
		filteredOptions.unshift({
			label: SELECT_ALL_LABEL,
			value: SELECT_ALL_VALUE
		})
	}

	const modifiedFilterOptions = filteredOptions.find(
		option => option.label === EXCLUDE_LABEL
	)
		? filteredOptions.filter(option => option.label !== EXCLUDE_LABEL)
		: filteredOptions

	const removeItem = useCallback(
		selectedRadio => {
			typePreference
				? onChange(
						sm(
							name,
							values.filter(value => value !== selectedRadio)
						)
				  )
				: onChange(values.filter(value => value !== selectedRadio))
		},
		[onChange, values, name, typePreference]
	)

	const handleClick = useCallback(
		selectedRadio => {
			if (selectedRadio === SELECT_ALL_VALUE) {
				onChange(sm(name, []))
				return
			}
			if (values.includes(selectedRadio)) {
				removeItem(selectedRadio)
			} else {
				onChange(
					typePreference
						? sm(name, [...values, selectedRadio])
						: [...values, selectedRadio]
				)
			}
		},
		[onChange, removeItem, values, name, typePreference]
	)

	const isSelect = value => {
		if (value === SELECT_ALL_VALUE && !values.length) {
			return true
		}
		return values.includes(value)
	}

	const renderSelectedItems = useMemo(
		() =>
			displaySelected && values.length ? (
				<ScrollbarWrapper height={70} hide={!withSearch}>
					<div className={classes.displayedValueContainer}>
						{values.map(value => (
							<BaseChip
								key={`selected-checkbox-${value}`}
								id={value}
								title={_get(
									options.find(({ value: option }) => option === value),
									'label',
									''
								)}
								onClose={removeItem}
								className={classes.chip}
							/>
						))}
					</div>
				</ScrollbarWrapper>
			) : null,
		[displaySelected, options, removeItem, values, withSearch]
	)
	// TODO Refactor ScrollbarWrapper
	return (
		<div className={clsx(classes.root, outerClasses.root)}>
			{searchComponent}
			{renderSelectedItems}
			<ScrollbarWrapper height={240} hide={!withSearch}>
				<div
					className={clsx(
						classes.radioButtonsContainer,
						classes[variant],
						className,
						{
							[classes.margin]: withSearch
						}
					)}
				>
					{modifiedFilterOptions?.map(({ value: radioValue, label }) => (
						<RadioItem
							variant="secondary"
							key={`radio-item-${radioValue}-${label}`}
							value={radioValue}
							label={label}
							onClick={handleClick}
							isSelect={isSelect(radioValue)}
						/>
					))}
				</div>
			</ScrollbarWrapper>
		</div>
	)
}

BaseCheckboxButtonsGroup.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	typePreference: PropTypes.bool,
	options: PropTypes.array,
	value: PropTypes.array,
	onChange: PropTypes.func
}

BaseCheckboxButtonsGroup.defaultProps = {
	className: '',
	value: [],
	name: '',
	typePreference: false,
	onChange: () => null,
	options: []
}

export default memo(BaseCheckboxButtonsGroup)
