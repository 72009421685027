import React from 'react'
import PropTypes from 'prop-types'
import classes from './Hint.module.scss'
import Typography from 'components/ui/Typography'
import clsx from 'clsx'

const Hint = ({ className, text }) => {
	return (
		<Typography className={clsx(className, classes.root)}>* {text}</Typography>
	)
}

Hint.propTypes = {
	className: PropTypes.string,
	text: PropTypes.string
}

Hint.defaultProps = {
	className: '',
	text: '123'
}

export default Hint
