import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance'
import appConfig from 'constants/appConfig'

export const getMatches = params =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + `/discovery/matches`, {
		params
	})

export const getLastMatch = () =>
	AuthorizedAxiosInstance.post(appConfig.apiPrefix + `/discovery/matches/last`)

export const matchConnected = userId =>
	AuthorizedAxiosInstance.post(
		appConfig.apiPrefix + `/discovery/matches/connected/${userId}`
	)

export const weMet = userId =>
	AuthorizedAxiosInstance.post(appConfig.apiPrefix + `/user/meet/${userId}`)
