import { _get } from 'utils/lodash'

const accessTokenKey = 'accessToken'
const refreshTokenKey = 'refreshToken'

export const getAccessToken = () => {
	return localStorage.getItem(accessTokenKey)
}

export const hasToken = () => {
	return !!getAccessToken()
}

export const setAccessToken = token => {
	localStorage.setItem(accessTokenKey, token)
}

export const getRefreshToken = () => {
	return localStorage.getItem(refreshTokenKey)
}

export const setRefreshToken = token => {
	localStorage.setItem(refreshTokenKey, token)
}

export const removeItemAccessToken = token => {
	localStorage.removeItem(accessTokenKey)
}

export const removeItemRefreshToken = token => {
	localStorage.removeItem(refreshTokenKey)
}

export const setAuthorizationData = data => {
	const tokenType = _get(data, 'result.token_type', 'Bearer')
	const accessToken = _get(data, 'result.access_token', '')
	const refreshToken = _get(data, 'result.refresh_token', '')

	setAccessToken(`${tokenType} ${accessToken}`)
	setRefreshToken(refreshToken)
}

export const clearAuthorizationData = () =>
	removeItemAccessToken() && removeItemRefreshToken()
