import React, { useCallback, useEffect, useRef } from 'react'
import { Scrollbars as CustomScrollbars } from 'react-custom-scrollbars'
import { useLocation } from 'react-router-dom'
import classes from './BaseScrollbars.module.scss'
import useFlagManager from 'hooks/useFlagManager.js'
import _useDidMount from 'hooks/useDidMount'

const BaseScrollbars = ({
	children,
	innerRef = {},
	hide = false,
	...props
}) => {
	const hovering = useFlagManager(false)
	const scrollBar = useRef()

	const { pathname } = useLocation()

	useEffect(() => {
		if (scrollBar && scrollBar.current) scrollBar.current.scrollToTop()
		//eslint-disable-next-line
	}, [pathname])

	_useDidMount(() => {
		innerRef.current = scrollBar.current
	}, [])

	const Track = useCallback(
		({ style }) => (
			<div
				className={classes.scrollTrack}
				style={{
					...style,
					width: hide ? 0 : 3,
					opacity: hovering.state ? 1 : 0
				}}
			/>
		),
		[hide, hovering.state]
	)

	const Thumb = useCallback(
		({ style }) => <div className={classes.scrollThumb} style={style} />,
		[]
	)

	return (
		<CustomScrollbars
			ref={scrollBar}
			renderTrackVertical={Track}
			renderThumbVertical={Thumb}
			renderTrackHorizontal={Track}
			renderThumbHorizontal={Thumb}
			onMouseEnter={hovering.turnIn}
			onMouseLeave={hovering.turnOff}
			autoHide
			hideTracksWhenNotNeeded
			{...props}
		>
			{children}
		</CustomScrollbars>
	)
}

export default BaseScrollbars
