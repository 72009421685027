import React from 'react'
import BaseFormikWrapper from 'components/wrappers/formik/index'
import PropTypes from 'prop-types'
import BaseRadioButtonsGroup from 'components/ui/controls/BaseRadioButtonsGroup'

const FormikFieldRadioButtonsGroup = props => (
	<BaseFormikWrapper component={BaseRadioButtonsGroup} {...props} />
)

FormikFieldRadioButtonsGroup.propTypes = {
	name: PropTypes.string.isRequired
}

export default FormikFieldRadioButtonsGroup
