import React from 'react'
import PropTypes from 'prop-types'
import classes from './ErrorMessage.module.scss'
import clsx from 'clsx'
import { _isObject } from 'utils/lodash'

const ErrorMessage = ({ children, className }) => {
	return (
		<span className={clsx(classes.root, className)}>
			*{_isObject(children) ? children.message : children}
		</span>
	)
}

ErrorMessage.propTypes = {
	className: PropTypes.string
}

ErrorMessage.defaultProps = {
	className: ''
}

export default ErrorMessage
