import React, { useMemo } from 'react'
import classes from './Step2.module.scss'
import clsx from 'clsx'
import { ReactComponent as NotInterested } from 'assets/staticIcons/not-interested.svg'
import { ReactComponent as Like } from 'assets/staticIcons/like.svg'
import Button from 'components/ui/Button'
import SpriteIcon from 'components/ui/SpriteIcon'
import { black3, primaryColor } from 'constants/colors'

const Step2 = () => {
	const renderMobileButtons = useMemo(
		() => (
			<>
				<Button
					size="xl"
					shape="rounded"
					color="white"
					className={clsx(classes.btn, classes.likeBtn)}
				>
					<SpriteIcon
						name="like"
						className={classes.icon}
						color={primaryColor}
					/>
				</Button>
				<Button
					size="xl"
					shape="rounded"
					color="white"
					className={clsx(classes.btn, classes.passBtn)}
				>
					<SpriteIcon name="close" className={classes.icon} color={black3} />
				</Button>
			</>
		),
		[]
	)

	return (
		<div>
			<div className={clsx(classes.root, classes.notInterested)}>
				<NotInterested />
			</div>
			<div className={clsx(classes.root, classes.like)}>
				<Like />
			</div>
			{renderMobileButtons}
		</div>
	)
}

export default Step2
