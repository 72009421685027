function unionArray(arrA, arrB) {
	const setA = new Set([...arrA])
	const setB = new Set([...arrB])

	const union = new Set(setA)
	for (let elem of setB) {
		union.add(elem)
	}
	return [...union]
}

export default unionArray
