import React from 'react'
import classes from './Step3.module.scss'
import { ReactComponent as BlockReport } from 'assets/staticIcons/blockReport.svg'
import { ReactComponent as Undo } from 'assets/staticIcons/undo.svg'
import { ReactComponent as Request } from 'assets/staticIcons/request.svg'
import Button from 'components/ui/Button'
import SpriteIcon from 'components/ui/SpriteIcon'
import { black3, secondaryColor } from 'constants/colors'
import clsx from 'clsx'

const Step3 = () => {
	return (
		<div className={classes.root}>
			<div className={classes.buttonsContainer}>
				<div className={classes.item}>
					<BlockReport />
					<Button
						shape="rounded"
						size="xs"
						color="white"
						className={clsx(classes.btn, classes.report)}
					>
						<SpriteIcon name="context-menu" color={black3} />
					</Button>
				</div>

				<div className={classes.item}>
					<Undo />
					<Button
						shape="rounded"
						size="xs"
						color="white"
						className={clsx(classes.btn, classes.reply)}
					>
						<SpriteIcon name="reply" color={secondaryColor} />
					</Button>
				</div>

				<div className={classes.item}>
					<Request />
					<Button
						shape="rounded"
						size="xs"
						color="white"
						className={clsx(classes.btn, classes.message)}
					>
						<SpriteIcon name="message-cloud" color={secondaryColor} />
					</Button>
				</div>
			</div>
		</div>
	)
}

export default Step3
