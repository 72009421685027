import * as types from './constants'
import * as services from 'modules/preferences/services'
import { deserializePreference } from 'modules/preferences/transformers.js'
import { _get } from 'utils/lodash'

export const getPreference = () => async dispatch => {
	try {
		const response = await services.getPreference()

		const filters = _get(response, 'result', {})

		if (filters?.location_id.length) {
			filters.location_text = await services.getLocationsByIds(
				filters.location_id
			)
		}

		const transformFilters = deserializePreference(filters)

		dispatch({
			type: types.GET_PREFERENCES,
			payload: transformFilters
		})
	} catch (e) {
		console.dir(e)
	}
}

export const updatePreference = data => async dispatch => {
	try {
		await services.updatePreference(data)

		dispatch({
			type: types.UPDATE_PREFERENCES,
			payload: data
		})
	} catch (e) {
		console.dir(e)
	}
}

export const resetPreferences = () => (dispatch, getState) => {
	const {
		preference: { filtersInitialState }
	} = getState()

	dispatch({
		type: types.RESET_PREFERENCES,
		payload: filtersInitialState
	})
}
