import { getAccessToken } from 'utils/authorize'
import { AUTH_INIT } from 'modules/auth/store/constants'
import { attachTokenToMessengerApiInstance } from 'libs/messenger'
import {logout, resendConfirmLink} from 'modules/auth/services.js'
import { clearPersistStorage } from 'libs/persist/config.js'
import { routesByName } from 'constants/routes'
import { history } from 'store/store'
import { clearStore } from 'store/actions.js'
// import {cancelApi} from "index"

export const initAuthAction = () => async (dispatch, getState) => {
	const token = getAccessToken()
	const {
		auth: { logined }
	} = getState()
	if (token) {
		attachTokenToMessengerApiInstance()
		dispatch({ type: AUTH_INIT, payload: { logined: true } })
	} else if (!token && logined) {
		dispatch(logOut())
	}
}

export const clearApplicationState = () => async dispatch => {
	try {
		localStorage.clear()
		await dispatch(clearStore())
		await clearPersistStorage()
		await history.push(routesByName.home)
	} catch (e) {
		console.dir(e)
	}
}

export const logOut = () => async dispatch => {
	try {
		await logout()
		// cancelApi.cancel()
		dispatch({ type: AUTH_INIT, payload: { logined: false } })
		await dispatch(clearApplicationState())
	} catch (e) {
		console.dir(e)
	}
}
export const resendLink = (data) => async () => {
	try {
		await resendConfirmLink(data);
	} catch (e) {
		console.dir(e)
	}
}
