import { lazy } from 'react'
import { routesByName } from 'constants/routes'
import mapRouteConfig from 'utils/mapRouteConfig'

const SuccessStories = lazy(() => import('pages/SuccessStories'))
const ComingSoon = lazy(() => import('pages/ComingSoon'))
const ContactPage = lazy(() => import('pages/ContactPage'))
const AboutPage = lazy(() => import('pages/AboutPage'))
const FAQPage = lazy(() => import('pages/FAQPage'))
const PrivacyPolicyPage = lazy(() => import('pages/PrivacyPolicyPage'))
const TermsAndConditionsPage = lazy(() =>
	import('pages/TermsAndConditionsPage')
)

const CommonRouteConfig = [
	{
		path: routesByName.successStories,
		component: SuccessStories,
		exact: false
	},
	{
		path: routesByName.blog,
		component: ComingSoon,
		exact: false
	},
	{
		path: routesByName.contact,
		component: ContactPage,
		exact: false
	},
	{
		path: routesByName.about,
		component: AboutPage,
		exact: false
	},
	{
		path: routesByName.faq,
		component: FAQPage,
		exact: false
	},
	{
		path: routesByName.common.privacy,
		component: PrivacyPolicyPage,
		exact: true
	},
	{
		path: routesByName.common.terms,
		component: TermsAndConditionsPage,
		exact: true
	}
]

export default mapRouteConfig(CommonRouteConfig)
