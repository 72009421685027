import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classes from './PrivacyPolicy.module.scss'
import clsx from 'clsx'
import Typography from 'components/ui/Typography'
import { routesByName } from 'constants/routes'
import useHistory from 'hooks/useHistory'

const PrivacyPolicy = ({ className, linkColor }) => {
	const history = useHistory()

	const handleClickTermsAndConditions = useCallback(() => {
		history.push(routesByName.common.terms)
	}, [history])

	const handleClickPrivacyPolicy = useCallback(() => {
		history.push(routesByName.common.privacy)
	}, [history])

	return (
		<div className={clsx(className, classes.root)}>
			<Typography className={clsx('mt-1 mb-1', classes.infoText)}>
				By joining, you accept our
				<span
					role="presentation"
					className={classes.infoTextLink}
					style={{ color: linkColor }}
					onClick={handleClickTermsAndConditions}
				>
					{' '}
					Terms of Use{' '}
				</span>
				<span
					role="presentation"
					className={classes.infoTextLink}
					style={{ color: linkColor }}
					onClick={handleClickPrivacyPolicy}
				>
					<span className={classes.privacy}>
						<Typography className={classes.infoText}> and </Typography>Privacy
						Policy
					</span>
				</span>
			</Typography>
		</div>
	)
}

PrivacyPolicy.propTypes = {
	className: PropTypes.string
}

PrivacyPolicy.defaultProps = {
	className: ''
}

export default PrivacyPolicy
