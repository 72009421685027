import * as types from 'modules/profile/store/constants'
import {
	getMe,
	getRejectReason,
	profileOptions
} from 'modules/profile/services'
import { _get } from 'utils/lodash'
import { transformObjectToOptionsArray } from 'utils/optionsTransformer'
import { determineMissingSteps } from 'modules/profile/createProfile/utils'
import {
	buildSrcWithHashAndUserId,
	preparePhotosSrc
} from 'modules/users/utils'
import { getPreference } from 'modules/preferences/store/actions'
import { SET_SUBSCRIPTION_STATUS } from 'modules/profile/store/constants'
import { transformSubscriptionStatus } from '../transformers'
import { GET_REJECT_REASON } from 'modules/profile/store/constants'
import moment from 'moment'

export const getProfileOptions = () => async dispatch => {
	try {
		const options = await Promise.allSettled(
			profileOptions.map(({ getter }) => getter())
		)

		const transformedConfig = options.reduce(
			(accum, { status, value }, index) =>
				status === 'fulfilled'
					? {
							...accum,
							[profileOptions[index].field]: {
								original: value.result,
								options: transformObjectToOptionsArray(value.result)
							}
					  }
					: accum,
			{}
		)

		dispatch({
			type: types.GET_PROFILE_OPTIONS,
			payload: transformedConfig
		})
	} catch (e) {
		console.dir(e)
	}
}

export const getMeAction = () => async (dispatch, getState) => {
	try {
		const response = await getMe()
		const {
			app: { config }
		} = getState()

		const user = _get(response, 'result', {})
		user.age = moment().diff(moment(user?.birthday), 'years')
		//Prepare photo path
		const photos = [...(user.photos || [])]
		user.photos = [...preparePhotosSrc({ photos, config })]

		dispatch({ type: types.GET_ME, payload: user })

		const subscriptionStatus = _get(
			response,
			'result.subscription.status',
			false
		)
		const transformedSubscriptionStatus = transformSubscriptionStatus(
			subscriptionStatus
		)
		dispatch(setSubscriptionStatus(transformedSubscriptionStatus))

		// Check if the user has blank data send for filling
		const [missingStep, allMissingSteps] = determineMissingSteps(user)

		if (!allMissingSteps.length) {
			dispatch(setFinishedCreateProfile(true))
			await dispatch(getPreference())
		} else {
			dispatch(setCurrentStepCreateProfile(missingStep))
			dispatch(setFinishedCreateProfile(false))
		}
		return user
	} catch (e) {
		console.dir(e)
	}
}

export const getRejectReasonAction = () => async dispatch => {
	try {
		const response = await getRejectReason()
		const reason = _get(response, 'result.reason', null)
		dispatch({ type: GET_REJECT_REASON, payload: { rejectReason: reason } })
	} catch (e) {
		console.dir(e)
	}
}

export const setCurrentStepCreateProfile = step => ({
	type: types.SET_CURRENT_STEP_CREATE_PROFILE,
	payload: step
})

export const updateMedia = (id, photo) => (dispatch, getState) => {
	const {
		app: { config },
		profile: {
			user: { id: userId, photos = [] }
		}
	} = getState()

	const hashes = buildSrcWithHashAndUserId({
		config,
		userId,
		type: photo.type,
		hash: photo.hash
	})

	const newPhotos = photos.map(p => (p.id === id ? { ...photo, ...hashes } : p))

	dispatch({
		type: types.UPDATE_MEDIA,
		payload: newPhotos
	})
}

export const pushMedia = photo => (dispatch, getState) => {
	const {
		app: { config },
		profile: {
			user: { id: userId }
		}
	} = getState()

	const hashes = buildSrcWithHashAndUserId({
		config,
		userId,
		type: photo.type,
		hash: photo.hash
	})

	dispatch({
		type: types.PUSH_MEDIA,
		payload: { ...photo, ...hashes }
	})
}

export const setFinishedCreateProfile = (value = true) => ({
	type: types.SET_FINISHED_CREATE_PROFILE,
	payload: value
})

export const updateMe = (user = {}) => ({
	type: types.UPDATE_ME,
	payload: user
})

export const toggleShowTutorial = () => {
	return {
		type: types.TOGGLE_SHOW_TUTORIAL
	}
}

export const setSubscriptionStatus = status => ({
	type: SET_SUBSCRIPTION_STATUS,
	payload: status
})
