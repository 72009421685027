import React, { PureComponent } from 'react'
import Sentry from 'libs/sentry/sentry'
import { connect } from 'react-redux'
import classes from './ErrorBoundary.module.scss'
import Logo from 'components/ui/layout/Logo'
import Button from 'components/ui/Button'
import { ReactComponent as Cupid } from 'assets/icons/cupid.svg'
import { clearApplicationState } from 'modules/auth/store/actions'

const initialState = {
	error: null,
	eventId: null
}

class ErrorBoundary extends PureComponent {
	state = { ...initialState }

	static getDerivedStateFromError(error) {
		return { error }
	}

	// eslint-disable-next-line
	componentDidCatch(error, errorInfo) {
		Sentry.withScope(() => {
			const eventId = Sentry.captureException(error)
			this.setState({ eventId })
		})
	}

	showSentryDialog = () => {
		const { eventId } = this.state
		const { user } = this.props

		const sentryUser = {}

		if (user) {
			const { email, firstName, lastName } = user
			sentryUser.name = `${firstName} ${lastName}`
			sentryUser.email = email
		}
		Sentry.showReportDialog({ eventId, user: sentryUser })
	}
	//
	// clearCache = () => {
	// 	if (caches) {
	// 		// Service worker cache should be cleared with caches.delete()
	// 		caches.keys().then(names => {
	// 			for (const name of names) {
	// 				caches.delete(name)
	// 			}
	// 		})
	// 	}
	// 	// delete browser cache and hard reload
	// 	// window.location.reload(true)
	// }

	clearStorage = () => {
		// this.clearCache()
		this.props.clearApplicationState()
		window.location.reload(true)
	}

	render() {
		const { children } = this.props
		const { error } = this.state

		if (error) {
			return (
				<div className={classes.root}>
					<Logo withTitle classes={{ root: classes.logo }} />
					<div className={classes.content}>
						<Cupid className={classes.icon} />
						<p className={classes.text}>
							Sorry! Cupid's taking a short break. We'll be up and running
							shortly. Help us fix the problem sooner by submitting a report.
						</p>
					</div>
					<div className={classes.btnsContainer}>
						<Button
							className={classes.btn}
							shape="rounded"
							onClick={this.clearStorage}
						>
							Refresh
						</Button>
						<Button
							className={classes.btn}
							shape="rounded"
							onClick={this.showSentryDialog}
						>
							Report
						</Button>
					</div>
				</div>
			)
		}

		return children
	}
}

const mapSateToProps = ({ profile: { user } }) => ({ user })
const mapDispatchToProps = dispatch => {
	return {
		clearApplicationState: () => dispatch(clearApplicationState())
	}
}

export default connect(mapSateToProps, mapDispatchToProps)(ErrorBoundary)
