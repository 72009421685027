export const GET_PROFILE_OPTIONS = 'GET_PROFILE_OPTIONS'
export const GET_ME = 'GET_ME'
export const UPDATE_ME = 'UPDATE_ME'
export const SET_CURRENT_STEP_CREATE_PROFILE = 'SET_CURRENT_STEP_CREATE_PROFILE'
export const SET_FINISHED_CREATE_PROFILE = 'SET_FINISHED_CREATE_PROFILE'
export const TOGGLE_SHOW_TUTORIAL = 'TOGGLE_SHOW_TUTORIAL'
export const UPDATE_MEDIA = 'UPDATE_MEDIA'
export const PUSH_MEDIA = 'PUSH_MEDIA'
export const SET_SUBSCRIPTION_STATUS = 'SET_SUBSCRIPTION_STATUS'
export const GET_REJECT_REASON = 'GET_REJECT_REASON'

export const configFields = {
	horoscope: 'horoscope',
	genders: 'genders',
	wantGenders: 'wantGenders',
	languages: 'languages',
	educations: 'educations',
	jobs: 'jobs',
	growth: 'growth',
	religions: 'religions',
	personalities: 'personalities',
	questions: 'questions',
	haveKids: 'haveKids',
	familyPlans: 'familyPlans',
	smoke: 'smoke',
	drink: 'drink'
}
