import axios from 'axios'

// let cancelToken

class CancelApi {
	constructor() {
		this.source = axios.CancelToken.source()
	}
	getSource() {
		return this.source
	}
	setSource(value) {
		this.source = value
	}
	getToken() {
		return this.source.token
	}
	cancel() {
		this.source.cancel()
	}
}
//
// const getCancelToken = () => {
// 	if (cancelToken) {
// 		return cancelToken
// 	} else {
// 		return new CancelToken(axios.CancelToken.source())
// 	}
// }

// const cancelRequestInterface = getCancelToken()

export default CancelApi
