import * as Yup from 'yup'
import { _get } from '../utils/lodash'
import { _isEmpty } from 'utils/lodash'
import * as moment from 'moment'
import { parsePhoneNumber } from 'libphonenumber-js'
import * as Sentry from '@sentry/browser'

Yup.addMethod(Yup.mixed, 'phone', function () {
	return this.test('Phone test', 'Phone is required', function (meta) {
		const { path, createError } = this
		if (!_get(meta, 'number', false)) return false
		const { number, country } = meta
		if (/[a-zA-Z]/.test(number)) return createError({ path, message: 'This phone number mustn\'t contain letters' })
		if (number.length < 3) return false

		try {
			const phoneNumber = parsePhoneNumber(number, country)

			if (!phoneNumber.isValid()) {
				return createError({ path, message: 'This phone number is invalid' })
			}
		} catch (e) {
			Sentry.captureException(e)
		}

		return true
	})
})

Yup.addMethod(Yup.mixed, 'answers', function () {
	return this.test(
		'Answers test',
		'Please make a selection.',
		function (values) {
			const { path, createError } = this

			if (_isEmpty(values)) return false

			if (Object.keys(values).length < 2)
				return createError({ path, message: 'Answer at least 2' })

			if (Object.keys(values).length > 2)
				return createError({
					path,
					message: 'Answer 2 prompts only.'
				})

			const error = Object.entries(values).reduce(
				(accum, [, value]) =>
					!value && !accum
						? [...accum, `Selected questions must have an answers`]
						: accum,
				''
			)

			if (error) return createError({ path, message: error })

			return true
		}
	)
})

Yup.addMethod(Yup.mixed, 'age', function (requiredAge = 18) {
	return this.test('Age test', 'Age is required', function (birthday) {
		const { path, createError } = this

		if (!birthday) return false

		const age = moment().get('year') - moment(birthday).get('year')

		if (age < requiredAge)
			return createError({
				path,
				message: `Required age ${requiredAge} and more`
			})

		return true
	})
})

Yup.addMethod(Yup.mixed, 'photos', function (size) {
	return this.test('Photo test', 'Photos must be different', function (photos) {
		const { path, createError } = this

		const uniquePhotos = [
			...new Set([...photos.map(photo => photo?.name)])
		].filter(value => value)

		if (photos.length < size) {
			return createError({
				path,
				message: `3 photos required`
			})
		}

		return (
			uniquePhotos.length ===
			photos.filter(photo => photo instanceof Blob).length
		)
	})
})

export default Yup
