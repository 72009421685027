const breakpointsUp = {
	xxl: '1900px',
	xl: '1400px',
	lg: '1200px',
	md: '992px',
	sm: '769px',
	xs: '650px',
	xxs: '450px',
	xxxs: '375px'
}

const breakpointsDown = {
	xl: '1399px',
	lg: '1199px',
	md: '991px',
	sm: '767px',
	xs: '649px',
	xxs: '449px'
}

export { breakpointsDown, breakpointsUp }
