import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import classes from './Button.module.scss'

const colors = {
	primary: 'primary',
	secondary: 'secondary',
	grey: 'grey'
}

const variants = {
	contained: 'contained',
	outlined: 'outlined'
}

const Button = ({
	className,
	component: Component,
	children,
	shape,
	color,
	variant,
	inline,
	size,
	stretch,
	tabs,
	icon,
	shadow,
	area,
	iconPlacement,
	type,
	onClick,
	disabled,
	...restProps
}) => (
	<button
		type={type}
		onClick={onClick}
		style={{
			gridArea: area
		}}
		className={clsx(
			classes.basicButton,
			className,
			classes[color],
			classes[variant],
			classes[size],
			classes[shape],
			'user-select-none',
			{
				[classes.inline]: inline,
				[classes.tabs]: tabs,
				[classes.stretch]: stretch,
				[classes.shadow]: shadow,
				[classes.iconBtn]: !children && icon,
				[classes.disabled]: disabled
				// 'interactive-btn': onClick
			}
		)}
		{...restProps}
	>
		<div className={clsx(classes.container, classes[iconPlacement])}>
			{icon}
			{children}
		</div>
	</button>
)

Button.propTypes = {
	color: PropTypes.oneOf([
		'primary',
		'secondary',
		'grey',
		'white',
		'orange',
		'transparent'
	]),
	variant: PropTypes.oneOf(['contained', 'outlined']),
	shape: PropTypes.oneOf(['default', 'rounded']),
	component: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.object,
		PropTypes.func
	]),
	size: PropTypes.oneOf(['custom', 'xs', 'sm', 'md', 'lg', 'xl']),
	iconPlacement: PropTypes.oneOf(['start', 'end']),
	children: PropTypes.node,
	className: PropTypes.string,
	type: PropTypes.string,
	area: PropTypes.string,
	onClick: PropTypes.func,
	inline: PropTypes.bool,
	stretch: PropTypes.bool,
	tabs: PropTypes.bool,
	shadow: PropTypes.bool
}

Button.defaultProps = {
	className: '',
	type: '',
	area: '',
	component: 'button',
	shape: 'default',
	size: 'lg',
	iconPlacement: 'start',
	color: colors.primary,
	variant: variants.contained,
	onClick: () => null,
	inline: false,
	stretch: false,
	tabs: false,
	shadow: false
}

export default Button
