import mobileSlide1 from 'assets/images/slides/1mobile.webp'
import mobileSlide2 from 'assets/images/slides/2mobile.webp'
import mobileSlide3 from 'assets/images/slides/3mobile.webp'
import mobileSlide4 from 'assets/images/slides/4mobile.webp'
import slide1 from 'assets/images/slides/1.webp'
import slide3 from 'assets/images/slides/3.webp'
export const joinWithFacebook = 'Join with Facebook'
export const joinWithPhone = 'Sign up with phone number'
export const successStories = 'Success Stories'

export const slides = [slide1, mobileSlide2, slide3, mobileSlide4]

export const slidesForMobile = [
	mobileSlide1,
	mobileSlide2,
	mobileSlide3,
	mobileSlide4
]

export const HomepageSlides = [
	{
		name: 'Shana & Ramesh',
		image: 'assets/images/slider-images/couple1.jpeg'
	},
	// {
	// 	name: 'Suji & Sinthu',
	// 	image: 'assets/images/slider-images/couple2.jpeg'
	// },
	{
		name: 'Abi & John',
		image: 'assets/images/slider-images/couple5-abi-john.jpg'
	},
	{
		name: 'Jenani & Nav',
		image: 'assets/images/slider-images/couple3.jpeg'
	},
	{
		name: 'Madhu & Niya',
		image: 'assets/images/slider-images/couple4.jpeg'
	}
]

export const titles = [
	'Meet Tamil Singles',
	'Like & Match',
	'Connect & Chat',
	'Meet & Date'
]

export const btnSize = {
	desktop: {
		size: 'lg'
	},
	mobile: {
		size: 'md'
	}
}
export const btnNames = {
	joinWithFacebook,
	joinWithPhone,
	successStories
}
