import React, { memo } from 'react'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import MobileTextBanner from 'components/ui/UpgradeBanners/MobileTextBanner/MobileTextBanner'

const TextBannerDialog = ({ buttons, open, onClose }) => {
	return (
		<BaseDialog open={open} onClose={onClose}>
			<MobileTextBanner />
		</BaseDialog>
	)
}

export default memo(TextBannerDialog)
