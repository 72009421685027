import { useCallback, useState } from 'react'

const useFlagManager = (initialState = false) => {
	const [state, setState] = useState(initialState)

	const turnIn = useCallback(() => setState(true), [])
	const turnOff = useCallback(() => setState(false), [])
	const toggle = useCallback(() => setState(state => !state), [])
	const set = useCallback(value => setState(value), [])

	return { state, turnIn, turnOff, toggle, set }
}

export default useFlagManager
