import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { routesByName } from 'constants/routes'

const GuestSwitch = ({ children }) => {
	const { path } = useRouteMatch()

	return (
		<Switch>
			<Route exact path={path}>
				<Redirect to={routesByName.home} />
			</Route>
			{children}
			<Route path="*">
				<Redirect to="/" />
			</Route>
		</Switch>
	)
}

export default GuestSwitch
