import React, { forwardRef, useMemo } from 'react'
import * as PropTypes from 'prop-types'

const sizeConfig = {
	xxl: 128,
	xl: 64,
	lg: 32,
	md: 24,
	sm: 16
}

const SpriteIcon = forwardRef(({ name, size, hide, ...props }, ref) => {
	const widthHeight = useMemo(() => {
		const number = sizeConfig[size]

		if (number) {
			return {
				width: number,
				height: number
			}
		}

		return {}
	}, [size])

	return !hide ? (
		<svg ref={ref} {...widthHeight} {...props}>
			<use xlinkHref={`#${name}`} />
		</svg>
	) : null
})

SpriteIcon.propTypes = {
	name: PropTypes.string.isRequired,
	size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xxl'])
}

SpriteIcon.defaultProps = {
	size: 'sm'
}

export default SpriteIcon
