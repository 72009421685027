import React, { useEffect, useCallback, useMemo, useRef, useState } from 'react'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import SpriteIcon from 'components/ui/SpriteIcon'
import './overrideClasses.scss'
import classes from './SlickCarousel.module.scss'
import Dot from 'components/wrappers/ui/BaseHomeSlickCarousel/Dot'

const BaseHomeSlickCarousel = ({
	children,
	onChangeSlide,
	area,
	dots,
	classes: outerClasses = {},
	arrows = false,
	isLazyLoad = false,
	...props
}) => {
	const ref = useRef()
	const [currentSlide, setCurrentSlide] = useState(0)
	const [isMobileFormat, setisMobileFormat] = useState(false)

	useEffect(() => {
		setisMobileFormat(props?.is_mobile)
	})

	const settings = useMemo(
		() => ({
			speed: 500,
			infinite: true,

			className: "center",
			centerMode: true,
			centerPadding: "30px",

			slidesToShow: 4,
			slidesToScroll: 1,
			initialSlide: 0,

			responsive: [
				{
					breakpoint: 1280,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1,
						centerPadding: '30px'
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 4,
						slidesToScroll: 1,
						centerPadding: '20px'
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						centerPadding: '20px'
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
						centerPadding: '25px'
					}
				}
			],
			beforeChange: (prev, next, ...rest) => {
				setCurrentSlide(next)
				onChangeSlide(next)
			},
			...props
		}),
		[onChangeSlide, props]
	)

	const handleClickDot = useCallback(slide => {
		ref.current.slickGoTo(slide)
	}, [])

	const nextPage = useCallback(slide => {
		ref.current.slickNext()
	}, [])

	const prevPage = useCallback(slide => {
		ref.current.slickPrev()
	}, [])

	const renderPrevArrow = useMemo(() => {
		const className = clsx(classes.arrow, classes.prev, outerClasses.prevArrow)

		return currentSlide !== 0 ? (
			<SpriteIcon
				onClick={prevPage}
				className={className}
				name="arrow-next"
				color="white"
				size="xl"
			/>
		) : (
			<div className={className} />
		)
	}, [currentSlide, outerClasses.prevArrow, prevPage])

	const renderNextArrow = useMemo(() => {
		const className = clsx(classes.arrow, classes.next, outerClasses.nextArrow)

		return currentSlide !== children.length - 1 ? (
			<SpriteIcon
				onClick={nextPage}
				className={className}
				name="arrow-next"
				color="white"
				size="xl"
			/>
		) : (
			<div className={className} />
		)
	}, [children.length, currentSlide, nextPage, outerClasses.nextArrow])

	return (
		<div
			className={clsx(classes.root, outerClasses.root)}
			style={{ gridArea: area }}
		>
			<div
				className={clsx(
					classes.carouselContainer,
					outerClasses.carouselContainer
				)}
			>
				{arrows && renderPrevArrow}
				<Slider {...settings} ref={ref}>
					{children}
				</Slider>
				{arrows && renderNextArrow}
			</div>

			{dots && (
				<div
					className={clsx(classes.dotsContainer, outerClasses.dotsContainer)}
				>
					{children.map((item, index) => (
						<Dot
							key={'dots-' + index}
							slide={index}
							active={index === currentSlide}
							onClick={handleClickDot}
							className={clsx(classes.dot, outerClasses.dot)}
							activeClass={outerClasses.activeDot}
						/>
					))}
				</div>
			)}
		</div>
	)
}

BaseHomeSlickCarousel.propTypes = {
	onChangeSlide: PropTypes.func,
	classes: PropTypes.object,
	dots: PropTypes.bool,
	area: PropTypes.string,
	width: PropTypes.string,
	isLazyLoad: PropTypes.bool
}

BaseHomeSlickCarousel.defaultProps = {
	onChangeSlide: () => null,
	dots: true,
	area: '',
	className: '',
	isLazyLoad: false
}

export default BaseHomeSlickCarousel
