import React from 'react'
import BaseFormikWrapper from 'components/wrappers/formik/index'
import PropTypes from 'prop-types'
import NativeSelect from 'components/ui/controls/NativeSelect'

const FormikFieldNativeSelect = props => (
	<BaseFormikWrapper component={NativeSelect} {...props} />
)

FormikFieldNativeSelect.propTypes = {
	name: PropTypes.string.isRequired
}

export default FormikFieldNativeSelect
