import React, { memo } from 'react'
import classes from './DesktopCarouselBanner.module.scss'
import BaseSlickCarousel from 'components/wrappers/ui/BaseSlickCarousel'
import Typography from 'components/ui/Typography'
import Button from 'components/ui/Button'
import { routesByName } from 'constants/routes'
import useHistory from 'hooks/useHistory'
import clsx from 'clsx'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import { screenFormat } from 'constants/screenFormat'
import {
	slides,
	textSlides
} from 'components/ui/UpgradeBanners/DesktopCarouselBanner/config'

const DesktopCarouselBanner = ({
	classes: outerClasses = {},
	withImg = false
}) => {
	const history = useHistory()
	const isMobileFormat =
		useDetermineScreenFormat(breakpointsUp.sm) === screenFormat.mobile
	const currentSlides = isMobileFormat ? slides('mobile') : slides('desktop')

	return (
		<div className={clsx(classes.wrapper, outerClasses.wrapper)}>
			<div className={clsx(classes.root, outerClasses.root)}>
				{withImg ? (
					<BaseSlickCarousel
						arrows={false}
						classes={{
							root: clsx(classes.carouselRoot, outerClasses.carouselRoot),
							dot: classes.dot,
							activeDot: classes.activeDot,
							dotsContainer: clsx(
								classes.dotsContainer,
								outerClasses.dotsContainer
							)
						}}
					>
						{currentSlides.map(({ img, text }, index) => (
							<div
								className={clsx(classes.slide, outerClasses.slide)}
								key={index}
							>
								{withImg ? (
									<>
										<img src={img} alt={''} className={classes.img} />
										<div
											className={clsx(
												classes.slideDescription,
												outerClasses.slideDescription
											)}
										>
											<div className={classes.title}>
												{isMobileFormat ? (
													'Premium Membership Benefits'
												) : (
													<>
														Premium
														<br />
														Membership
														<br />
														Benefits
													</>
												)}
												<br />
											</div>
											<ul className={classes.list}>
												{text.map(subtitle => (
													<li className={classes.subTitle} key={subtitle}>
														{subtitle}
													</li>
												))}
											</ul>
										</div>
										<div className={classes.gradient} />
									</>
								) : (
									<>
										<Typography className={classes.slideTitle}>
											Why Upgrade?
										</Typography>
										<div>
											{text.map(subtitle => (
												<p className={classes.slideSubtitle} key={subtitle}>
													{subtitle}
												</p>
											))}
										</div>
									</>
								)}
							</div>
						))}
					</BaseSlickCarousel>
				) : (
					<BaseSlickCarousel
						arrows={false}
						classes={{
							root: clsx(classes.carouselRoot, outerClasses.carouselRoot),
							dot: classes.dot,
							activeDot: classes.activeDot,
							dotsContainer: clsx(
								classes.dotsContainer,
								outerClasses.dotsContainer
							)
						}}
					>
						{textSlides.map((text, index) => (
							<div key={text + index}>
								<div className={classes.slideTitle}>Why Upgrade?</div>
								<div>
									<p className={classes.slideSubtitle} key={text}>
										{text}
									</p>
								</div>
							</div>
						))}
					</BaseSlickCarousel>
				)}

				{!withImg && (
					<Button
						shape="rounded"
						color="white"
						className={classes.btn}
						size="sm"
						onClick={() => history.push(routesByName.upgrade.root)}
					>
						Upgrade now
					</Button>
				)}
			</div>
		</div>
	)
}

export default memo(DesktopCarouselBanner)
