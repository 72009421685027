import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classes from './BaseChip.module.scss'
import SpriteIcon from 'components/ui/SpriteIcon'
import clsx from 'clsx'

const BaseChip = ({ title, id, className, onClose }) => {
	const handleClose = useCallback(() => {
		onClose(id)
	}, [onClose, id])

	return (
		<div className={clsx(classes.root, className)}>
			<div
				role="presentation"
				className={classes.iconContainer}
				onClick={handleClose}
			>
				<SpriteIcon name="close-rounded" className={classes.icon} />
			</div>
			{title}
		</div>
	)
}

BaseChip.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	onClose: PropTypes.func
}

BaseChip.defaultProps = {
	title: '',
	value: '',
	onClose: () => null
}

export default memo(BaseChip)
