import extendAxiosInstance from 'libs/axios/extendAxiosInstance'
import BaseAxiosInstance from 'libs/axios/BaseAxiosInstance'
import { getAccessToken } from 'utils/authorize'
import { clearApplicationState } from 'modules/auth/store/actions'
import axios from 'axios'
import { cancelApi } from 'index.js'

const AuthorizedAxiosInstance = extendAxiosInstance(BaseAxiosInstance, {})

export const setUpAuthInterceptorsAction = () => dispatch => {
	AuthorizedAxiosInstance.interceptors.request.use(
		async config => {
			const headers = { ...config.headers }
			const authToken = getAccessToken()

			if (!headers.Authorization && authToken) {
				headers.Authorization = authToken
			}

			if (!headers.Authorization) {
				delete headers.Authorization
			}
			// console.log(`cancelAxiosSource.token`, cancelRequestInterface.getToken())
			return {
				...config,
				cancelToken: cancelApi.getToken(),
				headers
			}
		},
		error => Promise.reject(error)
	)

	AuthorizedAxiosInstance.interceptors.response.use(
		response => response,
		async error => {
			if (error.response && error.response.status === 401) {
				await dispatch(clearApplicationState())
			} else if (axios.isCancel(error)) {
				throw error
			} else {
				throw error
			}
		}
	)
}

export default AuthorizedAxiosInstance
