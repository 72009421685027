import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const Typography = ({
	variant,
	children,
	className,
	innerRef,
	area,
	...props
}) => (
	<span
		className={clsx(className, variant)}
		style={{
			gridArea: area
		}}
		ref={innerRef}
		{...props}
	>
		{children}
	</span>
)

Typography.propTypes = {
	className: PropTypes.string,
	area: PropTypes.string,
	variant: PropTypes.oneOf([
		'body-1',
		'body-2',
		'body-3',
		'body-4',
		'body-5',
		'body-6'
	])
}

Typography.defaultProps = {
	variant: 'body-1',
	className: '',
	area: ''
}

export default Typography
