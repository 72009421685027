import React, { useCallback, useMemo, memo } from 'react'
import { ReactComponent as LightLogo } from 'assets/staticIcons/light-logo.svg'
import { ReactComponent as DarkLogo } from 'assets/staticIcons/dark-logo.svg'
import PropTypes from 'prop-types'
import useHistory from 'hooks/useHistory'
import { routesByName } from 'constants/routes'
import classes from './Logo.module.scss'
import clsx from 'clsx'

const variants = {
	light: LightLogo,
	dark: DarkLogo
}

const Logo = ({
	width,
	height,
	classes: outerClasses = {},
	area,
	variant,
	withTitle,
	...props
}) => {
	const history = useHistory()

	const handleClick = useCallback(() => {
		history.push(routesByName.root)
	}, [history])

	const SelectedLogo = useMemo(() => variants[variant], [variant])

	return (
		<div
			className={clsx(classes.root, outerClasses.root)}
			onClick={handleClick}
			role="presentation"
		>
			<SelectedLogo
				width={width}
				height={height}
				className={outerClasses.icon}
				style={{ gridArea: area, cursor: 'pointer' }}
				{...props}
			/>
			{withTitle ? (
				<span
					className={clsx('ml-1 mr-4', classes[variant], outerClasses.label)}
				>
					{' '}
					myTamilDate{' '}
				</span>
			) : null}
		</div>
	)
}

Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	classes: PropTypes.object,
	withTitle: PropTypes.bool,
	area: PropTypes.string,
	variant: PropTypes.oneOf(['dark', 'light']).isRequired
}

Logo.defaultProps = {
	width: 45,
	height: 45,
	classes: {},
	withTitle: false,
	area: '',
	variant: 'light'
}

export default memo(Logo)
