import BaseAxiosInstance from 'libs/axios/BaseAxiosInstance'
import appConfig from 'constants/appConfig.js'
import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance.js'

export const confirmationCreate = data =>
	BaseAxiosInstance.post(appConfig.apiPrefix + '/confirmation/create', data)

export const confirmationResend = data =>
	BaseAxiosInstance.post(appConfig.apiPrefix + '/confirmation/resend', data)

export const signIn = data =>
	BaseAxiosInstance.post(appConfig.apiPrefix + '/auth/login', data)

export const saveUserFCMToken = token =>
	AuthorizedAxiosInstance.post(appConfig.apiPrefix + '/user/firebase-token', {
		token
	})
export const facebookAuth = token =>
	AuthorizedAxiosInstance.post(appConfig.apiPrefix + '/auth/facebook', {
		fb_token: token
	})
