import firebase from 'firebase/app'
import 'firebase/messaging'

import config from 'constants/appConfig'
import { saveUserFCMToken } from 'modules/auth/signIn/services.js'
import successToastr from 'libs/toastr/successToastr.js'

let messagingInstance
const fcmTokenKey = 'fcmTokenKey'

class Firebase {
	onTokenUpdate

	constructor() {
		if (
			!firebase.apps.length &&
			'serviceWorker' in navigator &&
			'PushManager' in window &&
			firebase.messaging.isSupported()
		) {
			firebase.initializeApp(config.fcmConfig)
			messagingInstance = firebase.messaging()
			this.checkPermission()

			messagingInstance.onTokenRefresh(this.onTokenRefresh)

			window.addEventListener('load', this.registerBGNotifications)

			// messagingInstance.onMessage(this.onMessage)
		}
	}

	setOnTokenUpdate(callback) {
		this.onTokenUpdate = callback
	}

	onMessage(payload) {
		successToastr(payload.notification.body, payload.notification.title)
	}

	registerBGNotifications(payload) {
		navigator.serviceWorker
			.register('../firebase-messaging-sw.js')
			.then(
				registration =>
					console.log(
						'ServiceWorker registration successful with scope: ',
						registration.scope
					),
				err => console.log('ServiceWorker registration failed: ', err)
			)
			.catch(err => console.log(err))
	}

	async checkPermission() {
		messagingInstance
			.requestPermission()
			.then(() => {
				return messagingInstance.getToken()
			})
			.then(saveUserFCMToken)
			.catch(() => console.log(`Error occurred`))
	}

	async getToken() {
		let fcmToken = localStorage.getItem(fcmTokenKey)

		if (!messagingInstance) {
			return
		}

		try {
			if (!fcmToken) {
				fcmToken = await messagingInstance.getToken()
				if (fcmToken) {
					this.setToken(fcmToken)
				}
			}

			return fcmToken
		} catch (error) {
			console.dir(error)
		}
	}

	setToken(token) {
		localStorage.setItem(fcmTokenKey, token)
	}

	async onTokenRefresh() {
		try {
			const token = await this.getToken()

			await saveUserFCMToken(token)

			if (token && this.onTokenUpdate) {
				this.onTokenUpdate(token)
			}
		} catch (error) {
			console.dir(error)
		}
	}

	clear() {
		localStorage.removeItem(fcmTokenKey)
	}
}

let FirebaseInstance

const getFirebaseInstance = async () => {
	if (!FirebaseInstance) {
		FirebaseInstance = new Firebase()
	}
	return FirebaseInstance
}

export default getFirebaseInstance
