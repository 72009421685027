export const appBg = '#F0F0F0'
export const primaryColor = '#FC8C66'
export const secondaryColor = '#C494EB'
export const primaryGradientColor =
	'linear-gradient(90deg, #FC8C66 0%, #FFCCBA 162.36%)'
export const primaryGradientColor2 =
	'linear-gradient(44.84deg, #FC8C66 29.56%, rgba(237, 78, 23, 0.19) 126.14%)'
export const primaryHomepageGradientColor =
	'linear-gradient(101.33deg, #E2A59E 4.54%, #ECC4AA 77.66%, #F6E5B7 101.39%)'
export const errorColor = '#FF0000'
export const black1 = '#000000'
export const black2 = '#272727'
export const black3 = '#3E3E3E'
export const gray1 = '#C4C4C4'
export const gray2 = '#A1A1A1'
export const gray3 = '#E9E9E9'
export const gray4 = '#E5E5E5'
export const commonWhite = '#ffffff'
export const blue = '#2560B8'
