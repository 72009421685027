import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance'
import appConfig from 'constants/appConfig'

export const getReceivedLikes = params =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + `/discovery/likes`, {
		params
	})

export const getSentLikes = params =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + `/user/likes`, {
		params
	})

export const rejectApplicantFromLikes = userId =>
	AuthorizedAxiosInstance.post(
		appConfig.apiPrefix + `/discovery/matches/skip/${userId}`,
		null,
		{ params: { from_likes: true } }
	)
