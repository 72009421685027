import React, { memo, useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classes from 'components/ui/controls/BaseSelectImages/BaseSelectImages.module.scss'
import ImageUploadCard from 'components/ui/controls/BaseSelectImages/ImageUploadCard'
import BaseCropper from 'components/ui/controls/BaseCropper'
import clsx from 'clsx'
import imageCompression from 'browser-image-compression'
import { toggleLoader } from 'modules/app/store/actions'
import { useDispatch } from 'react-redux'
import BaseTooltip from 'components/wrappers/ui/BaseTooltip'

const initialCropper = {
	open: false,
	index: 0
}

const BaseSelectImages = ({
	value: values,
	onChange,
	variant,
	count,
	name,
	className,
	hasEditIcon,
	tooltip
}) => {
	const [cropper, setCropper] = useState(initialCropper)
	const [preloadImage, setPreloadImage] = useState(null)
	const [fileName, satFileName] = useState()
	const dispatch = useDispatch()

	const handleAddImage = useCallback(
		async ({ target: { value: file, name: currentIndex } }) => {
			try {
				const index = values[currentIndex] ? currentIndex : values.length

				if (file.hasOwnProperty('name')) {
					satFileName(file.name)
				}
				await setPreloadImage(file)
				setCropper({ open: true, index })
			} catch (e) {
				console.dir(e)
			}
		},
		[values]
	)

	const handleSaveCropper = useCallback(
		async (name, image) => {
			if (!image)
				image = {}
			image.name = fileName

			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 1920,
				useWebWorker: true
			}

			try {
				dispatch(toggleLoader())
				const compressedFile = await imageCompression(image, options)

				const event = {
					target: {
						name,
						value: compressedFile,
						index: cropper.index
					}
				}

				onChange(event)
				setCropper(initialCropper)
			} catch (e) {
				console.dir(e)
			} finally {
				dispatch(toggleLoader())
			}
		},
		[cropper.index, dispatch, fileName, onChange]
	)

	const renderImage = useMemo(
		() =>
			Array.from({ length: count }, (v, i) => (
				<ImageUploadCard
					key={`photo-card-${i}`}
					image={values[i]}
					name={i}
					variant={variant}
					onAddImage={handleAddImage}
					hasEditIcon={hasEditIcon}
				/>
			)),
		[count, handleAddImage, hasEditIcon, values, variant]
	)

	const handleCloseCropper = useCallback(index => {
		setPreloadImage(null)
		setCropper(initialCropper)
	}, [])

	return (
		<div
			className={clsx(classes.root, classes[variant], className)}
			style={{ gridTemplateColumns: `repeat(${count}, minmax(20px, 250px))` }}
		>
			{renderImage}
			{tooltip && (
				<BaseTooltip
					tooltip={tooltip}
					classes={{ tooltipIcon: classes.tooltipIcon }}
					isBold
				/>
			)}
			<BaseCropper
				image={preloadImage}
				name={`${name}[${cropper.index}]`}
				index={cropper.index}
				open={cropper.open}
				onClose={handleCloseCropper}
				onSave={handleSaveCropper}
			/>
		</div>
	)
}

BaseSelectImages.propTypes = {
	className: PropTypes.string,
	value: PropTypes.array,
	count: PropTypes.number,
	variant: PropTypes.oneOf(['primary', 'secondary']),
	onSaveCropper: PropTypes.func,
	onChange: PropTypes.func
}

BaseSelectImages.defaultProps = {
	className: '',
	variant: 'primary',
	value: [],
	onChange: () => null,
	onSaveCropper: () => null,
	count: 3
}

export default memo(BaseSelectImages)
