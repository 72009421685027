import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance'
import { configFields } from 'modules/profile/store/constants'
import appConfig from 'constants/appConfig.js'

export const getMe = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/user')

export const getRejectReason = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/user/reject-reason')

export const getTrialEnd = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/user/trial-ends')

export const postPopupShown = data =>
	AuthorizedAxiosInstance.post(appConfig.apiPrefix + '/user/trial-ends', data)

export const postMedia = data =>
	AuthorizedAxiosInstance.post(appConfig.apiPrefix + '/media/upload', data)

export const deleteMedia = id =>
	AuthorizedAxiosInstance.delete(appConfig.apiPrefix + `/media/delete/${id}`)

export const putName = data =>
	AuthorizedAxiosInstance.put(appConfig.apiPrefix + '/user/profile/name', data)

export const putBirthday = data =>
	AuthorizedAxiosInstance.put(
		appConfig.apiPrefix + '/user/profile/birthday',
		data
	)

export const putGender = data =>
	AuthorizedAxiosInstance.put(
		appConfig.apiPrefix + '/user/profile/gender',
		data
	)

export const putWantGender = data =>
	AuthorizedAxiosInstance.put(
		appConfig.apiPrefix + '/user/profile/want-gender',
		data
	)

export const putEmail = data =>
	AuthorizedAxiosInstance.put(appConfig.apiPrefix + '/user/profile/email', data)

export const putLanguages = data =>
	AuthorizedAxiosInstance.put(
		appConfig.apiPrefix + '/user/profile/languages',
		data
	)

export const putStudy = data =>
	AuthorizedAxiosInstance.put(appConfig.apiPrefix + '/user/profile/study', data)

export const putJob = data =>
	AuthorizedAxiosInstance.put(appConfig.apiPrefix + '/user/profile/job', data)

export const putGrowth = data =>
	AuthorizedAxiosInstance.put(
		appConfig.apiPrefix + '/user/profile/growth',
		data
	)

export const putReligion = data =>
	AuthorizedAxiosInstance.put(
		appConfig.apiPrefix + '/user/profile/religion',
		data
	)

export const putPersonalities = data =>
	AuthorizedAxiosInstance.put(
		appConfig.apiPrefix + '/user/profile/personality',
		data
	)

export const putAnswers = data =>
	AuthorizedAxiosInstance.put(
		appConfig.apiPrefix + '/user/profile/answers',
		data
	)

export const putDrink = data =>
	AuthorizedAxiosInstance.put(appConfig.apiPrefix + '/user/profile/drink', data)

export const putSmoke = data =>
	AuthorizedAxiosInstance.put(appConfig.apiPrefix + '/user/profile/smoke', data)

export const putKid = data =>
	AuthorizedAxiosInstance.put(appConfig.apiPrefix + '/user/profile/kid', data)

export const getGenderOptions = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/config/genders')

export const getWantGenderOptions = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/config/want-genders')

export const getLanguageOptions = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/config/languages')

export const getEducationOptions = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/config/education')

export const getJobOptions = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/config/job')

export const getGrowthOptions = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/config/growth')

export const getReligionOptions = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/config/religions')

export const getPersonalityOptions = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/config/personalities')

export const getQuestionOptions = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/config/questions')

export const getDrinkOptions = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/config/drink')

export const getSmokeOptions = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/config/smoke')

export const getHaveKidOptions = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/config/have-kids')

export const getWantKidOptions = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/config/want-kids')

export const getLocation = ({ input, type = 0 }) =>
	AuthorizedAxiosInstance.get(
		appConfig.apiPrefix + `/user/location?input=${input}&search_type=${type}`
	)

export const getHoroscopeOptions = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/config/horoscope')

export const postLocation = data =>
	AuthorizedAxiosInstance.post(appConfig.apiPrefix + '/user/location', data)

export const postMedias = (medias = [], type) => {
	return Promise.all(
		medias?.map(media => {
			const formData = new FormData()

			formData.append('file', media, media.name)
			formData.set('type', type)

			return postMedia(formData)
		})
	)
}

export const pauseAccount = status =>
	AuthorizedAxiosInstance.post(appConfig.apiPrefix + '/user/status', { status })

export const deleteAccount = (data) =>
	AuthorizedAxiosInstance.post(appConfig.apiPrefix + '/user/delete-reason', data)

export const deleteAccountReason = data => {
	AuthorizedAxiosInstance.post(
		appConfig.apiPrefix + '/user/delete-reason',
		data
	)
}

export const userActivity = action =>
	AuthorizedAxiosInstance.post(appConfig.apiPrefix + '/user/activity', {
		event: action
	})

export const profileOptions = [
	{ getter: getGenderOptions, field: configFields.genders },
	{ getter: getWantGenderOptions, field: configFields.wantGenders },
	{ getter: getLanguageOptions, field: configFields.languages },
	{ getter: getEducationOptions, field: configFields.educations },
	{ getter: getJobOptions, field: configFields.jobs },
	{ getter: getGrowthOptions, field: configFields.growth },
	{ getter: getReligionOptions, field: configFields.religions },
	{ getter: getPersonalityOptions, field: configFields.personalities },
	{ getter: getQuestionOptions, field: configFields.questions },
	{ getter: getHaveKidOptions, field: configFields.haveKids },
	{ getter: getWantKidOptions, field: configFields.familyPlans },
	{ getter: getSmokeOptions, field: configFields.smoke },
	{ getter: getDrinkOptions, field: configFields.drink },
	{ getter: getHoroscopeOptions, field: configFields.horoscope }
]
