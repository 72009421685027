export const GET_CONVERSATIONS = 'GET_CONVERSATIONS'
export const GET_MESSAGES_BY_CONVERSATION_BY_ID =
	'GET_MESSAGES_BY_CONVERSATION_BY_ID'
export const SEND_MESSAGE = 'SEND_MESSAGE'
export const GET_NEXT_PAGE_MESSAGES = 'GET_NEXT_PAGE_MESSAGES'
export const SET_FLAG_HAS_NEXT_PAGE = 'SET_FLAG_HAS_NEXT_PAGE'
export const PUSH_RECEIVED_MESSAGE = 'PUSH_RECEIVED_MESSAGE'
export const READ_CONVERSATION = 'READ_CONVERSATION'
export const GET_CONVERSATION_BY_ID = 'GET_CONVERSATION_BY_ID'
export const SET_CURRENT_CONVERSATION = 'SET_CURRENT_CONVERSATION'
export const CREATE_CONVERSATION = 'CREATE_CONVERSATION'
export const SET_USER_FOR_CREATE_CONVERSATION =
	'SET_USER_FOR_CREATE_CONVERSATION'
export const CLEAR_MESSENGER_META = 'CLEAR_MESSENGER_META'
export const SET_CONVERSATION = 'SET_CONVERSATION'
export const PUSH_CONVERSATIONS = 'PUSH_CONVERSATIONS'
export const REMOVE_CONVERSATION_BY_PARTICIPANT_ID =
	'REMOVE_CONVERSATION_BY_PARTICIPANT_ID'
export const SET_IS_LAST_MESSAGE_READ = 'SET_IS_LAST_MESSAGE_READ'
