import React, { useCallback } from 'react'
import classes from './MobileCarouselBanner.module.scss'
import BaseSlickCarousel from 'components/wrappers/ui/BaseSlickCarousel'
import { slides } from 'pages/Upgrade/config'
import Typography from 'components/ui/Typography'

const carouselClasses = {
	root: classes.carousel,
	activeDot: classes.activeDot,
	dot: classes.dot
}

const MobileCarouselBanner = () => {
	const handleChangeSlide = useCallback(currentSlide => {}, [])

	return (
		<BaseSlickCarousel
			classes={carouselClasses}
			onChangeSlide={handleChangeSlide}
			arrows={false}
		>
			{slides.map((slideTitle, index) => (
				<div className={classes.carouselItem} key={index}>
					<Typography className={classes.slideTitle}>
						Premium Membership Benefits
					</Typography>
					<Typography className={classes.slideSubtitle}>
						{slideTitle}
					</Typography>
				</div>
			))}
		</BaseSlickCarousel>
	)
}

export default MobileCarouselBanner
