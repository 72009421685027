import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import classes from 'components/ui/controls/BaseInput/BaseInput.module.scss'
import clsx from 'clsx'
import BaseTooltip from 'components/wrappers/ui/BaseTooltip'
import SpriteIcon from 'components/ui/SpriteIcon'

const BaseInput = props => {
	const {
		inputClass,
		rootClass,
		error,
		touched,
		onChange,
		onBlur,
		onFocus,
		value,
		name,
		placeholder,
		disabled,
		readOnly,
		type,
		tooltip,
		innerRef,
		onKeyPress,
		onClick,
		iconClass
	} = props

	const hasError = useMemo(() => error && touched, [error, touched])
	const isVerified = useMemo(() => !error && touched && value, [
		error,
		touched,
		value
	])

	const renderTooltip = useMemo(() => tooltip && !hasError && !value, [
		hasError,
		tooltip,
		value
	])

	return (
		<div
			role="presentation"
			className={clsx(rootClass, classes.root)}
			onClick={onClick}
		>
			<input
				ref={innerRef}
				className={clsx(inputClass, classes.input, {
					[classes.error]: hasError,
					[classes.disabled]: disabled,
					[classes.verified]: isVerified
				})}
				onChange={onChange}
				onBlur={onBlur}
				onFocus={onFocus}
				value={value}
				name={name}
				placeholder={placeholder}
				readOnly={readOnly || disabled}
				type={type}
				onKeyPress={onKeyPress}
			/>
			{renderTooltip && <BaseTooltip tooltip={tooltip} />}
			{hasError && (
				<SpriteIcon
					name="error"
					className={clsx(classes.errorIcon, iconClass)}
				/>
			)}
			{isVerified && (
				<SpriteIcon
					name="done"
					className={clsx(classes.successIcon, iconClass)}
				/>
			)}
		</div>
	)
}

BaseInput.propTypes = {
	value: PropTypes.string,
	inputClass: PropTypes.string,
	rootClass: PropTypes.string,
	error: PropTypes.string,
	touched: PropTypes.bool,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	placeholder: PropTypes.string,
	tooltip: PropTypes.exact({
		title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
		text: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
	})
}

BaseInput.defaultProps = {
	className: '',
	value: '',
	readOnly: false,
	disabled: false
}

export default memo(BaseInput)
