import {
	RESEND_VERIFY_CODE,
	SEND_VERIFY_CODE,
	SIGN_UP
} from 'modules/auth/signUp/store/constants'
import update from 'immutability-helper'

const initialState = {
	identity: '',
	expiresIn: ''
}

const signUp = (state = { ...initialState }, { type, payload }) => {
	switch (type) {
		case SEND_VERIFY_CODE: {
			return update(state, {
				$set: payload
			})
		}
		case RESEND_VERIFY_CODE: {
			return update(state, {
				$merge: payload
			})
		}
		case SIGN_UP: {
			return update(state, {
				$set: initialState
			})
		}
		default:
			return state
	}
}

export default signUp
