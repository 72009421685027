import {
	ageFrom,
	ageTo,
	photos,
	stepByField
} from 'modules/profile/createProfile/config'
import { _isEmpty, _isObject } from 'utils/lodash'

export const determineMissingSteps = obj => {
	const nullableValuesByKey = Object.entries(obj).reduce(
		(accum, [key, value]) => {
			if (key === photos && value.length < 3)
				return [...accum, stepByField[photos]]

			if (
				stepByField[key] >= 0 &&
				(!value || (_isObject(value) && _isEmpty(value)))
			) {
				return [...accum, stepByField[key]]
			} else return accum
		},
		[]
	)

	return [
		!_isEmpty(nullableValuesByKey)
			? Math.min(...nullableValuesByKey)
			: undefined,
		nullableValuesByKey
	]
}

export const capitalize = string => {
	return string.charAt(0).toUpperCase() + string.substring(1).toLowerCase()
}

export const determineNullableFilterValues = obj => (dispatch, getState) => {
	const {
		profile: { user }
	} = getState()
	const userAge = user?.age

	let isMinAgeChanged = false
	let isMaxAgeChanged = false

	return Object.entries(obj).reduce((accum, [key, value]) => {
		if (key === ageFrom) {
			isMinAgeChanged = value[0] !== userAge - 5 && value[0] !== 18
			return isMinAgeChanged ? accum : [...accum, key]
		}
		if (key === ageTo) {
			isMaxAgeChanged = value[0] !== userAge + 10 && value[0] !== 110
			return isMaxAgeChanged ? accum : [...accum, key]
		}

		if (!value || _isEmpty(value)) {
			return [...accum, key]
		} else return accum
	}, [])
}
