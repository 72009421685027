import React from 'react'
import PropTypes from 'prop-types'
import classes from './BaseSearchInput.module.scss'
import BaseInput from 'components/ui/controls/BaseInput'
import { primaryColor } from 'constants/colors'
import SpriteIcon from 'components/ui/SpriteIcon'
import clsx from 'clsx'

const BaseSearchInput = ({ value, name, onChange, className }) => {
	return (
		<div className={clsx(classes.root, className)}>
			<BaseInput
				value={value}
				name={name}
				onChange={onChange}
				inputClass={classes.input}
				rootClass={classes.rootInput}
			/>
			<div className={classes.iconWrapper}>
				<SpriteIcon
					className={classes.icon}
					name="loupe"
					size="md"
					color={primaryColor}
				/>
			</div>
		</div>
	)
}

BaseSearchInput.propTypes = {
	className: PropTypes.string
}

BaseSearchInput.defaultProps = {
	className: ''
}

export default BaseSearchInput
