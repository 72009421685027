import React, { memo, useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Cropper from 'react-cropper'
import './BaseCropper.scss'
import classes from './BaseCropper.module.scss'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import Typography from 'components/ui/Typography'
import clsx from 'clsx'
import { blobToSrc } from 'utils/blobToSrc'
import { screenFormat } from 'constants/screenFormat'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import SpriteIcon from '../../SpriteIcon'

const cropperStyle = {
	height: 'inherit',
	display: 'flex',
	alignItems: 'center'
}

const BaseCropper = ({
	image,
	dragMode,
	open,
	onClose,
	onSave,
	name,
	index
}) => {
	const [cropper, setCropper] = useState()
	const format = useDetermineScreenFormat()

	const handleSave = useCallback(() => {
		if (typeof cropper !== 'undefined') {
			cropper.getCroppedCanvas().toBlob(blob => {
				onSave(name, blob)
			})
		}
	}, [cropper, name, onSave])

	const handleClose = useCallback(() => {
		onClose(index)
	}, [index, onClose])

	const imageToSrc = useMemo(() => blobToSrc(image), [image])

	const handleRotate = useCallback(() => {
		if (typeof cropper !== 'undefined') {
			cropper.rotate(90)
		}
	}, [cropper])

	return (
		<BaseDialog
			classesBackdrop={classes.backdrop}
			classes={{ paper: classes.root }}
			fullScreen={format === screenFormat.mobile}
			open={open}
			onClose={handleClose}
			scroll={format === screenFormat.mobile ? 'paper' : 'body'}
		>
			<Cropper
				style={cropperStyle}
				initialAspectRatio={1}
				src={imageToSrc}
				viewMode={1}
				background={false}
				dragMode={dragMode}
				minCropBoxHeight={30}
				minCropBoxWidth={30}
				onInitialized={instance => {
					setCropper(instance)
				}}
			/>

			<div
				role="presentation"
				aria-label="Rotate"
				className={classes.rotate}
				onClick={handleRotate}
			>
				<SpriteIcon name="rotate" size="md" />
			</div>

			<Typography
				className={clsx(classes.button, classes.left)}
				onClick={handleClose}
			>
				Cancel
			</Typography>
			<Typography
				className={clsx(classes.button, classes.right)}
				onClick={handleSave}
			>
				Add Photo
			</Typography>
		</BaseDialog>
	)
}

BaseCropper.propTypes = {
	className: PropTypes.string,
	dragMode: PropTypes.string,
	image: PropTypes.oneOfType([PropTypes.instanceOf(Blob), PropTypes.string]),
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onSave: PropTypes.func,
	name: PropTypes.any
}

BaseCropper.defaultProps = {
	className: '',
	image: '',
	dragMode: 'move',
	open: false,
	onClose: () => null,
	onSave: () => null,
	name: ''
}

export default memo(BaseCropper)
