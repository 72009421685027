import React, { memo, useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classes from 'components/ui/controls/BaseSelectImages/ImageCard.module.scss'
import SpriteIcon from 'components/ui/SpriteIcon'
import Heading from 'components/ui/Typography/Heading'
import { blobToSrc } from 'utils/blobToSrc'
import { acceptImageTypes } from 'constants/acceptImageTypes'
import { sm } from 'utils/simulateEvent'
import imageCompression from 'browser-image-compression'
import clsx from 'clsx'
import { primaryColor } from 'constants/colors'
import useFlagManager from 'hooks/useFlagManager'
import InfoDialog from 'components/ui/Dialogs/InfoDialog'

const ImageUploadCard = ({
	onAddImage,
	name,
	variant = 'primary',
	image,
	hasEditIcon
}) => {
	const src = useMemo(() => {
		return image instanceof Blob ? blobToSrc(image) : image
	}, [image])
	const infoDialog = useFlagManager()
	const [error, setError] = useState('')

	const handleAddImage = useCallback(
		async e => {
			e.preventDefault()

			const file = (e.dataTransfer
				? e.dataTransfer.files
				: e.target
				? e.target.files
				: [])[0]

			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 1920,
				useWebWorker: true
			}
			try {
				const compressedFile = await imageCompression(file, options)

				await onAddImage(sm(name, compressedFile)) // write your own logic
			} catch (error) {
				console.dir(error)
				setError("Can't compress the image")
				infoDialog.turnIn()
			}
			// onAddImage(sm(name, file))
		},
		[infoDialog, name, onAddImage]
	)

	return (
		<div
			className={clsx(classes.photoCard, classes[variant])}
			style={{ backgroundImage: `url(${src})` }}
		>
			{hasEditIcon && (
				<SpriteIcon name="edit" color={primaryColor} className={classes.icon} />
			)}
			<input
				type="file"
				className={classes.input}
				name={name}
				value=""
				onChange={handleAddImage}
				accept={acceptImageTypes}
			/>
			{!image && (
				<>
					<div className={classes.iconBackground}>
						<SpriteIcon name="plus" className="primary-color" />
					</div>
					{variant === 'primary' && (
						<Heading variant="heading-2" className={classes.placeholder}>
							Add photo
						</Heading>
					)}
				</>
			)}
			<InfoDialog
				open={infoDialog.state}
				onClose={infoDialog.turnOff}
				type={'error'}
				title={'Error'}
				body={error}
			/>
		</div>
	)
}

ImageUploadCard.propTypes = {
	className: PropTypes.string,
	onAddImage: PropTypes.func,
	name: PropTypes.any,
	image: PropTypes.oneOfType([PropTypes.instanceOf(Blob), PropTypes.string]),
	hasEditIcon: PropTypes.bool
}

ImageUploadCard.defaultProps = {
	className: '',
	onAddImage: () => null,
	name: '',
	image: '',
	hasEditIcon: false
}

export default memo(ImageUploadCard)
