import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance'
import appConfig from 'constants/appConfig'
import { serializePreference } from 'modules/preferences/transformers.js'

export const getPreference = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/discovery/filters')

export const updatePreference = data =>
	AuthorizedAxiosInstance.post(
		appConfig.apiPrefix + '/discovery/filters',
		serializePreference(data)
	)

export const getLocationsByIds = location_ids => {
	let urlQuery = ''
	location_ids.forEach((value, index) => {
		if (index !== 0) {
			urlQuery += '&'
		}
		urlQuery += `ids[]=${value}`
	})

	return AuthorizedAxiosInstance.get(
		`${appConfig.apiPrefix}/config/locations?${urlQuery}`
	)
}
