import React, { memo } from 'react'
import classes from 'components/ui/UpgradeBanners/MobilePurpleBanner/MobilePurpleBanner.module.scss'
import Button from 'components/ui/Button'
import { routesByName } from 'constants/routes.js'
import useHistory from 'hooks/useHistory.js'

const config = {
	first: {
		title: (() => (
			<div className={classes.title}>
				Meet Some Couples
				<br /> Who Met on myTamilDate
			</div>
		))(),
		// subTitle: 'Who Met on myTamilDate',
		btn: 'Meet them now'
	},
	two: {
		title: (() => (
			<div className={classes.title}>
				Increase your chances of meeting someone great, become a Premium Member
			</div>
		))(),
		// subTitle: 'great, become a Premium Member',
		btn: 'Upgrade now'
	}
}

const MobilePurpleBanner = ({ variant = 'first' }) => {
	const history = useHistory()

	return (
		<div className={classes.card}>
			{config[variant].title}
			<Button
				shape="rounded"
				className={classes.cardBtn}
				onClick={() => history.push(routesByName.upgrade.root)}
			>
				{config[variant].btn}
			</Button>
		</div>
	)
}

export default memo(MobilePurpleBanner)
