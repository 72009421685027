import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classes from 'components/ui/controls/BaseRadioButtonsGroup/BaseRadioButtonsGroup.module.scss'
import RadioItem from 'components/ui/controls/BaseRadioButton'
import clsx from 'clsx'
import ScrollbarWrapper from 'components/ui/layout/ScrollbarWrapper'
import useButtonsGroupHelper from 'hooks/useButtonsGroupHelper'
import { sm } from 'utils/simulateEvent'

const BaseRadioButtonsGroup = ({
	options,
	variant,
	onChange,
	value,
	name,
	withSearch,
	searchPlaceholder,
	className,
	sortedByAlphabet,
	typePreference,
	isCustom
}) => {
	const {
		searchComponent,
		filteredOptions,
		sortedOptions
	} = useButtonsGroupHelper({
		options,
		withSearch,
		searchPlaceholder
	})

	const newValue = Array.isArray(value) ? value[0] : value

	const handleClick = useCallback(
		selectedRadio => {
			onChange(sm(name, selectedRadio))
		},
		[name, onChange]
	)
	// TODO Refactor ScrollbarWrapper
	return (
		<div className={classes.root}>
			{searchComponent}

			<ScrollbarWrapper
				// height={230}
				height={400}
				hide={!withSearch}
			>
				<div
					className={clsx(
						classes.radioButtonsContainer,
						classes[variant],
						className
					)}
				>
					{sortedByAlphabet
						? Object.keys(sortedOptions).map((key, index) => {
								return (
									<>
										<div key={index} className={classes.letter}>
											{key}
										</div>
										{sortedOptions[key].map(({ value: radioValue, label }) => (
											<RadioItem
												key={`radio-item-${radioValue}-${label}`}
												value={radioValue}
												label={label}
												onClick={handleClick}
												variant={variant}
												isSelect={newValue === radioValue}
												isCustom={isCustom}
											/>
										))}
									</>
								)
						  })
						: filteredOptions.map(({ value: radioValue, label }) => (
								<RadioItem
									key={`radio-item-${radioValue}-${label}`}
									value={radioValue}
									label={label}
									onClick={handleClick}
									variant={variant}
									isSelect={newValue === radioValue}
									isCustom={isCustom}
								/>
						  ))}
				</div>
			</ScrollbarWrapper>
		</div>
	)
}

BaseRadioButtonsGroup.propTypes = {
	className: PropTypes.string,
	searchPlaceholder: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.array,
	variant: PropTypes.oneOf(['default', 'secondary']),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	onChange: PropTypes.func,
	withSearch: PropTypes.bool,
	sortedByAlphabet: PropTypes.bool
}

BaseRadioButtonsGroup.defaultProps = {
	className: '',
	value: '',
	searchPlaceholder: '',
	name: '',
	withSearch: false,
	onChange: () => null,
	options: [],
	variant: 'default',
	sortedByAlphabet: false
}

export default memo(BaseRadioButtonsGroup)
