import React, { useCallback, useMemo, useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import classes from './BaseTooltip.module.scss'
import clsx from 'clsx'
import { screenFormat } from 'constants/screenFormat'
import SpriteIcon from 'components/ui/SpriteIcon'
import { primaryColor } from 'constants/colors'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import Heading from 'components/ui/Typography/Heading'
import Typography from 'components/ui/Typography'
import Button from 'components/ui/Button'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'

const BaseTooltip = ({
	children,
	tooltipClass,
	classes: propsClasses = {},
	tooltip,
	isBold,
	...props
}) => {
	const [openTooltipDialog, setTooltipDialog] = useState(false)
	const format = useDetermineScreenFormat(breakpointsUp.sm)
	const toggleModal = useCallback(() => {
		setTooltipDialog(value => !value)
	}, [])

	const renderMobileTooltip = useMemo(
		() =>
			tooltip &&
			format === screenFormat.mobile && (
				<>
					<SpriteIcon
						className={clsx(classes.tooltipIcon, propsClasses.tooltipIcon)}
						name={!isBold ? 'tooltip-icon' : 'tooltip-icon-bold'}
						size="md"
						color={primaryColor}
						onClick={toggleModal}
					/>
					<BaseDialog
						classes={{ paper: classes.dialogRoot }}
						open={openTooltipDialog}
						onClose={toggleModal}
					>
						<Heading variant="heading-2" className="mb-2">
							{tooltip.title}
						</Heading>

						<Typography variant="body-1" className="mb-2 text-center">
							{tooltip.text}
						</Typography>

						<Button
							onClick={toggleModal}
							variant="contained"
							size="sm"
							shape="rounded"
						>
							OK
						</Button>
					</BaseDialog>
				</>
			),
		[
			format,
			isBold,
			openTooltipDialog,
			propsClasses.tooltipIcon,
			toggleModal,
			tooltip
		]
	)
	const renderDesktopTooltip = useMemo(
		() =>
			tooltip &&
			format === screenFormat.desktop && (
				<Tooltip
					arrow
					placement="bottom-start"
					classes={{
						// ...propsClasses,
						arrow: classes.arrow,
						tooltip: clsx(classes.tooltip, tooltipClass)
					}}
					title={
						<div className={classes.tooltipContent}>
							<Heading
								variant="heading-2"
								className={clsx(classes.tooltipTitle, 'mb-2 text-start')}
							>
								{tooltip.title}
							</Heading>

							<Typography
								variant="body-1"
								className={clsx(classes.tooltipText, 'mb-2')}
							>
								{tooltip.text}
							</Typography>
						</div>
					}
					{...props}
				>
					<SpriteIcon
						className={clsx(classes.tooltipIcon, propsClasses.tooltipIcon)}
						name={!isBold ? 'tooltip-icon' : 'tooltip-icon-bold'}
						size="md"
						color={primaryColor}
						onClick={toggleModal}
					/>
				</Tooltip>
			),
		[format, isBold, props, propsClasses, toggleModal, tooltip, tooltipClass]
	)

	return (
		// <Tooltip
		// 	classes={{
		// 		...propsClasses,
		// 		arrow: classes.arrow,
		// 		tooltip: clsx(classes.tooltip, tooltipClass)
		// 	}}
		// 	{...props}
		// >
		// 	{children}
		// </Tooltip>
		<>
			{renderDesktopTooltip}
			{renderMobileTooltip}
		</>
	)
}

export default BaseTooltip
