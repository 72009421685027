import {
	SEND_CONFIRMATION_CODE,
	RESEND_CONFIRMATION_CODE,
	SIGN_IN
} from './constants'
import update from 'immutability-helper'

const initialState = {
	identity: '',
	expiresIn: ''
}

const signIn = (state = { ...initialState }, { type, payload }) => {
	switch (type) {
		case SEND_CONFIRMATION_CODE: {
			return update(state, {
				$set: payload
			})
		}
		case RESEND_CONFIRMATION_CODE: {
			return update(state, {
				$merge: payload
			})
		}
		case SIGN_IN: {
			return update(state, {
				$set: initialState
			})
		}
		default:
			return state
	}
}

export default signIn
