import * as types from './constants'
import update from 'immutability-helper'

const initialState = {
	receivedRequests: [],
	sentRequests: [],
	receivedRequestsMap: {},
	sentRequestsMap: {}
}

const requestsReducer = (state = { ...initialState }, { type, payload }) => {
	switch (type) {
		case types.GET_REQUESTS: {
			return update(state, {
				sentRequests: {
					$set: payload.sentRequests
				},
				sentRequestsMap: {
					$set: payload.sentRequestsMap
				},
				receivedRequests: {
					$set: payload.receivedRequests
				},
				receivedRequestsMap: {
					$set: payload.receivedRequestsMap
				}
			})
		}
		case types.REMOVE_RECEIVED_REQUEST: {
			return update(state, {
				receivedRequests: {
					$set: payload.receivedRequests
				},
				receivedRequestsMap: {
					$set: payload.receivedRequestsMap
				}
			})
		}
		default:
			return state
	}
}

export default requestsReducer
