import React, { useCallback, useMemo, useState } from 'react'
import BaseSearchInput from 'components/ui/controls/BaseSearchInput'
import { capitalize } from '../modules/profile/createProfile/utils'
import { transformArrayToDictionary } from 'utils/transformArrayToDictionary'
import PropTypes from 'prop-types'

const defaultFilter = (field, search) =>
	field.label.startsWith(capitalize(search))

const useButtonsGroupHelper = ({
	options = [],
	withSearch = false,
	searchPlaceholder = '',
	filterFunction = defaultFilter
}) => {
	const [search, setSearch] = useState()

	const filteredOptions = useMemo(
		() =>
			search ? options.filter(field => filterFunction(field, search)) : options,
		[filterFunction, options, search]
	)

	const sortedOptions = useMemo(
		() => transformArrayToDictionary(filteredOptions),
		[filteredOptions]
	)

	const handleChangeSearch = useCallback(
		({ target: { value } }) => setSearch(value),
		[]
	)

	const searchComponent = useMemo(
		() =>
			withSearch && (
				<BaseSearchInput
					className="mb-1"
					placeholder={searchPlaceholder}
					value={search}
					onChange={handleChangeSearch}
				/>
			),
		[handleChangeSearch, search, searchPlaceholder, withSearch]
	)

	return {
		searchComponent,
		filteredOptions,
		sortedOptions
	}
}

useButtonsGroupHelper.propTypes = {
	options: PropTypes.array,
	withSearch: PropTypes.bool,
	searchPlaceholder: PropTypes.string,
	filterFunction: PropTypes.func
}

export default useButtonsGroupHelper
