import * as types from './constants'
import update from 'immutability-helper'

const initialState = {
	unsubscribeInfo: []
}

const emailsReducer = (state = { ...initialState }, { type, payload }) => {
	switch (type) {
		case types.GET_UNSUBSCRIBE_INFO: {
			return update(state, {
				unsubscribeInfo: {
					$set: payload
				}
			})
		}
		case types.TOGGLE_STATE: {
			return update(state, {
				unsubscribeInfo: {
					[payload.id]: { $set: payload.item }
				}
			})
		}
		default:
			return state
	}
}

export default emailsReducer
