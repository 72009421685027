import update from 'immutability-helper'
import * as types from './constants'
export const initialDialogManagerState = {
	title: '',
	body: '',
	onClose: () => null,
	onConfirm: () => null,
	open: false,
	confirmBtn: {
		title: 'Yes'
	},
	cancelBtn: {
		title: 'No'
	},
	withCancelBtn: false,
	withConfirmBtn: false,
	boldText: false
}

const dialogManagerReducer = (
	state = { ...initialDialogManagerState },
	{ type, payload }
) => {
	switch (type) {
		case types.SET_DIALOG_MANAGER_STATE: {
			return update(state, {
				$merge: payload
			})
		}
		case types.CLOSE_DIALOG_MANAGER: {
			return update(state, {
				$set: initialDialogManagerState
			})
		}
		default:
			return state
	}
}

export default dialogManagerReducer
