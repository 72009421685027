import { useEffect } from 'react'

const _useDidMount = fn =>
	useEffect(
		() => {
			fn && fn()
		},
		//eslint-disable-next-line
		[]
	)

export default _useDidMount
