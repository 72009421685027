import React from 'react'
import Echo from './index.js'

export const EchoContext = React.createContext(Echo)

const EchoProvider = ({ children }) => {
	return <EchoContext.Provider value={Echo}>{children}</EchoContext.Provider>
}

export default EchoProvider
