import * as types from 'modules/conversation/store/constants.js'
import messengerApi from 'libs/messenger'
// import * as moment from 'moment'
import { getUsersByIds } from 'modules/users/actions'
import { transformArrayToObjectById } from 'utils/transformArrayToObjectById.js'
import { _isEmpty } from 'utils/lodash.js'
import { SET_IS_LAST_MESSAGE_READ } from 'modules/conversation/store/constants'

export const getConversations = (params = {}, mode = 'set') => async (
	dispatch,
	getState
) => {
	const {
		profile: {
			user: { id: myId }
		}
	} = getState()

	try {
		const conversations = await messengerApi.conversations.getList({
			limit: 20,
			...params
		})

		const users = conversations.reduce(
			(acc, conversation) => [
				...acc,
				conversation?.participants?.find(({ userId }) => userId !== myId)
					?.userId
			],
			[]
		)

		if (users.length) {
			dispatch(getUsersByIds(users))
		}

		const conversationsWithReadStatus = conversations.map(conversation => ({
			...conversation,
			isLastMessageRead: false
		}))

		const conversationsMap = transformArrayToObjectById(
			conversationsWithReadStatus
		)
		const metaByConversationId = conversations.reduce(
			(acc, conversation) => ({
				...acc,
				[conversation?.id]: {
					hasNextPage: true
				}
			}),
			{}
		)

		dispatch({
			type: mode === 'set' ? types.GET_CONVERSATIONS : types.PUSH_CONVERSATIONS,
			payload: {
				conversations,
				conversationsMap,
				metaByConversationId,
				hasMore: !!conversations.length
			}
		})
	} catch (e) {
		console.dir(e)
	}
}

export const setIsLastMessageRead = (conversationId, status) => (
	dispatch,
	getState
) => {
	const {
		messenger: { conversationsMap }
	} = getState()

	const {
		profile: {
			user: { id: myId }
		}
	} = getState()

	const hasLastMessage = !!conversationsMap[conversationId]?.lastMessage
	const isOwnLastMessage =
		conversationsMap[conversationId]?.lastMessage?.userId === myId
	const isLastMessageAlreadyRead =
		conversationsMap[conversationId]?.isLastMessageRead
	const lastMessageSentAt =
		conversationsMap[conversationId]?.lastMessage?.sentAt

	if (
		status &&
		hasLastMessage &&
		!isOwnLastMessage &&
		!isLastMessageAlreadyRead
	) {
		dispatch(readConversation(conversationId, lastMessageSentAt))
	}

	if (!isOwnLastMessage) {
		dispatch({
			type: SET_IS_LAST_MESSAGE_READ,
			payload: {
				...conversationsMap[conversationId],
				isLastMessageRead: status
			}
		})
	}
}

export const getConversationById = id => async dispatch => {
	try {
		const conversation = await messengerApi.conversations.byId(id).get()

		dispatch({ type: types.GET_CONVERSATION_BY_ID, payload: conversation })
	} catch (e) {
		console.dir(e)
	}
}

export const getNextPageMessages = (id = null, params) => async dispatch => {
	try {
		const messages =
			(await messengerApi.conversations.byId(id).messages.get(params)) || []

		if (_isEmpty(messages)) {
			dispatch({
				type: types.SET_FLAG_HAS_NEXT_PAGE,
				payload: {
					id,
					hasNextPage: false
				}
			})
		} else {
			dispatch({
				type: types.SET_FLAG_HAS_NEXT_PAGE,
				payload: {
					id,
					hasNextPage: true
				}
			})
		}

		const transformMessages = messages?.map(message => ({
			...message,
			type: 'text'
		}))

		dispatch({
			type: types.GET_NEXT_PAGE_MESSAGES,
			payload: { id, messages: transformMessages }
		})
	} catch (e) {
		console.dir(e)
	}
}

export const getMessagesByConversationId = (
	id = null,
	params = { sort: 'desc', limit: 40 }
) => async dispatch => {
	try {
		const messages =
			(await messengerApi.conversations.byId(id).messages.get(params)) || []

		const transformMessages = messages?.map(message => ({
			...message,
			type: 'text'
		}))

		dispatch({
			type: types.GET_MESSAGES_BY_CONVERSATION_BY_ID,
			payload: { id, messages: transformMessages }
		})
	} catch (e) {
		console.dir(e)
	}
}

export const sendMessage = (id, data) => async (dispatch, getState) => {
	try {
		const message = await messengerApi.conversations
			.byId(id)
			.messages.send(data)

		message.type = 'text'

		const {
			messenger: { messagesByConversationId }
		} = getState()
		const messages = messagesByConversationId[Number(id)] || []

		if (messages.some(({ id }) => message.id === id)) {
			return null
		} else {
			dispatch({
				type: types.SEND_MESSAGE,
				payload: { id, message }
			})
		}
	} catch (e) {
		console.dir(e)
	}
}

export const readConversation = (id, lastMessageSentAt) => async dispatch => {
	try {
		const result = await messengerApi.conversations
			.byId(id)
			// .setRead({ timestamp: moment().unix() * 1000 })
			.setRead({ timestamp: lastMessageSentAt })

		dispatch({ type: types.READ_CONVERSATION, payload: { id, result } })
	} catch (e) {
		console.dir(e)
	}
}

export const setCurrentConversation = id => dispatch => {
	dispatch(clearMessengerMeta())

	dispatch({
		type: types.SET_CURRENT_CONVERSATION,
		payload: id
	})
}

export const pushReceivedMessage = ({ conversation, message }) => (
	dispatch,
	getState
) => {
	const {
		messenger: { messagesByConversationId }
	} = getState()
	const messages = messagesByConversationId[Number(conversation.id)] || []

	message.type = 'text'
	message.userId = message.senderId

	if (messages.some(({ id }) => message.id === id)) {
		return null
	} else {
		dispatch({
			type: types.PUSH_RECEIVED_MESSAGE,
			payload: { id: conversation.id, message }
		})

		dispatch(setIsLastMessageRead(conversation.id, true))
	}
}

export const createConversation = conversation => {
	return {
		type: types.CREATE_CONVERSATION,
		payload: conversation
	}
}

export const setUserForCreateConversation = id => dispatch => {
	dispatch(clearMessengerMeta())

	dispatch({
		type: types.SET_USER_FOR_CREATE_CONVERSATION,
		payload: id
	})
}

export const clearMessengerMeta = () => ({
	type: types.CLEAR_MESSENGER_META
})

export const setConversation = data => (dispatch, getState) => {
	const {
		messenger: { conversationsMap }
	} = getState()

	const currentConversation = conversationsMap[data.conversation.id]

	if (currentConversation) {
		currentConversation.lastMessage = data.message
		currentConversation.lastMessage.userId = data.message.senderId
		delete currentConversation.lastMessage.senderId

		dispatch({
			type: types.SET_CONVERSATION,
			payload: currentConversation
		})

		dispatch(setIsLastMessageRead(data.conversation.id, false))
	}
}

export const removeConversationByParticipantId = participantId => (
	dispatch,
	getState
) => {
	const {
		messenger: { conversations }
	} = getState()

	let conversationToDelete

	let filteredConversations = conversations?.map(conversation => {
		const { participants } = conversation
		participants.forEach(({ userId }) => {
			if (userId === participantId) {
				conversationToDelete = conversation
			}
		})
		return conversation
	})

	filteredConversations = conversations?.filter(conversation => {
		return conversation !== conversationToDelete
	})

	dispatch({
		type: types.REMOVE_CONVERSATION_BY_PARTICIPANT_ID,
		payload: filteredConversations
	})
}
