import * as types from './constants'
import * as appTypes from '../../app/store/constants'
import { saveUsers } from 'modules/users/actions'
import * as services from 'modules/discovery/services'
import { extractFields } from 'utils/exctractIds'
import unionArray from 'utils/unionArray.js'
import { _get, _difference, _isEmpty } from 'utils/lodash'
import {
	closeDialogManager,
	setDialogManagerState
} from 'modules/dialogManager/store/actions'
import { APPROVED } from 'hooks/useUpgradeNotification'
import {
	SET_WAVE,
	SET_POPUP_STATUS,
	RESET_POPUP_STATUS,
	SET_USERS_ARE_OVER,
	SKIP_USER_VIEW
} from './constants'

export const prepareNextApplicant = () => async (dispatch, getState) => {
	try {
		const {
			discovery: { applicants, skipuserview },
			app: { countToShowUpgradeBanner, isShownUpgradeBanner },
			profile: {
				user: { subscriptionStatus }
			}
		} = getState()

		const shiftedValues = applicants.slice(1, applicants.length)

		const {
			discovery: { wave }
		} = getState()

		dispatch({
			type: types.PREPARE_NEXT_APPLICANT,
			payload: {
				applicants: shiftedValues
			}
		})
		if (
			!subscriptionStatus &&
			countToShowUpgradeBanner <= 25 &&
			!isShownUpgradeBanner
		) {
			dispatch({
				type: appTypes.UPDATE_BANNER_COUNTER
			})
		}
		if (shiftedValues.length === 0) {
			if (skipuserview) {
				await dispatch(getDiscoveryUsersSkipped())
			} else {
				await dispatch(getMoreDiscoveryUsers({ wave }))
			}
		}
	} catch (e) {
		console.dir(e)
	}
}

export const getDiscoveryUsers = params => async (dispatch, getState) => {
	try {
		dispatch(setSkipUserView(false))
		const response = await services.getDiscoveryUsers(params)

		dispatch(setWave(params.wave))

		const {
			discovery: { rejected, liked }
		} = getState()

		const applicants = _get(response, 'result', [])

		if (_isEmpty(applicants)) {
			dispatch(handleChangeWave(params.wave))
		} else {
			const applicantIds = extractFields(applicants)

			dispatch(saveUsers(applicants || []))

			const applicantsWithoutAlreadyTriggered = _difference(applicantIds, [
				...rejected,
				...liked
			])

			if (_isEmpty(applicantsWithoutAlreadyTriggered)) {
				dispatch(handleChangeWave(params.wave))
			}

			dispatch({
				type: types.GET_DISCOVERY_USERS,
				payload: applicantsWithoutAlreadyTriggered
			})
		}
	} catch (e) {
		console.dir(e)
	}
}

export const getMoreDiscoveryUsers = params => async (dispatch, getState) => {
	try {
		const {
			discovery: { rejected, liked }
		} = getState()

		const response = await services.getDiscoveryUsers({ wave: params.wave })

		const applicants = _get(response, 'result', [])

		const {
			discovery: { applicants: savedApplicantsIds }
		} = getState()

		if (_isEmpty(applicants)) {
			dispatch(handleChangeWave(params.wave))
		} else {
			const newApplicantsIds = extractFields(applicants)

			const applicantsWithoutAlreadyTriggered = _difference(newApplicantsIds, [
				...rejected,
				...liked
			])

			if (_isEmpty(applicantsWithoutAlreadyTriggered)) {
				dispatch(handleChangeWave(params.wave))
			}

			dispatch(saveUsers(applicants))

			dispatch({
				type: types.GET_MORE_DISCOVERY_USERS,
				payload: unionArray(
					applicantsWithoutAlreadyTriggered,
					savedApplicantsIds
				)
			})
		}
	} catch (e) {
		console.dir(e)
	}
}

export const preparePreviousApplicant = applicantId => async (
	dispatch,
	getState
) => {
	try {
		const {
			discovery: { applicants }
		} = getState()

		let unshiftedApplicants = [...applicants]

		if (applicants[0] !== applicantId) {
			unshiftedApplicants = [applicantId, ...applicants]
		}

		dispatch({
			type: types.PREPARE_PREVIOUS_APPLICANT,
			payload: {
				applicants: unshiftedApplicants
			}
		})
	} catch (e) {
		console.dir(e)
	}
}

export const likeApplicant = id => async dispatch => {
	try {
		await services.likeApplicant(id)
		dispatch(prepareNextApplicant())

		dispatch({
			type: types.LIKE_APPLICANT,
			payload: [id]
		})
	} catch (e) {
		console.dir(e)
	}
}

export const rejectApplicant = id => async dispatch => {
	try {
		await services.rejectApplicant(id)
		dispatch(prepareNextApplicant())

		dispatch({
			type: types.REJECT_APPLICANT,
			payload: [id]
		})
	} catch (e) {
		console.dir(e)
	}
}

const handleChangeWave = wave => (dispatch, getState) => {
	const {
		profile: {
			user: { approval }
		}
	} = getState()

	if (approval === APPROVED) {
		const {
			discovery: { popupStatus }
		} = getState()

		const isPopupShown = popupStatus[wave.toString()]
		const isFirstWave = wave === 0
		const isLastWave = wave === 3

		const onClose = () => {
			dispatch(closeDialogManager())
			dispatch(setPopupStatus({ [wave]: true }))
			dispatch(getDiscoveryUsers({ wave: wave + 1 }))
		}

		if (isFirstWave || isLastWave) {
			dispatch(setUsersAreOver(true))
			dispatch(getDiscoveryUsersSkipped())
		} else {
			if (!isPopupShown) {
				dispatch(
					setDialogManagerState({
						open: true,
						body:
							'We’ve shown you the users who meet your criteria.  Let’s help you discover some other awesome users who you have not considered!',
						onConfirm: onClose,
						confirmBtn: { title: 'OK' },
						withCancelBtn: false,
						onClose,
						withConfirmBtn: true
					})
				)
			} else {
				dispatch(getDiscoveryUsers({ wave: wave + 1 }))
			}
		}
	}
}

// Get Skip User Function
const getDiscoveryUsersSkipped = () => async (dispatch, getState) => {
	try {
		dispatch(setSkipUserView(true))

		const {
			discovery: { wave, rejected, liked }
		} = getState()

		const response = await services.getDiscoveryUsersSkipped()
		const applicants = _get(response, 'result', [])

		if (_isEmpty(applicants)) {
			dispatch(setUsersAreOver(true))
		} else {
			const applicantIds = extractFields(applicants)

			dispatch(saveUsers(applicants || []))

			const applicantsWithoutAlreadyTriggered = _difference(applicantIds, [
				...rejected,
				...liked
			])

			if (_isEmpty(applicantsWithoutAlreadyTriggered)) {
				dispatch(setSkipUserView(false))
				dispatch(handleChangeWave(wave))
			}

			dispatch({
				type: types.GET_DISCOVERY_USERS,
				payload: applicantsWithoutAlreadyTriggered
			})
		}
	} catch (error) {
		console.dir(error)
	}
}

export const setWave = wave => ({
	type: SET_WAVE,
	payload: wave
})

export const setPopupStatus = value => ({
	type: SET_POPUP_STATUS,
	payload: value
})

export const resetPopupStatus = () => ({
	type: RESET_POPUP_STATUS,
	payload: {
		1: false,
		2: false
	}
})

export const setUsersAreOver = value => ({
	type: SET_USERS_ARE_OVER,
	payload: value
})

// Get Skip User constants
export const setSkipUserView = value => ({
	type: SKIP_USER_VIEW,
	payload: value
})
