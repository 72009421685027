import React, { useCallback, useState } from 'react'
import SignUpWelcomeScreen from 'pages/SignUpWelcomeScreen'
import { Formik } from 'formik'
import View from 'modules/profile/createProfile/View'
import {
	birthday,
	firstName,
	gender,
	languages,
	lastName,
	personalities,
	steps,
	wantGender,
	location,
	study,
	job,
	growth,
	religion,
	answers,
	kid,
	smoke,
	drink,
	// stepByField,
	familyPlans
} from 'modules/profile/createProfile/config'
import classes from './CreateProfile.module.scss'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import Header from 'components/ui/layout/GuestHeader'
import Stepper from 'components/ui/Stepper'
import { screenFormat } from 'constants/screenFormat'
import * as moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
	getMeAction,
	setCurrentStepCreateProfile,
	setFinishedCreateProfile,
	updateMe
} from 'modules/profile/store/actions'
import { determineMissingSteps } from 'modules/profile/createProfile/utils'
import { toggleLoader } from 'modules/app/store/actions'
import { breakpointsUp } from 'constants/breakpoints.js'
import { routesByName } from 'constants/routes'
import useHistory from 'hooks/useHistory'
import useFlagManager from 'hooks/useFlagManager'
import InfoDialog from 'components/ui/Dialogs/InfoDialog'
import {
	closeDialogManager,
	setDialogManagerState
} from '../../dialogManager/store/actions'
import { _isEmpty } from '../../../utils/lodash'

const countSteps = steps.length

export const defaultAge = moment().subtract(18, 'years').format('YYYY-MM-DD')

const initialValues = {
	[firstName]: '',
	[lastName]: '',
	[birthday]: defaultAge,
	[gender]: '',
	[wantGender]: '',
	[languages]: [],
	[personalities]: [],
	[location]: '',
	[study]: '',
	[job]: '',
	[familyPlans]: '',
	[growth]: '',
	[religion]: '',
	[answers]: {},
	[kid]: '',
	[smoke]: '',
	[drink]: ''
}

const CreateProfile = () => {
	const [showForm, setShowForm] = useState(true)
	const format = useDetermineScreenFormat(breakpointsUp.sm)
	const history = useHistory()
	const { user, config, currentStep } = useSelector(({ profile }) => profile)
	// const history = useHistory()
	const infoDialog = useFlagManager()
	const [error, setError] = useState('')

	const dispatch = useDispatch()

	const toggleShowForm = useCallback(() => {
		setShowForm(value => !value)
	}, [])

	const handleSubmit = useCallback(
		async values => {
			const nextStep = currentStep + 1

			const isLastStep = nextStep === countSteps

			try {
				dispatch(toggleLoader())
				await steps[currentStep].handleValues(values, dispatch)

				dispatch(updateMe(values))
			} catch (e) {
				setError(e.generalError)
				infoDialog.turnIn()
			} finally {
				dispatch(toggleLoader())
			}

			const [, allMissingSteps] = determineMissingSteps(values)

			if (!isLastStep) {
				dispatch(setCurrentStepCreateProfile(nextStep))
			} else {
				history.push(routesByName.selectedPlan)
				// setShowSelectedPlanDialog(true)
			}

			if (!allMissingSteps.length) {
				dispatch(getMeAction())
				dispatch(setFinishedCreateProfile())
			}
		},
		[currentStep, dispatch, history, infoDialog]
	)

	const goToPrevStep = useCallback(() => {
		dispatch(setCurrentStepCreateProfile(currentStep - 1))
	}, [currentStep, dispatch])

	const setStep = useCallback(
		step => {
			if (step < currentStep) {
				dispatch(setCurrentStepCreateProfile(step))
			}
		},
		[currentStep, dispatch]
	)

	const handleShowInfoDialog = useCallback(
		result => {
			if (!_isEmpty(result)) {
				dispatch(
					setDialogManagerState({
						open: true,
						body: result[Object.keys(result)[0]],
						onClose: () => dispatch(closeDialogManager()),
						onConfirm: () => dispatch(closeDialogManager()),
						withCancelBtn: false,
						boldText: true
					})
				)
			}
		},
		[dispatch]
	)

	return !showForm ? (
		<SignUpWelcomeScreen onClickStart={toggleShowForm} />
	) : (
		<div className={classes.root}>
			<Header
				hideLogoOnMobile
				variantLogo="dark"
				hasBack={format === screenFormat.mobile && currentStep > 0}
				onClickBackIcon={goToPrevStep}
			/>
			<div className={classes.content}>
				<Stepper
					count={countSteps}
					step={currentStep}
					className={classes.stepper}
					onClickStep={setStep}
					hideOnMobile
				/>
				<Formik
					initialValues={initialValues}
					onSubmit={handleSubmit}
					validationSchema={steps[currentStep]?.validationSchema}
					enableReinitialize
					validateOnBlur={false}
					validateOnChange={true}
				>
					{({ setValues, validateForm }) => (
						<View
							components={steps[currentStep]}
							user={user}
							config={config}
							format={format}
							setValues={setValues}
							submitForm={() => {
								validateForm().then(result => {
									handleShowInfoDialog(result)
								})
							}}
						/>
					)}
				</Formik>
			</div>
			<InfoDialog
				open={infoDialog.state}
				onClose={infoDialog.turnOff}
				type={'error'}
				title={'Error'}
				body={error}
			/>
		</div>
	)
}

export default CreateProfile
