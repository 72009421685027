import { Route } from 'react-router-dom'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import { screenFormat } from 'constants/screenFormat'
import { getSubscriptionStatus } from '../modules/profile/store/selectors'

const RouteBuilder = ({
	exact = false,
	path,
	component,
	onlySubscribe = false,
	onlyUnsubscribe = false,
	onlyDesktop = false,
	onlyMobile = false
}) => {
	const isSubscribe = useSelector(getSubscriptionStatus)
	const isMobile =
		useDetermineScreenFormat(breakpointsUp.sm) === screenFormat.mobile

	const isInject = useMemo(() => {
		if (isSubscribe && onlyUnsubscribe) return false
		if (!isSubscribe && onlySubscribe) return false
		if (isMobile && onlyDesktop) return false

		return !(!isMobile && onlyMobile)
	}, [
		isMobile,
		onlyDesktop,
		onlyMobile,
		onlySubscribe,
		onlyUnsubscribe,
		isSubscribe
	])

	return isInject ? (
		<Route exact={exact} path={path} component={component} />
	) : null
}

const mapRouteConfig = config =>
	config.map(props => <RouteBuilder {...props} key={props.path} />)

export default mapRouteConfig
