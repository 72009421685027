import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classes from './Stepper.module.scss'
import clsx from 'clsx'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { screenFormat } from 'constants/screenFormat'

const Stepper = ({ step, count, hideOnMobile, onClickStep, className }) => {
	const format = useDetermineScreenFormat()

	const handleClickStep = useCallback(
		step => {
			onClickStep(step)
		},
		[onClickStep]
	)

	const renderSteps = useMemo(() => {
		let steps = []

		for (let i = 0; i < count; i++) {
			const isActive = step === i
			steps.push(
				<div
					role="presentation"
					className={clsx(classes.step, { [classes.active]: isActive })}
					key={`step-${i}-${isActive}`}
					onClick={() => handleClickStep(i)}
				>
					{isActive ? i + 1 : null}
				</div>
			)
		}

		return steps
	}, [count, handleClickStep, step])

	return hideOnMobile && format === screenFormat.mobile ? null : (
		<div className={clsx(className, classes.root)}>{renderSteps}</div>
	)
}

Stepper.propTypes = {
	step: PropTypes.number.isRequired,
	count: PropTypes.number.isRequired,
	hideOnMobile: PropTypes.bool,
	onClickStep: PropTypes.func,
	className: PropTypes.string
}

Stepper.defaultProps = {
	step: 0,
	count: 1,
	hideOnMobile: false,
	onClickStep: () => null,
	className: ''
}

export default Stepper
