class RequestError extends Error {
	validation = {}
	exception = {}

	constructor({ response, ...rest }) {
		super()
		this.response = response

		Object.keys(rest).forEach(key => {
			this[key] = rest[key]
		})

		if (response && response.data) {
			if (response.data.validation) {
				this.validation = response.data.validation
			}
			if (response.data.exception) {
				this.exception = response.data.exception
			}
			if (response.data.error) {
				this.message = response.data.error.message
				this.code = response.data.error.code
			}
		}
	}

	get generalError() {
		return this.message
	}

	get getException() {
		return this.exception.message
	}

	get validationErrors() {
		return this.validation
	}
}

export default RequestError
