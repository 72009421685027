import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import clsx from 'clsx'
import classes from './ErrorGroup.module.scss'
import ErrorMessage from 'components/ui/ErrorMessage'
import SuccessMessage from 'components/ui/SuccessMessage'

const ErrorsGroup = ({ children, className }) => {
	const context = useFormikContext()

	const formNames = useMemo(
		() => children?.map(({ props: { name } }) => name),
		[children]
	)

	const currentError = useMemo(
		() =>
			formNames.reduce(
				(accum, name) =>
					context.errors.hasOwnProperty(name) && !accum
						? context.errors[name]
						: accum,
				''
			),
		[context.errors, formNames]
	)

	const currentTouched = useMemo(
		() =>
			formNames.reduce(
				(accum, name) =>
					context.touched.hasOwnProperty(name) && !accum
						? context.touched[name]
						: accum,
				false
			),
		[context.touched, formNames]
	)

	return (
		<div className={clsx(classes.root, className)}>
			{children.map((c, index) =>
				React.cloneElement(c, { hideError: true, key: `field-${index}` })
			)}
			{currentError && currentTouched && (
				<ErrorMessage className={classes.error}>{currentError}</ErrorMessage>
			)}
			{!currentError && currentTouched && (
				<SuccessMessage>Verified!</SuccessMessage>
			)}
		</div>
	)
}

ErrorsGroup.propTypes = {
	className: PropTypes.string
}

ErrorsGroup.defaultProps = {
	className: ''
}

export default ErrorsGroup
