import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classes from './EditModal.module.scss'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { screenFormat } from 'constants/screenFormat'
import Heading from 'components/ui/Typography/Heading'
import SpriteIcon from 'components/ui/SpriteIcon'
import FormikFieldTextArea from 'components/wrappers/formik/FormikFieldTextArea'
import Button from 'components/ui/Button'
import clsx from 'clsx'

const EditModal = ({
	open,
	onClose,
	title,
	name,
	onCancel,
	onClickDone,
	placeholder
}) => {
	const format = useDetermineScreenFormat()

	return (
		<BaseDialog
			fullScreen={format === screenFormat.mobile}
			open={open}
			onClose={onClose}
			maxWidth="md"
		>
			<div className={classes.dialog}>
				<header className={classes.headerDialog}>
					{format === screenFormat.mobile ? (
						<SpriteIcon
							name="arrow-back"
							className={classes.closeIcon}
							onClick={onClose}
							color="#E5E5E5"
						/>
					) : (
						<SpriteIcon
							name="close"
							className={classes.closeIcon}
							onClick={onClose}
						/>
					)}
				</header>

				<div className={classes.contentDialog}>
					<Heading className={classes.titleDialog}>{title}</Heading>

					<div className={classes.inputDialog}>
						<FormikFieldTextArea
							hideError
							rowsMin={10}
							name={name}
							placeholder={placeholder}
							maxLength={200}
							className={classes.input}
						/>
					</div>

					<div className={classes.buttons}>
						<Button
							size="xl"
							shape="rounded"
							className={classes.btn}
							onClick={onClickDone}
						>
							Done
						</Button>

						<Button
							size="xl"
							shape="rounded"
							className={clsx(classes.btn, classes.cancelBtn)}
							onClick={onCancel}
							variant="outlined"
						>
							Cancel
						</Button>
					</div>
				</div>
			</div>
		</BaseDialog>
	)
}

EditModal.propTypes = {
	className: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.array,
	value: PropTypes.string,
	onChange: PropTypes.func
}

EditModal.defaultProps = {
	className: '',
	value: '',
	name: '',
	onChange: () => null,
	options: []
}

export default memo(EditModal)
