import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classes from './RadioItem.module.scss'
import clsx from 'clsx'
import { primaryColor } from 'constants/colors'
import { withStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const CustomRadio = withStyles({
	root: {
		color: primaryColor,
		'&$checked': {
			color: primaryColor
		}
	},
	checked: {}
})(props => <Radio {...props} />)

const BaseRadioButton = ({
	value,
	label,
	onClick,
	variant,
	className,
	isSelect,
	isCustom
}) => {
	const handleClick = useCallback(() => {
		onClick(value)
	}, [onClick, value])

	return (
		<>
			{isCustom ? (
				<FormControlLabel
					value="top"
					control={
						<CustomRadio
							value={value}
							checked={isSelect}
							onClick={handleClick}
						/>
					}
					label={label}
				/>
			) : (
				<div
					role="presentation"
					className={clsx(className, classes.root, classes[variant], {
						[classes.active]: isSelect
					})}
					onClick={handleClick}
				>
					{label}
				</div>
			)}
		</>
	)
}

BaseRadioButton.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	label: PropTypes.string,
	variant: PropTypes.string,
	isSelect: PropTypes.bool
}

BaseRadioButton.defaultProps = {
	className: '',
	isSelect: false,
	variant: 'default',
	value: '',
	label: '',
	onClick: () => null
}

export default BaseRadioButton
