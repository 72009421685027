import React, { useCallback, useMemo } from 'react'
import { routesByName } from 'constants/routes.js'
import useHistory from 'hooks/useHistory.js'
import { useSelector } from 'react-redux'
import { getSubscriptionStatus } from 'modules/profile/store/selectors.js'
import upgradeToastr from 'libs/toastr/upgradeToastr.js'
import MobileTextBanner from 'components/ui/UpgradeBanners/MobileTextBanner/MobileTextBanner'
import MobilePurpleBanner from 'components/ui/UpgradeBanners/MobilePurpleBanner/MobilePurpleBanner'
import TextBannerDialog from 'components/ui/UpgradeBanners/TextBannerDialog/TextBannerDialog'
import useFlagManager from 'hooks/useFlagManager'
import DesktopCarouselBanner from 'components/ui/UpgradeBanners/DesktopCarouselBanner'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { breakpointsUp } from 'constants/breakpoints'
import { screenFormat } from 'constants/screenFormat'
import MobileCarouselBanner from 'components/ui/UpgradeBanners/MobileCarouselBanner'
import { getUserApproval } from 'modules/profile/store/selectors'

export const PENDING = 10
export const UNKNOWN = 15
export const APPROVED = 20
export const REJECTED = 30
export const REGISTERED = 40

const useUpgradeNotification = () => {
	const history = useHistory()
	const subscriptionStatus = useSelector(getSubscriptionStatus)
	const isMobile =
		useDetermineScreenFormat(breakpointsUp.sm) === screenFormat.mobile
	const dialog = useFlagManager()
	const userApproval = useSelector(getUserApproval)

	const showUpgradeToastr = useCallback(
		messageType =>
			upgradeToastr(() => history.push(routesByName.upgrade.root), messageType),
		[history]
	)

	const showNotification = useMemo(
		() => !subscriptionStatus && userApproval === APPROVED,
		[subscriptionStatus, userApproval]
	)

	const renderTextBanner = useMemo(
		() => (showNotification ? <MobileTextBanner /> : null),
		[showNotification]
	)

	const renderPurpleBanner = useMemo(
		() => (showNotification ? <MobilePurpleBanner /> : null),
		[showNotification]
	)

	const renderTextBannerDialog = useMemo(
		() =>
			showNotification ? (
				<TextBannerDialog open={dialog.state} onClose={dialog.turnOff} />
			) : null,
		[dialog.state, dialog.turnOff, showNotification]
	)

	const renderCarouselBanner = useMemo(
		() => (showNotification && !isMobile ? <DesktopCarouselBanner /> : null),
		[isMobile, showNotification]
	)

	const renderMobileCarouselBanner = useMemo(
		() => (showNotification && isMobile ? <MobileCarouselBanner /> : null),
		[isMobile, showNotification]
	)

	return {
		textBanner: renderTextBanner,
		isSubscribe: subscriptionStatus,
		purpleBanner: renderPurpleBanner,
		carouselBanner: renderCarouselBanner,
		mobileCarouselBanner: renderMobileCarouselBanner,
		showUpgradeToastr,
		unlockFeatures: {
			controller: dialog,
			component: renderTextBannerDialog
		}
	}
}

export default useUpgradeNotification
