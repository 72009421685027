import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import classes from './ToastrIconContainer.module.scss'

const ToastrIconContainer = ({ type, children }) => {
	return <div className={clsx(classes.root, classes[type])}>{children}</div>
}

ToastrIconContainer.propTypes = {
	type: PropTypes.string,
	children: PropTypes.node.isRequired
}

ToastrIconContainer.defaultProps = {
	type: 'info'
}

export default ToastrIconContainer
