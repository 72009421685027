import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classes from './BaseSelectLocation.module.scss'
import clsx from 'clsx'
import { sm } from 'utils/simulateEvent.js'
import BaseAsyncSelect from 'components/wrappers/ui/BaseAsyncSelect'

const BaseSelectLocation = ({
	value,
	name,
	onChange,
	placeholder = 'Enter location',
	classes: outerClasses = {},
	isClearable,
	type = 0
}) => {
	const handleChange = useCallback(
		event => {
			onChange(sm(name, event?.value))
		},
		[name, onChange]
	)

	return (
		<div className={clsx(classes.root, outerClasses.root)}>
			<BaseAsyncSelect
				value={value}
				onChange={handleChange}
				placeholder={placeholder}
				isClearable={isClearable}
				type={type}
			/>
		</div>
	)
}

BaseSelectLocation.propTypes = {
	className: PropTypes.string
}

BaseSelectLocation.defaultProps = {
	className: ''
}

export default memo(BaseSelectLocation)
