import update from 'immutability-helper'
import * as types from 'modules/profile/store/constants'
import { configFields } from 'modules/profile/store/constants'
import { UNKNOWN } from 'hooks/useUpgradeNotification'

const configField = {
	original: {},
	options: []
}

export const userShape = {
	id: 0,
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	birthday: '',
	cityName: '',
	countryName: '',
	genderId: 0,
	wantGenderId: 0,
	studyId: 0,
	jobId: 0,
	growthId: 0,
	religionId: 0,
	haveKidId: 0,
	wantKidId: 0,
	smokeId: 0,
	drinkId: 0,
	photos: [],
	languages: [],
	personalities: [],
	answers: {},
	subscription: {
		status: true,
		is_cancelled: false
	},
	subscriptionStatus: true,
	approval: UNKNOWN,
	horoscope: 0,
	rejectReason: null,
	status: 'active',
	popup_status: null,
	is_blocked: false
}

const initialState = {
	user: { ...userShape },
	currentStep: 0,
	finishedCreatingProfile: true,
	config: Object.entries(configFields).reduce(
		(acc, [key]) => ({ ...acc, [key]: configField }),
		[]
	),
	showTutorial: false
}

const profileReducer = (state = { ...initialState }, { type, payload }) => {
	switch (type) {
		case types.SET_CURRENT_STEP_CREATE_PROFILE: {
			return update(state, {
				currentStep: { $set: payload }
			})
		}
		case types.SET_FINISHED_CREATE_PROFILE: {
			return update(state, {
				finishedCreatingProfile: { $set: payload }
			})
		}
		case types.GET_ME: {
			return update(state, {
				user: { $merge: payload }
			})
		}
		case types.GET_REJECT_REASON: {
			return update(state, {
				user: { $merge: payload }
			})
		}
		case types.UPDATE_ME: {
			return update(state, {
				user: { $merge: payload }
			})
		}
		case types.UPDATE_MEDIA: {
			return update(state, {
				user: { photos: { $set: payload } }
			})
		}
		case types.PUSH_MEDIA: {
			return update(state, {
				user: { photos: { $push: [payload] } }
			})
		}
		case types.GET_PROFILE_OPTIONS: {
			return update(state, {
				config: { $merge: payload }
			})
		}
		case types.TOGGLE_SHOW_TUTORIAL: {
			return update(state, {
				showTutorial: { $set: !state.showTutorial }
			})
		}
		case types.SET_SUBSCRIPTION_STATUS: {
			return update(state, {
				user: { $merge: payload }
			})
		}

		default:
			return state
	}
}

export default profileReducer
