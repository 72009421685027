import update from 'immutability-helper'
import * as types from './constants'
import { userShape } from 'modules/profile/store/reducer'

const initialState = {
	itemsMap: {
		default: { ...userShape }
	}
}

const usersReducer = (state = { ...initialState }, { type, payload }) => {
	switch (type) {
		case types.GET_USER_BY_ID: {
			return update(state, {
				itemsMap: { [payload.id]: { $set: payload.user } }
			})
		}
		case types.GET_USERS_BY_IDS: {
			return update(state, {
				itemsMap: { $merge: payload }
			})
		}
		case types.REMOVE_USER_BY_ID: {
			return update(state, {
				itemsMap: { $set: payload }
			})
		}
		case types.SAVE_USERS: {
			return update(state, {
				itemsMap: { $merge: payload }
			})
		}
		default:
			return state
	}
}

export default usersReducer
