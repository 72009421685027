import * as types from './constants'
import update from 'immutability-helper'

const initialState = {
	receivedLikes: [],
	sentLikes: [],
	currentUserId: null,
	hasMoreReceivedLikes: true,
	hasMoreSentLikes: true
	// items: []
	// itemsMap: {}
}

const likesReducer = (state = { ...initialState }, { type, payload }) => {
	switch (type) {
		case types.GET_RECEIVED_LIKES: {
			return update(state, {
				receivedLikes: {
					$set: payload.receivedLikes
				},
				hasMoreReceivedLikes: {
					$set: payload.hasMoreReceivedLikes
				}
			})
		}
		case types.PUSH_RECEIVED_LIKES: {
			return update(state, {
				receivedLikes: {
					$push: payload.receivedLikes
				},
				hasMoreReceivedLikes: {
					$set: payload.hasMoreReceivedLikes
				}
			})
		}
		case types.GET_SENT_LIKES: {
			return update(state, {
				sentLikes: {
					$set: payload.sentLikes
				},
				hasMoreSentLikes: {
					$set: payload.hasMoreSentLikes
				}
			})
		}
		case types.PUSH_SENT_LIKES: {
			return update(state, {
				sentLikes: {
					$push: payload.sentLikes
				},
				hasMoreSentLikes: {
					$set: payload.hasMoreSentLikes
				}
			})
		}
		case types.REMOVE_RECEIVED_LIKE_BY_ID: {
			return update(state, {
				receivedLikes: {
					$set: payload
				}
			})
		}
		case types.REMOVE_SENT_LIKE_BY_ID: {
			return update(state, {
				sentLikes: {
					$set: payload
				}
			})
		}
		case types.SET_CURRENT_USER_ID: {
			return update(state, {
				currentUserId: {
					$set: payload
				}
			})
		}
		default:
			return state
	}
}

export default likesReducer
