import * as types from './constants'
import * as services from './../services'
import { saveUsers } from 'modules/users/actions'
import { extractFields } from 'utils/exctractIds'
import { _get } from 'utils/lodash'

export const getReceivedLikes = (
	params = {},
	mode = 'set'
) => async dispatch => {
	try {
		const receivedLikesResponse = await services.getReceivedLikes({
			limit: 20,
			...params
		})

		const receivedLikes = _get(receivedLikesResponse, 'result', [])

		if (receivedLikes?.length) {
			dispatch(saveUsers(receivedLikes))
		}

		dispatch({
			type:
				mode === 'set' ? types.GET_RECEIVED_LIKES : types.PUSH_RECEIVED_LIKES,
			payload: {
				receivedLikes: extractFields(receivedLikes),
				hasMoreReceivedLikes: !!receivedLikes.length
			}
		})
	} catch (e) {
		console.dir(e)
	}
}

export const getSentLikes = (params = {}, mode = 'set') => async dispatch => {
	try {
		const sentLikesResponse = await services.getSentLikes({
			limit: 20,
			...params
		})

		const sentLikes = _get(sentLikesResponse, 'result', [])

		if (sentLikes?.length) {
			dispatch(saveUsers(sentLikes))
		}

		dispatch({
			type: mode === 'set' ? types.GET_SENT_LIKES : types.PUSH_SENT_LIKES,
			payload: {
				sentLikes: extractFields(sentLikes),
				hasMoreSentLikes: !!sentLikes.length
			}
		})
	} catch (e) {
		console.dir(e)
	}
}

export const removeReceivedLikeById = id => (dispatch, getState) => {
	const {
		likes: { receivedLikes }
	} = getState()

	const result = receivedLikes.filter(like => like !== id)

	dispatch({
		type: types.REMOVE_RECEIVED_LIKE_BY_ID,
		payload: result
	})
}

export const removeSentLikeById = id => (dispatch, getState) => {
	const {
		likes: { sentLikes }
	} = getState()

	const result = sentLikes.filter(like => like !== id)

	dispatch({
		type: types.REMOVE_SENT_LIKE_BY_ID,
		payload: result
	})
}

export const setCurrentUserId = userId => ({
	type: types.SET_CURRENT_USER_ID,
	payload: userId
})
