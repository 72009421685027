import React from 'react'
import LoadSpinner from 'components/ui/LoadSpinner'
import classes from './FallbackPage.module.scss'

const FallbackPage = () => {
	return (
		<div className={classes.root}>
			<LoadSpinner />
		</div>
	)
}

export default FallbackPage
