import { createStore, compose, applyMiddleware, combineReducers } from 'redux'
import { createBrowserHistory } from 'history'

import { connectRouter, routerMiddleware } from 'connected-react-router'

import thunk from 'redux-thunk'

import { persistStore, persistReducer } from 'redux-persist'
import { persistConfig } from 'libs/persist/config'
import signUp from 'modules/auth/signUp/store/reducer.js'
import signIn from 'modules/auth/signIn/store/reducer.js'
import authReducer from 'modules/auth/store/reducer.js'
import appReducer from 'modules/app/store/reducer.js'
import messengerReducer from 'modules/conversation/store/reducer.js'
import profileReducer from 'modules/profile/store/reducer.js'
import discoveryReducer from 'modules/discovery/store/reducer.js'
import usersReducer from 'modules/users/reducer.js'
import likesReducer from 'modules/notifications/likes/store/reducer.js'
import requestsReducer from 'modules/notifications/requests/store/reducer.js'
import matchesReducer from 'modules/notifications/matches/store/reducer.js'
import subscriptionBillingReducer from 'modules/subscriptionBilling/store/reducer.js'
import { reducer as toastrReducer } from 'react-redux-toastr'
import { CLEAR_STORE } from 'store/constants.js'
import dialogManagerReducer from 'modules/dialogManager/store/reducer'
import preferenceReducer from 'modules/preferences/store/reducer.js'
import emailsReducer from '../modules/notifications/emails/store/reducer'

const composeEnhancers =
	typeof window === 'object' &&
	// eslint-disable-next-line no-underscore-dangle
	// eslint-disable-next-line no-undef
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? // eslint-disable-next-line no-underscore-dangle
		  // eslint-disable-next-line no-undef
		  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				// Specify extension's options like name, actionsBlacklist, actionsCreators, serialize...
		  })
		: compose

const middlewares = [thunk]

const history = createBrowserHistory()

const mainReducer = persistReducer(
	persistConfig.root,
	combineReducers({
		router: connectRouter(history),
		signUp,
		signIn,
		auth: authReducer,
		app: appReducer,
		messenger: persistReducer(persistConfig.messenger, messengerReducer),
		profile: persistReducer(persistConfig.profile, profileReducer),
		discovery: persistReducer(persistConfig.discovery, discoveryReducer),
		users: usersReducer,
		likes: likesReducer,
		requests: requestsReducer,
		matches: matchesReducer,
		preference: persistReducer(persistConfig.preferences, preferenceReducer),
		subscriptionBilling: subscriptionBillingReducer,
		dialogManager: dialogManagerReducer,
		emails: persistReducer(persistConfig.emails, emailsReducer),
		toastr: toastrReducer
	})
)

const createRootReducer = (...args) => {
	const state = 0
	const action = 1

	if (args[action].type === CLEAR_STORE) {
		args[state] = args[action].payload
	}

	return mainReducer(...args)
}

const store = createStore(
	createRootReducer,
	{},
	composeEnhancers(applyMiddleware(routerMiddleware(history), ...middlewares))
)

const persistor = persistStore(store)

if (module.hot) {
	store.replaceReducer(createRootReducer)
	// module.hot.accept('./rootReducer', () => {
	// 	// eslint-disable-next-line global-require
	// 	store.replaceReducer(createRootReducer)
	// })
}

export { store, persistor, history }
