import React from 'react'
import PropTypes from 'prop-types'
import classes from './SignUpWelcomeScreen.module.scss'
import Header from 'components/ui/layout/GuestHeader'
import { btnSize } from 'pages/Homepage/config'
import { btnNames, welcomeDescription } from './config'
import Heading from 'components/ui/Typography/Heading'
import Button from 'components/ui/Button'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import PrivacyPolicy from 'components/ui/layout/PrivacyPolicy'
import { welcomeTitle } from 'pages/SignUpWelcomeScreen/config'
import Typography from 'components/ui/Typography'

const SignInWelcomeScreen = ({ onClickStart }) => {
	const format = useDetermineScreenFormat()

	return (
		<div className={classes.root}>
			<Header />
			<div className={classes.content}>
				<div className={classes.textWrapper}>
					<Heading variant="heading-3" className={classes.title}>
						{welcomeTitle}
					</Heading>
					<Typography variant="body-4" className={classes.description}>
						{welcomeDescription}
					</Typography>
				</div>

				<div className={classes.actions}>
					<Button
						area="facebookBtn"
						shape="rounded"
						color="white"
						size="md"
						stretch
						shadow
						className="mb-1"
						onClick={onClickStart}
						{...btnSize[format]}
					>
						{btnNames.getStarted}
					</Button>

					<PrivacyPolicy />
				</div>
			</div>
		</div>
	)
}

SignInWelcomeScreen.propTypes = {
	onClickStart: PropTypes.func
}

SignInWelcomeScreen.defaultProps = {
	className: '',
	onClickStart: () => null
}

export default SignInWelcomeScreen
