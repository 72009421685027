import * as Sentry from '@sentry/react'
import appConfig from 'constants/appConfig'
import { Integrations } from '@sentry/tracing'

if (
	process.env.NODE_ENV === 'production' &&
	process.env.REACT_APP_SENTRY_RELEASE
) {
	Sentry.init({
		dsn: appConfig.sentryDSN,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 0,
		release: process.env.REACT_APP_SENTRY_RELEASE,
		ignoreErrors: [
			'Non-Error exception captured',
			'Non-Error promise rejection captured'
		]
	})
}
export default Sentry
