import React from 'react'
import PropTypes from 'prop-types'
import classes from './PhotoGuideline.module.scss'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import Heading from 'components/ui/Typography/Heading'
import Typography from 'components/ui/Typography'
import SpriteIcon from 'components/ui/SpriteIcon'
import clsx from 'clsx'
import Button from 'components/ui/Button'

const mainPhotoDontShow = [
	{
		name: 'sunglasses',
		label: 'Sunglasses'
	},
	{
		name: 'half-face',
		label: 'Only half of your face'
	},
	{
		name: 'person-with-second',
		label: 'More than one person'
	}
]
const anyPhotoDontShow = [
	{
		name: 'nudity',
		label: 'Nudity'
	},
	{
		name: 'child',
		label: 'Child or adolescent'
	},
	{
		name: 'celebrity',
		label: 'Celebrity'
	},
	{
		name: 'blurry-face',
		label: 'Blurry or unclear face'
	}
]

const IconsField = ({ title, icons }) => {
	return (
		<div className={classes.iconsField}>
			<Heading variant="heading-2" className={clsx(classes.iconsTitle)}>
				{title}
			</Heading>

			<div className={classes.iconsContainer}>
				{icons.map(({ name, label }) => (
					<div className={classes.iconItem} key={`${label}-${name}`}>
						<SpriteIcon name={name} size="lg" className={classes.icon} />
						<Typography
							variant="body-5"
							className={clsx(classes.iconDesc, 'text-center')}
						>
							{label}
						</Typography>
					</div>
				))}
			</div>
		</div>
	)
}

const PhotoGuideline = ({ open, onClose }) => {
	return (
		<BaseDialog open={open} onClose={onClose} maxWidth="md">
			<div className={classes.root}>
				<Heading
					variant="heading-2"
					className={clsx(classes.mainTitle, 'mb-2')}
				>
					Photo Guideline
				</Heading>
				<Typography
					variant="body-5"
					className={clsx(classes.mainDesc, 'mb-3 text-center')}
				>
					Your profile
					<Heading variant="heading-2" className="color-primary">
						{' '}
						will not be approved{' '}
					</Heading>
					if your photo has any of the following:
				</Typography>

				<Typography
					variant="body-5"
					className={clsx(classes.sectionTitle, 'mb-2 color-primary')}
				>
					Main Photo
				</Typography>
				<IconsField title="DO NOT SHOW" icons={mainPhotoDontShow} />

				<Typography
					variant="body-5"
					className={clsx(classes.sectionTitle, 'mb-2 color-primary')}
				>
					Any Photo
				</Typography>
				<IconsField title="DO NOT SHOW" icons={anyPhotoDontShow} />

				<Button
					onClick={onClose}
					className={clsx('mt-3', classes.btn)}
					variant="contained"
					size="sm"
					shape="rounded"
				>
					OK
				</Button>
			</div>
		</BaseDialog>
	)
}

PhotoGuideline.propTypes = {
	className: PropTypes.string
}

PhotoGuideline.defaultProps = {
	className: ''
}

export default PhotoGuideline
