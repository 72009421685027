import React, { memo, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classes from './GuestHeader.module.scss'
import { Logo } from 'components/wrappers'
import Button from 'components/ui/Button'
import { btnNames } from 'pages/Homepage/config'
import useDetermineScreenFormat from 'hooks/useDetermineScreenFormat'
import { routesByName } from 'constants/routes'
import useHistory from 'hooks/useHistory'
import SpriteIcon from 'components/ui/SpriteIcon'
import { screenFormat } from 'constants/screenFormat'
import clsx from 'clsx'

const logoSize = {
	desktop: {
		width: 45,
		height: 45
	},
	mobile: {
		width: 32,
		height: 32
	}
}

const btnSize = {
	desktop: {
		size: 'sm'
	},
	mobile: {
		size: 'xs'
	}
}

const closeBtnSize = {
	desktop: {
		size: 'lg'
	},
	mobile: {
		size: 'md'
	}
}

const GuestHeader = ({
	variantLogo,
	hasSuccessStoriesBtn,
	hasCloseIcon,
	hasBack,
	hideHeaderOnMobile,
	hideLogoOnMobile,
	onClickBackIcon,
	classes: outerClasses = {}
}) => {
	const format = useDetermineScreenFormat()

	const history = useHistory()

	const handleClickSuccessStory = useCallback(() => {
		history.push(routesByName.successStories)
	}, [history])

	const handleClickCloseIcon = useCallback(() => {
		history.goBack()
	}, [history])

	const handleClickBackIcon = useCallback(() => {
		if (onClickBackIcon) {
			onClickBackIcon()
		} else {
			history.goBack()
		}
	}, [history, onClickBackIcon])

	const renderLogo = useMemo(
		() =>
			hideLogoOnMobile && format === screenFormat.mobile ? (
				<div />
			) : (
				<Logo {...logoSize[format]} variant={variantLogo} withTitle />
			),
		[format, hideLogoOnMobile, variantLogo]
	)

	const renderCloseIcon = useMemo(
		() =>
			hasCloseIcon && (
				<SpriteIcon
					name="close"
					className={classes.closeIcon}
					onClick={handleClickCloseIcon}
					{...closeBtnSize[format]}
				/>
			),
		[format, handleClickCloseIcon, hasCloseIcon]
	)

	const renderBackIcon = useMemo(
		() =>
			hasBack && (
				<SpriteIcon
					name="arrow-back"
					className={classes.closeIcon}
					onClick={handleClickBackIcon}
					color="#E5E5E5"
				/>
			),
		[handleClickBackIcon, hasBack]
	)

	return hideHeaderOnMobile && format === screenFormat.mobile ? null : (
		<header className={clsx(classes.root, outerClasses.root)}>
			{renderBackIcon}

			{renderLogo}

			{hasSuccessStoriesBtn && (
				<Button
					variant="contained"
					color="white"
					onClick={handleClickSuccessStory}
					{...btnSize[format]}
					className={classes.successStoriesBtn}
				>
					{btnNames.successStories}
				</Button>
			)}
			{renderCloseIcon}
		</header>
	)
}

GuestHeader.propTypes = {
	variantLogo: PropTypes.oneOf(['dark', 'light']),
	hasSuccessStoriesBtn: PropTypes.bool,
	hasCloseIcon: PropTypes.bool,
	hideHeaderOnMobile: PropTypes.bool,
	hasBack: PropTypes.bool,
	hideLogoOnMobile: PropTypes.bool,
	onClickBackIcon: PropTypes.func
}

GuestHeader.defaultProps = {
	variantLogo: 'dark',
	hasSuccessStoriesBtn: false,
	hasCloseIcon: false,
	hasBack: false,
	hideHeaderOnMobile: false,
	hideLogoOnMobile: false
}

export default memo(GuestHeader)
