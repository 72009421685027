const homePrefix = '/home'
const mainPrefix = '/main'

export const routesByName = {
	root: '/',
	home: homePrefix,
	successStories: '/success-stories',
	blog: 'https://tamilculture.com/user/mytamildatecom',
	contact: '/contact',
	about: '/about',
	faq: '/faq',
	facebook: '/facebook',
	selectedPlan: '/selected-plan',
	comingSoon: '/coming-soon',
	main: {
		root: mainPrefix,
		discovery: {
			root: `${mainPrefix}/discovery`,
			sendMessage: `${mainPrefix}/discovery/sendMessage`,
			conversation: `${mainPrefix}/discovery/conversation`,
			preference: `${mainPrefix}/discovery/preference`
		},
		notifications: {
			root: `${mainPrefix}/notifications`,
			matches: {
				root: `${mainPrefix}/notifications/matches`,
				conversation: `${mainPrefix}/notifications/matches/conversation`,
				conversationCreate: `${mainPrefix}/notifications/matches/conversation-create`,
				mobileConversationCreate: `${mainPrefix}/notifications/matches/mobile-conversation-create`
			},
			likes: {
				root: `${mainPrefix}/notifications/likes`,
				sent: `${mainPrefix}/notifications/likes/sent`,
				received: `${mainPrefix}/notifications/likes/received`,
				previewReceived: `${mainPrefix}/notifications/likes/previewReceived`,
				previewSent: `${mainPrefix}/notifications/likes/previewSent`,
				previewWithId: userId =>
					`${mainPrefix}/notifications/likes/preview/${userId}`
			},
			requests: {
				root: `${mainPrefix}/notifications/requests`,
				sent: `${mainPrefix}/notifications/requests/sent`,
				sentConversationCreate: `${mainPrefix}/notifications/requests/sent/conversation/create`,

				received: `${mainPrefix}/notifications/requests/received`,
				mobileReceivedConversation: `${mainPrefix}/notifications/requests/received/mobile-conversation`,
				receivedConversation: `${mainPrefix}/notifications/requests/received/conversation`
			},
			emails: `${mainPrefix}/notifications/emails`
		},

		profile: {
			root: `${mainPrefix}/profile`,
			edit: `${mainPrefix}/profile/edit`,
			preview: `${mainPrefix}/profile/preview`
		},
		settings: {
			root: `${mainPrefix}/settings`,
			changeIdentity: `${mainPrefix}/settings/change-:type`,
			changeIdentityWithType: type => `${mainPrefix}/settings/change-${type}`,
			billing: {
				root: `${mainPrefix}/settings/billing`,

				history: `${mainPrefix}/settings/billing/history`,
				addPaymentMethod: `${mainPrefix}/settings/billing/add-payment-method`,
				editPaymentMethod: `${mainPrefix}/settings/billing/edit-payment-method`
			}
		},
		lastMatch: {
			root: `${mainPrefix}/lastMatch`,
			conversation: `${mainPrefix}/lastMatch/conversation`,
			conversationCreate: `${mainPrefix}/lastMatch/create-conversation`,
			mobileConversationCreate: `${mainPrefix}/lastMatch/mobile-create-conversation`
		},
		helpPage: `${mainPrefix}/help`,
		preferences: `${mainPrefix}/preferences`
	},
	test: '/test',
	conversation: {
		root: `/conversation`,
		create: '/conversation/create'
	},
	enterEmail: '/enter-email',
	createProfile: '/create-profile',

	signUp: {
		root: '/sign-up'
	},
	signIn: {
		root: '/sign-in',
		withPhone: '/sign-in/with-phone',
		help: '/sign-in/help'
	},

	upgrade: {
		root: '/upgrade',
		subscribe: '/upgrade/subscribe',
		subscribeWithId: id => `/upgrade/subscribe/${id}`
	},

	common: {
		root: '/common',
		terms: '/terms-of-use',
		privacy: '/privacy-policy'
	}
}
