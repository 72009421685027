import appConfig from 'constants/appConfig'
import BaseAxiosInstance from 'libs/axios/BaseAxiosInstance'
import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance'

export const getAppConfig = () => BaseAxiosInstance.get(appConfig.configUrl)

export const getUnmatchReasons = () =>
	AuthorizedAxiosInstance.get(
		appConfig.apiPrefix + '/discovery/matches/unmatches'
	)

export const getReportReasons = () =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/report/reasons')

export const getMeta = async () => {
	try {
		const response = await fetch('/meta.json')
		if (!response.ok)
			return Promise.reject(Error("Failed to fetch meta data"));
		return response
	} catch (e) {
		console.error(e)
	}
}
