import React, { memo } from 'react'
import BaseDialog from 'components/wrappers/ui/BaseDialog'
import Typography from '../Typography'
import Button from '../Button'
import classes from './styles.module.scss'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { getDialogManagerState } from 'modules/dialogManager/store/selectors'

const ReduxConfirmDialog = () => {
	const dialogManager = useSelector(getDialogManagerState)
	const {
		open = false,
		onClose,
		onConfirm,
		title,
		body,
		confirmBtn,
		cancelBtn,
		withCancelBtn,
		withConfirmBtn,
		boldText
	} = dialogManager

	return (
		<BaseDialog
			open={open}
			rootClass={classes.dialog}
			maxWidth="xs"
			onClose={onClose}
		>
			<div className={classes.root}>
				<div className={classes.modal}>
					<Typography className={classes.modalTitle}>{title}</Typography>
					<Typography
						className={clsx(classes.modalSubtitle, {
							[classes.bold]: boldText
						})}
					>
						{body}
					</Typography>
					<div className={classes.buttons}>
						{withConfirmBtn && (
							<Button
								onClick={onConfirm}
								shape="rounded"
								className={classes.btn}
							>
								{withCancelBtn ? confirmBtn.title : 'OK'}
							</Button>
						)}
						{withCancelBtn && (
							<Button
								onClick={onClose}
								shape="rounded"
								variant={'outlined'}
								className={clsx(classes.btn, classes.cancelBtn)}
							>
								{cancelBtn.title}
							</Button>
						)}
					</div>
				</div>
			</div>
		</BaseDialog>
	)
}

export default memo(ReduxConfirmDialog)
