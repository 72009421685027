import { toastr } from 'react-redux-toastr'
import React from 'react'
import SpriteIcon from 'components/ui/SpriteIcon'
import ToastrIconContainer from 'libs/toastr/ToastrIconContainer/ToastrIconContainer'

const successToastr = (message = '', title = 'Success', options = {}) => {
	toastr.success(title, message, {
		icon: (
			<ToastrIconContainer type="success">
				<SpriteIcon name="correct" size="sm" />
			</ToastrIconContainer>
		),
		...options,
		showCloseButton: true,
		newestOnTop: true,
		position: 'top-center',
		transitionIn: 'fadeIn',
		transitionOut: 'fadeOut',
		timeOut: 3000
	})
}

export default successToastr
