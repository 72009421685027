import React from 'react'
import classes from './Step4.module.scss'
import { ReactComponent as Filter } from 'assets/staticIcons/filter.svg'
import BottomNavigationBar from 'components/ui/controls/BottomNavigationBar'
import SpriteIcon from 'components/ui/SpriteIcon'
import { black3 } from 'constants/colors'

const navigationBarClasses = {
	root: classes.navigationBar
}

const Step4 = () => {
	return (
		<div className={classes.root}>
			<div className={classes.filter}>
				<Filter />
			</div>
			<div>
				<SpriteIcon
					name="filters"
					color={black3}
					className={classes.filtersBtn}
				/>
			</div>
			<div className={classes.nav}>
				<p>Home</p>
				<p>Messages</p>
				<p>Matches</p>
				<p>Profile</p>
			</div>
			<BottomNavigationBar classes={navigationBarClasses} />
		</div>
	)
}

export default Step4
