import AuthorizedAxiosInstance from 'libs/axios/AuthorizedAxiosInstance'
import appConfig from 'constants/appConfig'

export const getUserById = id =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + '/user/' + id)

export const getUsersByIds = ids =>
	AuthorizedAxiosInstance.get(appConfig.apiPrefix + `/users`, {
		params: { ids }
	})

export const reportUser = (userId, reason) =>
	AuthorizedAxiosInstance.post(appConfig.apiPrefix + `/user/report/${userId}`, {
		reason: reason
	})

export const unmatchUser = (userId, reasonId) =>
	AuthorizedAxiosInstance.post(
		appConfig.apiPrefix + `/discovery/matches/unmatch/${userId}`,
		{
			unmatchReasonId: reasonId
		}
	)

export const blockUser = userId =>
	AuthorizedAxiosInstance.post(appConfig.apiPrefix + `/user/block/${userId}`)

export const unblockUser = userId =>
	AuthorizedAxiosInstance.post(appConfig.apiPrefix + `/user/unblock/${userId}`)
