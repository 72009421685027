export const transformArrayToDictionary = (arr = []) => {
	const dictionary = {}

	arr.map(item => {
		if (dictionary[item.label.charAt(0)]) {
			dictionary[item.label.charAt(0)].push(item)
		} else {
			dictionary[item.label.charAt(0)] = []
			dictionary[item.label.charAt(0)].push(item)
		}
		return dictionary
	})
	return dictionary
}
