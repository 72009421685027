export const getMe = ({ profile: { user } }) => user

export const getMyId = ({
	profile: {
		user: { id }
	}
}) => id

export const getMeAvatar = ({
	profile: {
		user: { photos }
	}
}) => photos.find(photo => photo?.type === 'avatar')

export const getProfileConfig = ({ profile: { config } }) => config

export const getFinishedCreatingProfile = ({
	profile: { finishedCreatingProfile }
}) => finishedCreatingProfile

export const getSubscriptionStatus = ({
	profile: {
		user: { subscriptionStatus }
	}
}) => subscriptionStatus

export const getRejectReason = ({
	profile: {
		user: { rejectReason }
	}
}) => rejectReason

export const getPopupStatus = ({
	profile: {
		user: { popup_status }
	}
}) => popup_status

export const getUserApproval = ({
	profile: {
		user: { approval }
	}
}) => approval

export const getUserAge = ({ profile: { user } }) => user?.age
