import { routesByName } from 'constants/routes.js'
import { lazy } from 'react'
import mapRouteConfig from 'utils/mapRouteConfig'

const Homepage = lazy(() => import('pages/Homepage'))
const SignUp = lazy(() => import('modules/auth/signUp'))
const SignInSwitch = lazy(() => import('modules/auth/signIn/Switch.js'))
const ComingSoon = lazy(() => import('pages/ComingSoon'))

const GuestRouteConfig = [
	{
		path: routesByName.home,
		component: Homepage,
		exact: true
	},
	{
		path: routesByName.signUp.root,
		component: SignUp,
		exact: false
	},
	{
		path: routesByName.signIn.root,
		component: SignInSwitch,
		exact: false
	},
	{
		path: routesByName.facebook,
		component: ComingSoon,
		exact: false
	},
	{
		path: routesByName.comingSoon,
		component: ComingSoon,
		exact: false
	}
]

export default mapRouteConfig(GuestRouteConfig)
