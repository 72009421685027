import { CLEAR_STORE } from 'store/constants.js'

export const clearStore = (blacklist = ['app', '_persist']) => (
	dispatch,
	getState
) => {
	const state = getState()

	for (let key in state) {
		if (!blacklist.includes(key) && state.hasOwnProperty(key)) {
			state[key] = undefined
		}
	}

	dispatch({
		type: CLEAR_STORE,
		payload: state
	})
}
