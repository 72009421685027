import React, { memo } from 'react'
import classes from './UserAvatar.module.scss'
import clsx from 'clsx'
import BaseImage from 'components/wrappers/ui/BaseImage'
import Logo from 'components/ui/layout/Logo'

const UserPreviewAvatar = ({ src, type = 'round', className, isSelected }) => {
	return (
		<div className={clsx(className, classes.container)}>
			{src ? (
				<BaseImage
					classes={{
						root: clsx(className, classes.root, classes[type]),
						wrapper: classes.wrapper
					}}
					src={src}
				/>
			) : (
				<Logo variant="dark" />
			)}
			<div className={clsx({ [classes.isSelected]: isSelected })} />
		</div>
	)
}

export default memo(UserPreviewAvatar)
