import React from 'react'
import BaseFormikWrapper from 'components/wrappers/formik/index'
import PropTypes from 'prop-types'
import BaseSelectImages from 'components/ui/controls/BaseSelectImages'

const FormikFieldSelectImages = props => (
	<BaseFormikWrapper component={BaseSelectImages} {...props} />
)

FormikFieldSelectImages.propTypes = {
	name: PropTypes.string.isRequired
}

export default FormikFieldSelectImages
