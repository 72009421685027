import update from 'immutability-helper'
import * as types from './constants'

const initialState = {
	items: [],
	lastElement: 0,
	hasMore: true,
	personForMatch: null
}

const matchesReducer = (state = { ...initialState }, { type, payload }) => {
	switch (type) {
		case types.GET_MATCHES: {
			return update(state, {
				items: { $set: payload.matches },
				hasMore: { $set: payload.hasMore }
			})
		}
		case types.PUSH_MATCHES: {
			return update(state, {
				items: { $push: payload.matches },
				hasMore: { $set: payload.hasMore }
			})
		}
		case types.GET_LAST_MATCH: {
			return update(state, {
				lastElement: { $set: payload }
			})
		}
		case types.REMOVE_MATCH_BY_ID: {
			return update(state, {
				items: { $set: payload }
			})
		}
		case types.SET_PERSON_FOR_MATCH: {
			return update(state, {
				personForMatch: { $set: payload }
			})
		}
		default:
			return state
	}
}

export default matchesReducer
