import * as types from './constants'
import { buildUsersPhotoPath, preparePhotosSrc } from 'modules/users/utils'
import * as usersServices from 'modules/users/services'
import { transformArrayToObjectById } from 'utils/transformArrayToObjectById'
import { _get, _isObject } from 'utils/lodash'

export const getUserById = id => async (dispatch, getState) => {
	try {
		if (!_isObject(id)) {
			const response = await usersServices.getUserById(id)

			const user = _get(response, 'result', {})

			const {
				app: { config }
			} = getState()

			const photos = _get(response, 'result.photos', [])

			user.photos = preparePhotosSrc({ photos, config })

			dispatch({
				type: types.GET_USER_BY_ID,
				payload: {
					user,
					id
				}
			})
		}
	} catch (e) {
		console.dir(e)
	}
}

export const getUsersByIds = (ids = []) => async (dispatch, getState) => {
	try {
		const response = await usersServices.getUsersByIds(ids)

		const users = _get(response, 'result', [])

		const {
			app: { config }
		} = getState()

		const usersWithPhotos = buildUsersPhotoPath(users, config)

		const usersMap = transformArrayToObjectById(usersWithPhotos)

		dispatch({
			type: types.GET_USERS_BY_IDS,
			payload: usersMap
		})
	} catch (e) {
		console.dir(e)
	}
}
export const saveUsers = (users = []) => (dispatch, getState) => {
	try {
		const {
			app: { config }
		} = getState()
		const usersWithPhotos = buildUsersPhotoPath(users, config)

		const usersMap = transformArrayToObjectById(usersWithPhotos)

		dispatch({
			type: types.SAVE_USERS,
			payload: usersMap
		})
	} catch (e) {
		console.dir(e)
	}
}

export const removeUserById = id => (dispatch, getState) => {
	const {
		users: { itemsMap }
	} = getState()

	const newItemsMap = { ...itemsMap }

	delete newItemsMap[id]

	return {
		type: types.REMOVE_USER_BY_ID,
		payload: newItemsMap
	}
}
