import React, { useCallback, useEffect, useState } from 'react'
import Step1 from 'pages/MobileTutorial/Steps/Step1'
import Step2 from 'pages/MobileTutorial/Steps/Step2'
import Step3 from 'pages/MobileTutorial/Steps/Step3'
import Step4 from 'pages/MobileTutorial/Steps/Step4'
import classes from './MobileTutorial.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { toggleShowTutorial } from '../../modules/profile/store/actions'

const steps = [<Step1 />, <Step2 />, <Step3 />, <Step4 />]

const lastStep = steps.length

const MobileTutorial = () => {
	const [currentStepShowTutorial, setCurrentStepShowTutorial] = useState(0)
	const showTutorial = useSelector(
		({ profile: { showTutorial } }) => showTutorial
	)
	const dispatch = useDispatch()

	const handleChangeStep = useCallback(() => {
		setCurrentStepShowTutorial(value => value + 1)
	}, [])

	useEffect(() => {
		if (currentStepShowTutorial === lastStep) {
			dispatch(toggleShowTutorial())
			setCurrentStepShowTutorial(0)
		}
	}, [currentStepShowTutorial, dispatch])

	return (
		<>
			{showTutorial && (
				<div
					className={classes.root}
					role="presentation"
					onClick={handleChangeStep}
				>
					{steps[currentStepShowTutorial]}
				</div>
			)}
		</>
	)
}

export default MobileTutorial
